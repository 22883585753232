import Circle_Write2 from "../../components/ExcerciseTypes/Circle_Write2";
import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 4",
    id: "FP8-WB-demo-2023-U4-P64-E1",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page64/Key/E1answerKey.png",
    component: Circle_Write2,

    titleQuestion: [
      {
        num: "1",
        title: `
        Read the situations and sentences a–c. Find and correct the sentence with a mistake. 
				 
				`,
        color: "#4b3a97",
      },
    ],
    question: {
      Write: {
        isHiddenCheck: true,
        inputStyle: {
          // fontWeight: "bold",
          textAlign: "center",
          fontSize: "22px",
          width: 150,
          borderBottom: "2px solid gray",
        },
        answers: [
          `T`,
          `T`,
          "little",
          "many",
          `T`,
          `T`,
          "enough milk",
          `T`,
          `T`,

          "lot of / little",

          `T`,
          `T`,
        ],
        initialValue: [],
      },
      Circle: {
        selectWordStyle: {
          padding: 5,
          borderRadius: "50%",
          border: "3px solid gray",
        },
        limitSelect: 1,
        listWords: [
          `a <br> b <br>  c`, // 0
          `That’s too much money. <br> Only a few people can buy them. <br> I’ve got a few money, but not enough to buy them. `, // 1
          `a <br> <br> b <br> c`, // 2
          `There aren’t too much people at <br> the party. 
          <br> There’s enough food.  <br>  ‘How much food is there?’ <br> ‘The right amount.’ `, // 3
          `a <br> <br> b <br> c`, // 4
          `There isn’t milk_enough for <br> everyone. 
          <br> There’s only a little milk. <br> There isn’t a lot of milk. `, // 5
          `a <br> b <br> c`, // 6
          `We need a few light.  <br> There isn’t enough light.  <br> It’s too dark. `, // 7
        ],
        answers: ["0-10", "1-32", "2-0", "3-6", "4-0", "5-4", "6-0", "7-6"],

        initialValue: [],
      },
      Layout: `
      <div><i style='font-size:19px; color: rgb(0,103,180)'>*If the sentence has no mistake, write T (true).</i></div>
      <div style='font-size: 22px'> 
      There are five people, but we’ve got four burgers.<br>
      <b>a</b>&ensp;There aren’t enough burgers.&ensp;<u style='color: gray'>&ensp;T&ensp;</u><br>
      <b style='padding: 5px; border: 2px solid; border-radius: 50%'>b</b>&ensp;There are only a <s>little</s> burgers.&ensp; <u style='color: gray'>&ensp;few&ensp;</u><br>
      <b>c</b>&ensp;There are too many people.&ensp;<u style='color: gray'>&ensp;T&ensp;</u><br>
      </div>
      
      <div style='display:flex;max-width:100%;font-size:22px'>
      <div style='margin-left:-20px;' >
      <b>1</b>&ensp; Those jeans cost £150!

					<div style='display:flex'>
               <div style='margin-left:30px;font-weight:bold'>
              <input id='0' type='Circle' />
            </div>
            <div style='margin-left:10px'>
              <input id='1' type='Underline' />
            </div>
           <span>
           <input id='0'  margin-left='-190px'/>
           <br>
           <input id='1'  width='120px'  margin-left='-120px' /> <br>  <br>
           <input id='2'  margin-left='-340px' />
         </span>
          </div>
          <b>2</b>&ensp;Everyone at the party has a plate of food. 

					<div style='display:flex'>

            <div style='margin-left:30px;font-weight:bold'>
              <input id='2' type='Circle' />
            </div>
            <div style='margin-left:10px'>
              <input id='3' type='Underline' />
            </div>
            <span>
             <br> <input id='3' margin-left='-215px'  /><br> 
              <input id='4' width='120px'  margin-left='-120px' /><br> <br>
              <input id='5'  margin-left='-150px' />
            </span>
          </div>
      </div>


      <div style='margin-left:100px;'>
       <b>3</b>&ensp;  We’ve got 50 ml of milk and everyone wants some in their tea. 

          <div style='display:flex'>

            <div style='margin-left:35px;font-weight:bold'>
              <input id='4' type='Circle' />
            </div>
            <div style='margin-left:10px'>
              <input id='5' type='Underline' />
            </div>
            <span>
             <br> <input id='6'   margin-left='-150px'  /><br> 
              <input id='7' border ='none'   margin-left='-20px' /><br> 
              <input id='8' border ='none' margin-left='-20px' />
            </span>
          </div>
          <b>4</b>&ensp; It’s very dark in this room. We can’t see anything. 

					<div style='display:flex'>

            <div style='margin-left:35px;font-weight:bold'>
              <input id='6' type='Circle' />
            </div>
            <div style='margin-left:10px'>
              <input id='7' type='Underline' />
            </div>
            <span>
              <input id='9' border='none' margin-left='0px'  /><br>
              <input id='10' border='none' margin-left='0px' /><br>
              <input id='11'  width='110px' margin-left='-110px' />
            </span>
          </div>
      </div>
      
      
      </div>
         

        `,
    },
  },
  2: {
    unit: "Unit 4",
    id: "FP8-WB-demo-2023-U4-P64-E2",
    // audio: "Audios/2.17.mp3",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page64/key/E2answerKey.png",
    component: T6v2,
    maxLength: 50,
    inputSize: 700,
    stylesTextInput: {
      //   background: 'transparent',
      //   border: 'none',
      //   borderBottom: '1px solid black',
      //   textAlign: 'center',
      fontSize: "22px",
      // fontWeight: "bold",
    },
    titleQuestion: [
      {
        num: "2",

        title: `
				Order the words to make sentences. There is one extra word.
										`,
        color: "#4b3a97",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
      <div style='line-height:45px; font-size: 22px'>
        <div >
        paper / you got / much / have / how / enough / ? <br>
        <u><b style='color: gray'>&ensp;How much paper have you got?&ensp;</b></u><br>
        </div>
        <div>
          <b>1</b>&ensp; a / butter / many / in the fridge / little / there’s <br>
          #
        </div>

        <div style='margin-top:10px'>
          <b>2</b>&ensp; there / time / enough / a lot / is / ? <br>
          #
        </div>

        <div style='margin-top:10px'> 
          <b>3</b>&ensp; like / much / people / many / chocolate<br>
          #
        </div>

        <div style='margin-top:10px'>
          <b>4</b>&ensp; are / too / enough / there / bags / many / ? <br>
          #
        </div>

        <div style='margin-top:10px'>
        <b>5</b>&ensp; you / enough / got / haven’t / few / water <br>
        #
      </div>

      <div style='margin-top:10px'>
      <b>6</b>&ensp; noise / a lot / little / there’s / outside / of <br>
      #
    </div>

        

      </div>
									`,
        answer: [
          `There's a little butter in the fridge.`,
          `Is there enough time?`,
          `Many people like chocolate.`,
          `Are there too many bags?`,
          `You haven't got enough water.`,
          `There's a lot of noise outside.`,
        ],
      },
    ],
  },
  3: {
    unit: "Unit 4",
    id: "FP8-WB-demo-2023-U4-P64-E3",
    audio: "",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page64/key/E3answerKey.png",
    component: T6v2,
    maxLength: 17,
    inputSize: 170,
    stylesTextInput: {
      //   background: 'transparent',
      //   border: 'none',
      //   borderBottom: '1px solid black',
      textAlign: "center",
      fontSize: "22px",
      // fontWeight: "bold",
    },
    titleQuestion: [
      {
        num: "3",

        title: `
				Add tags to make questions.
										`,
        color: "#4b3a97",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
		<div style=''>
      <div style='line-height:45px; font-size: 22px'>
        He isn’t very good at singing, <u><b style='color: gray'>&ensp;is he&ensp;</b></u> ?

        <div><b>1.&ensp;</b>&ensp; They didn’t recycle the rubbish, # ? </div>
        <div><b>2.&ensp;</b>&ensp; She’s winning the competition, # ? </div>
        <div><b>3.&ensp;</b>&ensp; They can’t cook Chinese food, # ? </div>
        <div><b>4.&ensp;</b>&ensp; George has been here before, # ? </div>
        <div><b>5.&ensp;</b>&ensp; You won’t be ready for a while, # ? </div>
        <div><b>6.&ensp;</b>&ensp; They don’t come to this school, # ? </div>
      <div><b>7.&ensp;</b>&ensp; Katia loves swimming, # ? </div>
      <div><b>8.&ensp;</b>&ensp; Daniel can play the violin, # ? </div>
      <div><b>9.&ensp;</b>&ensp; They haven’t got many friends, # ? </div>
      <div><b>10.</b>&ensp; You used to live there, # ? </div>
      </div>
    
    </div>
										`,
        answer: [
          `did they`,
          `isn't she`,
          `can they`,
          `hasn't he`,
          `will you`,
          `do they`,
          `doesn't she`,
          `can't he`,
          `have they`,
          `didn't you`,
        ],
      },
    ],
  },
  4: {
    unit: "Unit 4",
    id: "FP8-WB-demo-2023-U4-P64-E4",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page64/key/E4answerKey.png",
    component: T6v2,
    maxLength: 6,
    inputSize: 100,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      fontSize: "24px",
      fontWeight: "bold",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "4",
        title: `
        Complete the sentences with one word.
        `,
        color: "#4b3a97",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <div style='line-height:2'>
          I’m turning off the lights <b><u style='color: gray'>&ensp;to&ensp;</u></b> save energy.
          <div><b>1.</b>&ensp; Keep those bottles, in # we need them again. </div>
          
          <div><b>2.</b>&ensp; She’s becoming a doctor in # to help people. </div>

          <div><b>3.</b>&ensp; He called # that he could tell me his news. </div>

          <div><b>4.</b>&ensp; # order to save money, we’re making the presents at home. </div>

          <div><b>5.</b>&ensp; Keep your mobile phone with you, # case I need to give you a call. </div>
          
          <div><b>6.</b>&ensp; There’s a student council, so # students can make decisions about college life. </div>

          <div><b>7.</b>&ensp; We’re going to the airport # meet my cousin. </div>
        
        </div>
        `,
        answer: [`case`, `order`, `so`, `In`, `in`, `that`, `to`],
      },
    ],
  },
  5: {
    unit: "Unit 4",
    id: "FP8-WB-demo-2023-U4-P64-E5",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "",
    component: T6v2,
    // maxLength: 1,
    inputSize: 500,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      fontSize: "24px",
    },
    titleQuestion: [
      {
        num: "5",
        title: `
        Work in pairs. Ask for and give advice for each situation. Which piece of
        advice was the best?

        `,
        color: "#4b3a97",
        prefix: { icons: ["far fa-comment"], text: "USE IT!" },
      },
    ],
    questionImage: [],
    hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <p style="font-size: 25px;">
          <span style="font-weight: bold;2;" >1</span>
          <span>I cheated in a test.</span>
        </p>

        <p style="font-size: 25px;">
          <span style="font-weight: bold;margin-right:10px;" >2</span>
          <span>Another student is bullying my best friend.</span>
        </p>

        <p style="font-size: 25px;">
          <span style="font-weight: bold;margin-right:10px;" >3</span>
          <span>I’m always late for school.</span>
        </p>
        <div style="margin-left: 200px"><img src="img/FriendsPlus/Page64/14.jpg" /></div>

        `,
        answer: [],
      },
    ],
  },
  6: {
    unit: "Unit 4",
    id: "FP8-WB-demo-2023-U4-P64-E6",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "",
    component: T6v2,
    // maxLength: 1,
    inputSize: 500,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      fontSize: "24px",
    },
    titleQuestion: [
      {
        num: "6",
        title: `
        Work in pairs. Read the situation, prepare and practise a new dialogue. Use the key phrases and the
        dialogue in exercise 1 to help you.
        `,
        color: "#4b3a97",
        prefix: { icons: ["far fa-comment"], text: "USE IT!" },
      },
    ],
    questionImage: [],
    hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
        
        <div style="margin-left: 200px"><img src="img/FriendsPlus/Page64/15.jpg" /></div>
        <div style="margin-left: 200px;margin-top: 30px"><img src="img/FriendsPlus/Page64/13.jpg" /></div>
        <div style="margin-top: 30px"><img src="img/FriendsPlus/Page64/12.jpg" width="1200px"/></div>

        `,
        answer: [],
      },
    ],
  },
};

export default json;
