import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  1: {
    unit: "Unit 2",
    id: "FP8-SB-demo-2023-U2-P23-E1",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page23/Key/E1answerKey.png",
    component: T6v2,

    inputSize: 550,
    stylesTextInput: {
      fontSize: "22px",
      //textAlign: "center	",
    },
    titleQuestion: [
      {
        num: "1",
        title: `
				Order the words to make sentences and questions.
				`,
        color: "rgb(28, 192, 217)",
        star: 1,
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <div style='font-size: 22px; line-height: 45px'>
          <div>have / just / set / on / travels / their / off / they<br></div>
          <div style="color:rgb(93, 95, 95)"><i>They have just set off on their travels.</i></div>

					<div><b>1 </b>record / she / beaten / her / has / just / ?</div>
					<div>#</div>

					<div><b>2 </b>just / Natalie / have / I / the / seen / shops / at</div>
					<div>#.</div>

					<div><b>3 </b>rugby / we / just / match / have / played / a</div>
					<div>#.</div>

					<div><b>4 </b>they / finished / concert / the / have / just / ?</div>
					<div>#</div>

					<div><b>5 </b>won / has / just / competition / who / the / ?</div>
					<div>#</div>

					<div><b>6 </b>climbed / has / Robin / mountain / a / up / just</div>
					<div>#.</div>
          </div>
        `,
        answer: [
          "Has she just beaten her record?",
          "I have just seen Natalie at the shops",
          "We have just played a rugby match",
          "Have they just finished the concert?",
          "Who has just won the competition?",
          "Robin has just climbed up a mountain",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 2",
    id: "FP8-SB-demo-2023-U2-P23-E1",
    audio: "",
    video: "",
    component: T6v2,
    exerciseKey: "img/FriendsPlus/Page23/Key/E2answerKey.png",
    inputSize: 550,
    stylesTextInput: {
      fontSize: "23px",
    },
    titleQuestion: [
      {
        num: "2",
        title: `
				What has just happened in the photos? Complete the sentences using <i>just</i> and the present perfect simple form of the verbs. Use short forms.
				`,
        color: "rgb(28, 192, 217)",
        star: 2,
      },
    ],
    hintBox: [
      {
        src: ["break", "cook", "eat", "open", "pass", "<del>win</del>"],
        borderColor: "rgb(0,103, 178)",
        width: 650,
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
        
        <div style="display:flex;">
          <div><img src='img/FriendsPlus/Page23/E2/1.jpg' style='width: 11cm' /></div>
          <div style='padding-left:20px; line-height: 50px; font-size: 23px'>
            <div><hintBox id='0'></hintBox></div>
            <div>He's <i><u style='color: gray'>just won a race.</u></i></div>
            <div><b>1 </b> We #.</div>
            <div><b>2 </b> He #.</div>
            <div><b>3 </b> He #.</div>
            <div><b>4 </b> She #.</div>
            <div><b>5 </b> They #.</div>
            </div>
				</div>
        `,
        answer: [
          "'ve just eaten dinner",
          "'s just broken his leg",
          "'s just opened a present",
          "'s just cooked a pizza",
          "'ve just passed their exams",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 2",
    id: "FP8-SB-demo-2023-U2-P23-E3",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page23/Key/E3answerKey.png",
    component: T6v2,
    inputSize: 130,
    maxLength: 25,
    stylesTextInput: {
      fontSize: "23px",
    },
    titleQuestion: [
      {
        num: "3",
        title: `
				Correct the words.
				`,
        color: "rgb(28, 192, 217)",
        star: 2,
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <div style='font-size: 23px; line-height: 50px'>
          <div>We haven't tried scuba-diving <b><del>still</del>. <u>&ensp;yet&ensp;&ensp;</u></b> </div>
          <div><b>1 </b>Joe has <b>still</b> seen the film. He didn't like it. #</div>
          <div><b>2 </b>You <b>yet</b> haven't washed the car! #</div>
          <div><b>3 </b>Have you planned our trip <b>already</b>? #</div>
          <div><b>4 </b>Sorry, I <b>already</b> haven't finished. #</div>
          <div><b>5 </b>Has she <b>yet</b> told you the news? #</div>
        </div>
        `,
        answer: ["already", "still", "yet", "still", "already"],
      },
    ],
  },
  4: {
    unit: "Unit 2",
    id: "FP8-SB-demo-2023-U2-P23-E4",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page23/Key/E4answerKey.png",
    component: T6v2,
    inputSize: 100,
    maxLength: 10,
    stylesTextInput: {
      fontSize: "21px",
      background: "none",
      // borderBottom: "none",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "4",
        title: `
        Complete the text message with <i>still, yet</i> and <i>already</i>.
				`,
        color: "rgb(28, 192, 217)",
        star: 2,
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
        
        <div style="padding-left:50px; position: relative; font-size: 21px">
        <img src='img/FriendsPlus/Page23/E4/1.jpg' style='width: 19cm' />

        <div style=" position: absolute; width: 578px; top: 38px; left: 132px; ">
          Hi everyone,<br>
          Sorry that I still haven’t suggested any 
          activities for our family holiday. First, I need to know how 
          much money we can spend, but I haven’t heard from 
          Grandma or Grandad <sup>1</sup># . I emailed them 
          last week and they <sup>2</sup># haven’t replied. Mum 
          and Dad have <sup>3</sup># paid for the hotel, but 
          most of us <sup>4</sup># need to get our bus tickets. 
          I know Uncle Tom and Aunt Susie have <sup>5</sup>#
          bought theirs. I’m not sure what we’re going to do in the 
          evenings <sup>6</sup>#. We’ll probably want to relax.<br>
          Speak soon,<br>
          Jessica
        
        </div>
        </div>

        `,
        answer: ["yet", "still", "already", "still", "already", "yet"],
      },
    ],
  },
  5: {
    unit: "Unit 2",
    id: "FP8-SB-demo-2023-U2-P23-E5",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page23/E5/Key/answerKey.png",
    component: T6v2,
    inputSize: 850,
    stylesTextInput: {
      fontSize: "24px",
    },
    hideBtnFooter: true,
    titleQuestion: [
      {
        num: "5",
        title: `
				Answer the questions with your own ideas. Use the present perfect simple and <i>just, still, yet</i> and <i>already</i>.
				`,
        color: "rgb(28, 192, 217)",
        star: 3,
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <div style="padding-left:30px">
					<div>What have you done today?</div>
					<u style='color: gray'><i>I've already finished school and I've just done my homework, but I haven't had dinner yet.</i></u>

          <div><b>1 </b>Have you already decided what career you want in the future?</div>
					<div>#.</div>

					<div><b>2 </b>Have you helped your parents at home recently?</div>
					<div>#.</div>

					<div><b>3 </b>What ambitions haven't you achieved yet?</div>
					<div>#.</div>

					<div><b>4 </b>What adventure sports have you tried?</div>
					<div>#</div>
        </div>
        `,
        answer: [""],
      },
    ],
  },
  6: {
    unit: "Unit 2",
    id: "FP8-SB-demo-2023-U2-P23-E6",
    audio: "Audios/1.18.mp3",
    video: "",
    component: Circle_Write,
    exerciseKey: "img/FriendsPlus/Page23/E6/Key/answerKey.png",
    titleQuestion: [
      {
        num: "6",
        title: `
					<headphone name='1.18'></headphone > 
					Read the dialogue and choose the correct words. Then listen and check.
				`,
        color: "rgb(27, 191, 215)",
      },
    ],
    question: {
      Write: {
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "-40px",
          fontWeight: 600,
          fontStyle: "italic",
          color: "",
          borderRadius: "50%",
        },
        selectWordStyle: { border: "2px solid rgb(0, 173, 238)" },
        limitSelect: 1,
        listWordsStyle: {},
        listWords: [
          `Have_you_been_/ Did_you_go`,
          `have_been / went`,
          `Did_you_enjoy / Have_you_enjoyed`,
          `Did_you_try / Have_you_tried`,
          `I've_never_been / I_didn't_go`,
        ],
        answers: ["0-0", "1-4", "2-0", "3-4", "4-0"],
        initialValue: [],
      },
      Layout: `
			<div style='display: flex; flex-direction: column; gap: 10px;'>
				<div style='display: flex; gap: 20px;'>
					<div style='font-weight: bold;'>Zoe</div>
					<div>
						<sup>1</sup> <input id='0' type='Circle' /> anywhere exciting recently?
					</div>	
				</div>
				<div style='display: flex; gap: 20px;'>
					<div style='font-weight: bold;'>Sam</div>
					<div>
						No, not recently. We <sup>2</sup> <input id='1' type='Circle' /> to a water park last month though.
					</div>	
				</div>
				<div style='display: flex; gap: 20px;'>
					<div style='font-weight: bold;'>Zoe</div>
					<div>
						Really? <sup>3</sup> <input id='2' type='Circle' /> it?
					</div>	
				</div>
				<div style='display: flex; gap: 20px;'>
					<div style='font-weight: bold;'>Sam</div>
					<div>
					Yes, it was awesome. What’s the most amazing ride that you’ve ever tried?
					</div>	
				</div>
				<div style='display: flex; gap: 20px;'>
					<div style='font-weight: bold;'>Zoe</div>
					<div>
						It was the roller coaster at Sun World Bà Nà Hill. <sup>4</sup> <input id='3' type='Circle' /> it?
					
					</div>	
				</div>
				<div style='display: flex; gap: 20px;'>
					<div style='font-weight: bold;'>Sam</div>
					<div>
						No, <sup>5</sup> <input id='4' type='Circle' /> go there.
					</div>	
				</div>
			</div>	
			`,
    },
  },
  7: {
    unit: "Unit 2",
    id: "FP8-SB-demo-2023-U2-P23-E7",
    audio: "",
    video: "",
    component: T6v2,
    hintBox: [
      {
        src: ["Have you been anywhere interesting recently?"],
        borderColor: "rgb(28, 192, 217)",
        arrow: true,
        position: 1,
        width: 550,
      },
      {
        src: ["Yes, I went to Cần Thơ at the weekend."],
        borderColor: "rgb(28, 192, 217)",
        arrow: true,
        position: 2,
        width: 490,
      },
    ],
    inputSize: 800,
    stylesTextInput: {
      fontSize: "24px",
    },
    titleQuestion: [
      {
        num: "7",
        title: `
					Make a dialogue with a partner on either idea A or B. Use the present perfect and past simple forms.
				`,
        color: "rgb(28, 192, 217)",
        prefix: { icons: ["far fa-comment"], text: "USE IT!" },
      },
    ],
    questionImage: [],
    hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
					<div style='display: flex; gap: 10px; flex-direction: column;'>
						<div style='display: flex; gap: 10px;'>
							<span style='font-weight: bold;'>A</span>
							<div>be / anywhere interesting</div>
						</div>
						<div style='display: flex; gap: 10px;'>
							<span style='font-weight: bold;'>B</span>
							<div>see / any good films</div>
						</div>
					</div>

					<div style='margin-top: 20px;'><hintbox id='0'></hintbox></div>
					<div style='margin-top: 20px; margin-left: 150px;'><hintbox id='1'></hintbox></div>
				`,
        answer: [],
      },
    ],
  },
};

export default json;
