import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
const json = {
  1: {
    unit: "Unit 2",
    id: "FP8-SB-demo-2023-U2-P27-E1",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page27/Key/E1answerKey.png",
    component: T6v2,
    inputSize: 120,
    maxLength: 8,
    stylesTextInput: {
      textAlign: "center",
      // borderStyle: "dotted",
      background: "none",
      borderBottom: "none",
      fontSize: "20px",
    },
    titleQuestion: [
      {
        num: "1",
        title: `
        Complete the table with the quantifiers.
				`,
        color: "rgb(0,103, 178)",
        star: 1,
      },
    ],
    hintBox: [
      {
        src: [
          "<del>a few</del>",
          "a little",
          "a lot of",
          "enough (x2)",
          "many",
          "much",
        ],
        borderColor: "rgb(0,103, 178)",
        width: 650,
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <hintBox id='0'></hintBox>
        <div style='position: relative;'>
        <img style="padding-top: 20px; width: 15cm" src='img/FriendsPlus/Page27/E1/1.jpg'  />
        
        <div style=" position: absolute; width: 128px; top: 108px; left: 279px; "><input id='0' value='text' width=''></input></div>
        <div style=" position: absolute; width: 128px; top: 173px; left: 279px; "><input id='1' value='text' width=''></input></div>
        <div style=" position: absolute; width: 128px; top: 233px; left: 303px; "><input id='2' value='text' width=''></input></div>
        <div style=" position: absolute; width: 126px; top: 304px; left: 353px; "><input id='3' value='text' width=''></input></div>
        <div style=" position: absolute; width: 126px; top: 361px; left: 416px; "><input id='4' value='text' width=''></input></div>
        <div style=" position: absolute; width: 126px; top: 418px; left: 417px; "><input id='5' value='text' width=''></input></div>

        </div>
        
        `,
        answer: ["many", "much", "a little", "enough", "enough", "a lot of"],
      },
    ],
  },
  2: {
    unit: "Unit 2",
    id: "FP8-SB-demo-2023-U2-P27-E5",
    audio: "",
    video: "",
    component: Circle_Write,
    exerciseKey: "img/FriendsPlus/Page27/Key/E2answerKey.png",
    titleQuestion: [
      {
        num: "2",
        title: `
        Cross out the incorrect word.
				`,
        color: "rgb(78, 59, 151)",
        star: 2,
      },
    ],
    question: {
      Write: {
        inputStyle: {
          fontWeight: 100,
        },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "-40px",
          // fontWeight: 900,
          color: "",
          // fontSize: 27,
          borderRadius: "50%",
          // marginInline: '-1px',
        },
        selectWordStyle: {
          border: "2px solid rgb(0, 173, 238)",
          padding: "5px",
        },
        limitSelect: 1,
        listWordsStyle: {
          fontWeight: "bold",
        },
        listWords: [
          "time / money / pounds ",
          "enough / a_few / not_enough",
          "space / people / boxes",
          "many / enough / a_lot_of ",
          "a_few / enough / much",
          "a_lot_of / a_few / too_much ",
          "too much / too_many / enough",
          "a_lot_of / a_few / a_little",
        ],
        answers: ["0-8", "1-4", "2-0", "3-0", "4-8", "5-4", "6-6", "7-8"],
        initialValue: [],
      },
      Layout: `

      <div style='line-height: 50px'>
      <div>There weren't many <b>people / <del>food</del> / presents</b> at the birthday party.</diV>
        <div>
            <div><strong>1 </strong>How much <strong><input id='0' type='Circle'/></strong>did you spend on this?</div>
        </div>

        <div style='top:0px; left:0px ; '>
            <div><b>2 </b>There is <strong><input id='1' type='Circle' /></strong> food for everyone here.</div>
        </div>

        <div style='top:0px; left:0px ; '>
            <div><b>3 </b>There are a lot of <strong><input id='2' type='Circle' /></strong> in the room at the moment.</div>
        </div>

        <div style='top:0px; left:0px ; '>
            <div><b>4 </b>I haven't got <strong><input id='3' type='Circle' /></strong> money.</div>
        </div>

        <div style='top:0px; left:0px ; '>
            <div><b>5 </b>They've planted <strong><input id='4' type='Circle' /></strong> new trees in the forest.</div>
        </div>

        <div style='top:0px; left:0px ; '>
            <div><b>6 </b>The children next door make <strong><input id='5' type='Circle' /></strong> when they're playing.</div>
        </div>

        <div style='top:0px; left:0px ; '>
            <div><b>7 </b>I think there are <strong><input id='6' type='Circle' /></strong> stories about the environment in the news!</div>
        </div>

        <div style='top:0px; left:0px ; '>
            <div><b>8 </b>Have you read <strong><input id='7' type='Circle' /></strong> newspaper articles about food waste?</div>
        </div>
      </div>
			  `,
    },
  },
  3: {
    unit: "Unit 2",
    id: "FP8-SB-demo-2023-U2-P27-E3",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page27/Key/E3answerKey.png",
    component: T6,
    inputSize: 170,
    maxLength: 16,
    stylesTextInput: {
      textAlign: "center",
      // borderStyle: "dotted",
      background: "none",
      // borderBottom: "none",
      fontSize: "21px",
    },
    titleQuestion: [
      {
        num: "3",
        title: `
        Complete the dialogue with the words.
				`,
        color: "rgb(0,103, 178)",
        star: 2,
      },
    ],
    hintBox: [
      {
        src: [
          "a few",
          "a little",
          "a lot of",
          "enough",
          "many",
          "<del>much</del>",
          "not enough",
        ],
        borderColor: "rgb(0,103, 178)",
        width: 750,
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <hintBox id='0'></hintBox>
        <div style='display: flex; font-size: 21px; line-height: 40px;'>
        <div style='margin-right: 20px'>
          Emily<br><br>
          Harry<br>
          Emily<br><br>
          Harry<br>
          Emily<br><br>
          Harry<br>
          Emily<br><br>
          Harry<br><br>
          Emily<br>

        </div>
        <div style="width: 850px">
          <div>I’m reading an article about food waste. 
          How much food do you think we throw away every year?</div>
          <div>I don’t know. It must be more than <sup>1</sup># food. Maybe 20%?</div>
          <div>No, 20% isn’t high <sup>2</sup># ! We waste fifteen million tonnes a year!<br> 
          That’s around 35% of the food we buy!</div>
          <div>Wow! That’s <sup>3</sup># food!</div>
          <div>Also, <sup>4</sup># of the food products 
          that the supermarkets throw away are still OK for us to eat.</div>
          <div>Oh, that’s terrible, isn’t it?</div>
          <div>Yes, it is. There are <sup>5</sup># charity 
          programmes for unsold food, but that’s <sup>6</sup># .</div>
          <div>No, it isn’t. Did you know that in France 
          all the food that supermarkets don’t sell goes to charity? It’s the law.</div>
          <div>What a great idea! Why can’t we do that?</div>
          </div>
        `,
        answer: [
          "a little",
          "enough",
          "a lot of",
          "many",
          "a few",
          "not enough",
        ],
      },
    ],
  },

  4: {
    unit: "Unit 2",
    id: "FP8-SB-demo-2023-U2-P27-E4",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page27/Key/E4answerKey.png",
    component: T6,
    inputSize: 330,
    stylesTextInput: {
      textAlign: "center",
      borderStyle: "dotted",
      fontSize: "24px",
    },
    textareaStyle: { width: 830 },
    hideBtnFooter: true,
    titleQuestion: [
      {
        num: "4",
        title: `
        Write one or two sentences about the topics using quantifiers.
				`,
        color: "rgb(78, 59, 151)",
        star: 3,
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
          <div>
            <div>the time you spend doing homework</div>
            <u style='color: gray'><i>We usually have a lot of homework. I spend a few hours a week doing it.</i></u>

            <div>
            <div><b>1 </b> the money you spend on clothes<br><textarea id="0" rows="2"></textarea></div>
            <div><b>2 </b> the different foods you eat<br><textarea id="1" rows="2"></textarea></div>
            <div><b>3 </b> the money that people earn in different jobs<br><textarea id="2" rows="2"></textarea></div>
            <div><b>4 </b> the things for teenagers to do in your area<br><textarea id="3" rows="2"></textarea></div>
            </div>
          </div>
        `,
        answer: [],
      },
    ],
  },
  7: {
    unit: "Unit 2",
    id: "FP8-SB-demo-2023-U2-P27-E7",
    audio: "",
    video: "",
    component: Circle_Write,
    exerciseKey: "img/FriendsPlus/Page27/E7/Key/answerKey.png",
    // hideBtnFooter: true,
    titleQuestion: [
      {
        num: "7",
        title: `
          Choose the correct words.
				`,
        color: "rgb(78, 59, 151)",
      },
    ],
    question: {
      Write: {
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          marginLeft: "10px",
          padding: "-30px",
          fontWeight: 100,
          fontStyle: "italic",
          color: "",
          // fontSize: 27,
          borderRadius: "50%",
          // marginInline: '-1px',
        },
        selectWordStyle: { border: "2px solid rgb(0, 173, 238)" },
        limitSelect: 1,
        listWordsStyle: {},
        listWords: [
          `a_In_these&nbsp;&nbsp;&nbsp;&nbsp; b_In_those&nbsp;&nbsp;&nbsp;&nbsp; c_On_these&nbsp;&nbsp;&nbsp;&nbsp;`,
          `a_Such_as&nbsp;&nbsp;&nbsp;&nbsp; b_Like&nbsp;&nbsp;&nbsp;&nbsp; c_For_example&nbsp;&nbsp;&nbsp;&nbsp;`,
          `a_The_sixties_was_the_decade&nbsp;&nbsp;&nbsp;&nbsp; b_In_the_sixties&nbsp;&nbsp;&nbsp;&nbsp; c_These_days&nbsp;&nbsp;&nbsp;&nbsp;`,
          `a_like&nbsp;&nbsp;&nbsp;&nbsp; b_of&nbsp;&nbsp;&nbsp;&nbsp; c_as&nbsp;&nbsp;&nbsp;&nbsp;`,
          `a_At&nbsp;&nbsp;&nbsp;&nbsp; b_When&nbsp;&nbsp;&nbsp;&nbsp; c_For&nbsp;&nbsp;&nbsp;&nbsp;`,
          `a_On&nbsp;&nbsp;&nbsp;&nbsp; b_In&nbsp;&nbsp;&nbsp;&nbsp; c_At&nbsp;&nbsp;&nbsp;&nbsp;`,
          `a_decade&nbsp;&nbsp;&nbsp;&nbsp; b_times&nbsp;&nbsp;&nbsp;&nbsp; c_days&nbsp;&nbsp;&nbsp;&nbsp;`,
          `a_example&nbsp;&nbsp;&nbsp;&nbsp; b_such&nbsp;&nbsp;&nbsp;&nbsp; c_for_instance&nbsp;&nbsp;&nbsp;&nbsp;`,
          `a_such&nbsp;&nbsp;&nbsp;&nbsp; b_like&nbsp;&nbsp;&nbsp;&nbsp; c_instance&nbsp;&nbsp;&nbsp;&nbsp;`,
        ],
        answers: [
          "0-2",
          "1-4",
          "2-0",
          "3-4",
          "4-0",
          "5-2",
          "6-0",
          "7-4",
          "8-2",
        ],
        initialValue: [],
      },
      Layout: `
        <div style='position: relative; padding: 15px; border: 1px solid rgb(143, 144, 148); border-radius: 10px; box-shadow: 7px 7px 10px rgb(143, 144, 148);'>
          <div style='position: absolute; top: 90px; left: 421px;'><img src='img/FriendsPlus/Page27/E7/1.jpg' width='70%' /></div>
          <div style='font-size: 28px;'>Fact file: <strong>Flared trousers</strong></div>
          <div style='margin-top: 7px; display: flex; flex-direction: column; gap: 5px;'>
            <div style='width: 400px;'>
              <strong>The beginnings</strong>
              <div>
              American sailors were the first to 
              wear flared trousers in the 19th 
              century. <sup>1</sup>.......... days, trousers 
              with a wide leg below the knee were 
              practical for many reasons. 
              <sup>2</sup>.........., they were easy to roll 
              up when cleaning the ship or to take 
              off when they got wet.
              </div>
            </div>
            <div style='width: 700px;'>
              <strong>Craze of the 1960s and 1970s</strong>
              <div>
                <sup>3</sup>.......... when flared trousers 
                became popular. Many singers, 
                such <sup>4</sup>.......... Sonny and Cher, 
                wore them. <sup>5</sup>.......... that time,  
                young people made their own flared trousers because 
                shops weren’t selling them. <sup>6</sup>.......... the 
                seventies, you could buy them in almost every shop. 
                Hardly anyone chose a different style of trousers 
                during this <sup>7</sup>.......... .
              </div>
            </div>
            <div style='width: 700px;'>
              <strong>A comeback</strong>
              <div>
              There were a few times when flared trousers came 
              back, <sup>8</sup>.........., throughout the 1990s and in 
              2015. Although fashion designers, <sup>9</sup>.......... 
              Balmain and Gucci, presented them during fashion 
              shows, they didn’t become as popular as in the 1970s.
              </div>
            </div>
          </div>
        </div>

        <div style='margin-top: 20px; display: flex; gap: 20px; flex-direction: column;'>
          <div style='display: flex; gap: 10px;'>
            <span style='font-weight: bold;'>1</span>
            <div><input id='0' type='Circle' /></div>
          </div>
          <div style='display: flex; gap: 10px;'>
            <span style='font-weight: bold;'>2</span>
            <div><input id='1' type='Circle' /></div>
          </div>
          <div style='display: flex; gap: 10px;'>
            <span style='font-weight: bold;'>3</span>
            <div><input id='2' type='Circle' /></div>
          </div>
          <div style='display: flex; gap: 10px;'>
            <span style='font-weight: bold;'>4</span>
            <div><input id='3' type='Circle' /></div>
          </div>
          <div style='display: flex; gap: 10px;'>
            <span style='font-weight: bold;'>5</span>
            <div><input id='4' type='Circle' /></div>
          </div>
          <div style='display: flex; gap: 10px;'>
            <span style='font-weight: bold;'>6</span>
            <div><input id='5' type='Circle' /></div>
          </div>
          <div style='display: flex; gap: 10px;'>
            <span style='font-weight: bold;'>7</span>
            <div><input id='6' type='Circle' /></div>
          </div>
          <div style='display: flex; gap: 10px;'>
            <span style='font-weight: bold;'>8</span>
            <div><input id='7' type='Circle' /></div>
          </div>
          <div style='display: flex; gap: 10px;'>
            <span style='font-weight: bold;'>9</span>
            <div><input id='8' type='Circle' /></div>
          </div>
        </div>
			  `,
    },
  },
};

export default json;
