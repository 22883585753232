import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";

const json = {
  1: {
    unit: "Unit 7",
    id: "FP8-WB-demo-2023-U7-P49-E1",
    audio: "",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page49/key/E1answerKey.png",
    component: T6v2,
    // maxLength: 6,
    inputSize: 50,
    maxLength: 1,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      textAlign: "center",
      fontSize: "24px",
      fontWeight: "bold",
    },
    titleQuestion: [
      {
        num: "1",
        star: 1,
        title: `
		Match 1–6 with a–f. 
							`,
        color: "#04A750",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
			<div style='display:flex'>
			<div>
			<div style='display:flex'>
			<b>1</b>
			<div style='margin-left:10px'>
			The government wants to ban violent video
			<br> games for teenagers under eighteen because <b style='border-bottom:1px solid black'>b</b> 
			</div>
			</div>

			<div style='display:flex'>
			<b>2</b>
			<div style='margin-left:10px'>
			The majority of young people are responsible <br>
			and most of <input/>  
			</div>
			</div>

			<div style='display:flex'>
			<b>3</b>
			<div style='margin-left:10px'>
			Some young people aren’t happy about the 
			<br> plan to ban particular video games so  <input/> 
			</div>
			</div>

			<div style='display:flex'>
			<b>4</b>
			<div style='margin-left:10px'>
			Schools are worried about students not doing 
			<br> enough PE or exercise because they think  <input/> 
			</div>
			</div>

			<div style='display:flex'>
			<b>5</b>
			<div style='margin-left:10px'>
			Teachers need to explain the importance of PE 
			<br>to parents so <input/> 
			</div>
			</div>

			<div style='display:flex'>
			<b>6</b>
			<div style='margin-left:10px'>
			All parents will receive information letters 
			<br> about the changes to PE and <input/> 
			</div>
			</div>

			</div>
			<div style='margin-left:50px;line-height:3'>
			<b>a</b>&ensp;they’re complaining about it on social media. <br>
			<b>b</b>&ensp;they could have a negative influence.  <br>
			<b>c</b>&ensp;these will arrive next week. <br>
			<b>d</b>&ensp;them don’t play very violent games. <br>
			<b>e</b>&ensp;this problem sometimes leads to obesity. <br>
			<b>f</b>&ensp;they’re going to speak to them soon. 
 
			</div>
			</div>
							`,
        answer: ["d", "a", "e", "f", "c"],
      },
    ],
  },
  2: {
    unit: "Unit 7",
    id: "FP8-WB-demo-2023-U7-P49-E2",
    audio: "",
    video: "",
    component: Circle_Write,
    exerciseKey: "img/FriendsPlus/Page49/Key/E2answerKey.png",
    titleQuestion: [
      {
        num: "2",
        star: 2,
        title: `
            Choose the correct words in the first two paragraphs of a discussion essay.
                  `,
        color: "#04A750",
      },
    ],
    question: {
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: 0,
          // border: "none",
          border: "2px solid #000000", // Sửa giá trị border thành "1px solid #000000"
          borderRadius: "50%",
          borderColor: "transparent",
          color: "#000000",
        },
        selectWordStyle: {
          padding: 5,
          borderColor: "#00aeef",
        },

        limitSelect: 1,
        listWords: [
          "they / it / them", //0
          "these / that / this", //1
          "he / them / this", //2
          "him / he / it", //3
          "them / it / these", //4
          "him / he / them", //5
        ],
        answers: ["0-8", "1-0", "2-8", "3-4", "4-4", "5-0"],
        initialValue: [],
      },
      Layout: `
      <div style='font-size:22px;line-height:2'>
	  <span style='border:1px solid rgb(4 167 80);padding: 10px 20px;background-color:rgb(215 238 222)'>TV programmes that show violence should be banned before eleven o’clock at night. Discuss.
	  </span> <br>
    
    <div style='border:0px solid black; padding: 10px 20px; width:955px;box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;margin-top:20px;background: rgb(255 255 255);
    background: radial-gradient(circle, rgb(255 238 255) 0%, rgba(209,233,208,1) 100%);'>
    Some people say that violent programmes on TV should be banned because 
    <span style='border:3px solid black;border-radius:50%;font-weight:bold'>they</span>  <b> / them / this</b> are bad for children and teenagers. <br> 
    One of the arguments for the ban is that violent TV programmes are too realistic
    and watching <sup>1</sup> <b><input id='0' type='Circle' /></b> can encourage violence in real life.
    In addition, some people have suggested that <sup>2</sup> <b><input id='1' type='Circle' /></b> programmes
    can be addictive and <sup>3</sup> <b><input id='2' type='Circle' /></b> can become a problem. For instance,
    my friend Alistair told me that <sup>4</sup> <b><input id='3' type='Circle' /></b> was watching a violent series
    for three hours a day. I was worried that <sup>5</sup> <b><input id='4' type='Circle' /></b> was bad
    for <sup>6</sup> <b><input id='5' type='Circle' /></b> .
 
    </div>
    
</div>

       


      `,
    },
  },
  3: {
    unit: "Unit 7",
    id: "FP8-WB-demo-2023-U7-P49-E3",
    audio: "",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page49/key/E3answerKey.png",
    component: T6v2,
    maxLength: 14,
    inputSize: 160,
    stylesTextInput: {
      background: "none",
      // border: 'none',
      textAlign: "center",
      fontSize: "22px",
      fontWeight: "bold",
    },
    titleQuestion: [
      {
        num: "3",
        star: 2,
        title: `
            Read and complete the discussion  essay with the words.

                  `,
        color: "#04A750",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
				
        <div style="width:1000px;border: 1px solid green; display: flex; justify-content:center;padding: 10px 20px; border-radius: 40px">
        <div style="margin-right:20px"><span>addition</span></div>
        <div style="margin-right:20px"><span>a̶̶g̶̶a̶̶i̶̶n̶̶s̶̶t̶</span> </div>
        <div style="margin-right:20px"><span>arguments</span></div>
        <div style="margin-right:20px"><span>for</span></div>
        <div style="margin-right:20px"><span>hand</span></div>
        <div style="margin-right:20px"><span>however</span></div>
        <div style="margin-right:20px"><span>instance</span></div>
        <div style="margin-right:20px"><span>one</span></div>
        <div style="margin-right:20px"><span>personally
        </span></div>
        </div>

        <div style='font-size: 22px; border:0px solid black; padding: 10px 20px; width:1000px;box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;margin-top:20px;'>
    <b>Students should do PE every day at school. Discuss.
    </b> <br>
    Our school plans to introduce PE lessons every day. 
    But is it fair to make everybody do exercise so often? There are arguments for and against this idea. <br>
    On the <sup>1</sup> <input/> hand, doing PE every day would help students to keep fit. Some students never do sport outside school.
    For <sup>2</sup> <input/> , one group of students explained that they don’t have time for it. What’s more, most students spend too 
    much time in front of screens. One of the <sup>3</sup> <input/> for more PE is that it could help to prevent obesity and a ‘couch potato’ lifestyle. <br>

    On the other <sup>4</sup> <input/> , some students complain that they don’t want more PE at school because they don’t enjoy the sports that are offered.
    In <sup>5</sup> <input/> , more PE lessons would affect the school timetable.  <sup>6</sup> <input/> example, many teachers have said that PE every day would be too much
    and it would reduce the time for subjects like maths, languages or science. 

    <div style='display:flex'>
    <div style='flex:1'>
    <sup>7</sup> <input/> , I am  not in favour of PE every day because it would take too much time from the school timetable. <br>
    <sup>8</sup> <input/> , I think students should do PE three times a week so that they get enough exercise.

    </div>
    <div style='flex:1'>
    <img src='img/FriendsPlus/Page49/E1/1.jpg' style='margin-left:117px; width: 10cm; height: 7cm'>
    </div>
    
    </div>
 
    </div>
    
		
							`,
        answer: [
          "one",
          "instance",
          "arguments",
          "hand",
          "addition",
          "For",
          "Personally",
          "However",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 7",
    id: "FP8-WB-demo-2023-U7-P49-E4",
    audio: "",

    video: "",
    // recorder: true,
    exerciseKey: "",
    component: T6v2,
    // maxLength: 6,
    inputSize: 200,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      textAlign: "center",
      fontSize: "24px",
    },
    titleQuestion: [
      {
        num: "4",
        star: 3,
        title: `
      Write a discussion essay on this topic: 
      `,
        color: "#04A750",
      },
    ],
    textareaStyle: { width: 1000 },
    questionImage: [],
    hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <span style='border:1px solid green;padding:10px 20px; margin-bottom: 30px'>Video games which show violence should be banned for people under the age of eighteen. </span> <br><br>
<b style='margin-top: 30px'>Use the discussion essay in exercise 3 to help you and think about the following things:</b>
		<ul>
    <li>Do many teenagers play violent video games?</li>
    <li>What are the arguments for a ban? </li>
    <li>What are the arguments against a ban? </li>
    <li>What is your opinion and why?
    </li>

    </ul>
    <textarea id='0' rows='7'></textarea>
				`,
        answer: [""],
      },
    ],
  },
};

export default json;
