import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";

const json = {
  1: {
    unit: "Unit 3",
    id: "FP8-SB-demo-2023-U3-P30-E1",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page33/E1/Key/E1answerKey.png",
    component: T6v2,
    isHiddenCheck: true,
    maxLength: 6,
    titleQuestion: [
      {
        num: "1",
        title: `
        Complete the table with the words.
				`,
        color: "rgb(66,153,210)",
        star: 1,
      },
    ],

    stylesTextInput: {
      fontSize: "22px",
      background: "none",
      textAlign: "center",
      height: 28,
    },
    inputSize: 150,
    questions: [
      {
        title: `
					<div style='display: flex;flex-direction:column; font-size: 22px;'>
          <div style='display: flex; flex-direction:column;flex-wrap: wrap; align-items: center; justify-content: center;
					 width:700px; padding: 10px 80px;border-radius: 20px; border: 3px solid rgb(66,153,210);'>
					<div>
          arrive <span style="text-decoration:line-through">if</span> late leave unless we’ll 
					</div>
          </div>

          <div style="display: inline-block; position: relative">
          <img src="img/FriendsPlus/Page33/E1/1.jpg"/ style='width: 20cm; margin-top: 20px'>
            <div style="position:absolute;top: 100px; left: 54px"><b>Situation</b><br><u style='color: gray'>&ensp;If&ensp;</u>&ensp;we leave now,
           <br>
            <b>Result</b><br>We’ll <sup>2</sup># on time
            </div>
            <div style="position:absolute;top: 100px;left:413px"><b>Result</b><br><sup>1</sup>#  arrive on time.
            <br><br>
            <b>Situation</b><br>if we <sup>3</sup># now.
            </div>
           
            <div style="position:absolute; top:355px; left:54px"><sup>4</sup># we</div>
            <div style="position:absolute; top:340px; left:413px">leave now, we’ll arrive <sup>5</sup># !</div>
          </div>

					</div>
         
				`,
        answer: [`arrive`, `we'll`, `leave`, `Unless`, `late`],
      },
    ],
    tb2Style: { width: 400 },
    type: 2, // Dạng 2 thì nhập số 2
    data: [
      {
        title: "Land",
        content: ["#", "#", "#", "#", "#", "#"],
        answers: [`valley`, `cave`, `rainforest`, `beaches`, `dunes`, `desert`],
        commonStyle: {},
        titleStyle: {
          backgroundColor: "#7FA8D9",
          border: "1px solid #468DCB",
          color: "#fff",
        },
        contentStyle: {
          backgroundColor: "#CDDBF0",
          border: "1px solid #468DCB",
          color: "#58585a",
        },
      },
      {
        title: "Water",
        content: ["see", "#", "#", "#", "#"],
        answers: [``, `river`, `falls`, `waves`, `ocean`],
        commonStyle: {},
        titleStyle: {
          backgroundColor: "#7FA8D9",
          border: "1px solid #468DCB",
          color: "#fff",
        },
        contentStyle: {
          backgroundColor: "#CDDBF0",
          border: "1px solid #468DCB",
          color: "#58585a",
        },
      },
    ],
  },
  2: {
    unit: "Unit 3",
    id: "FP8-SB-demo-2023-U3-P33-E2",
    audio: "",
    exerciseKey: "img/FriendsPlus/Page33/E2/key/E2answerkey.png",
    component: T6v2,
    inputSize: 750,
    stylesTextInput: {
      fontSize: "22px",
    },
    titleQuestion: [
      {
        num: "2",
        title: `
				Order the words to make sentences and questions.
				`,
        color: "rgb(66,153,210)",
        star: 2,
      },
    ],

    isHiddenCheck: true,

    questions: [
      {
        title: `
        <div style='font-size: 22px; line-height: 45px'>
        
       
			<div>to / learn / <span style="text-decoration:line-through">we’ll</span> / teacher / the / more / if / 
      listen / we</div>
      <div>We'll <strong style="text-decoration:underline; color: gray">&ensp;learn more if we listen to the teacher.&ensp;</strong></div>
		   
      <div style="display:flex;flex-direction:column">
      
      <div><strong style="margin-right:30px">1</strong>wake / early / you / exhausted / you’ll / <span style="text-decoration:line-through">if</span> / be / 
      up</div> 
      <div style="margin-left:40px">If #</div>

      <div><strong style="margin-right:30px">2</strong>a car / her / driving / <span style="text-decoration:line-through">she</span> / gets / licence / 
      unless / she / won’t / buy </div> 
      <div style="margin-left:40px">She #</div>

      <div><strong style="margin-right:30px">3</strong>resources / less / we / <span style="text-decoration:line-through">if</span> / eat / will / meat / 
      save / we ? </div> 
      <div style="margin-left:40px">If #</div>

      <div><strong style="margin-right:30px">4</strong>reduce / planet / we / the / we’ll / <span style="text-decoration:line-through">unless</span> / 
      waste / destroy</div> 
      <div style="margin-left:40px">Unless #</div>

      <div><strong style="margin-right:30px">5</strong><span style="text-decoration:line-through">they</span> / products / they’re / the / won’t / 
      ecological / use / unless</div> 
      <div style="margin-left:40px">They #</div>

      <div><strong style="margin-right:30px">6</strong>I / if / hard / work / rich / I / get / <span style="text-decoration:line-through">will</span> ? </div>
      <div style="margin-left:40px">Will #</div>
      
      </div>
      </div>
      `,
        answer: [
          `you wake up early, you'll be exhausted.`,
          `won't buy a car unless she gets her driving licence.`,
          `we eat less meat, will we save resources?`,
          `we reduce waste, we'll destroy the planet.`,
          `won't use the products unless they're ecological.`,
          `I get rich if I work hard?`,
        ],
      },
    ],
  },
  3: {
    unit: "Unit 3",
    id: "FP8-SB-demo-2023-U3-P33-E2",
    audio: "",
    exerciseKey: "img/FriendsPlus/Page33/E3/Key/E3answerKey.png",
    component: T6v2,
    inputSize: 120,
    maxLength: 10,
    stylesTextInput: {
      fontSize: "21px",
      background: "none",
      // border: "none",
      height: 28,
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "3",
        title: `
        Correct five mistakes in Gaetan’s post. Tick (✓) the three correct answers.
				`,
        color: "rgb(66,153,210)",
        star: 2,
      },
    ],

    isHiddenCheck: true,
    questions: [
      {
        title: `
        <div style="display:inline-block;position:relative; font-size: 21px; line-height: 37px">
        
        <img src="img/FriendsPlus/Page33/E3/1.jpg" style='width: 21cm; height: 17cm'/>
        <div style="position:absolute; top:50px; left:57px; width: 368px">
        Many people say that 
        the future will be better 
        than the past, but I’m not 
        sure. <b>If</b> <u style='color: gray'>&ensp;Unless&ensp;</u> we 
        develop better ways of 
        processing energy, we <sup>1</sup><b>harm</b>#
         the environment further.
        </div>
        <div style="position:absolute; top:281px; left:57px; width: 598px">
        And <sup>2</sup><b>if</b># that <sup>3</sup><b>will happen</b># 
         , one day we’ll need to go and 
        live on another planet. What <sup>4</sup><b>will</b># we 
        do <sup>5</sup><b>unless</b>#
        we don’t develop the technology in time? If 
        we <sup>6</sup><b>'ll travel</b># to Mars, we’ll need to 
        produce our own water and air. Unless technology 
        <sup>7</sup><b>doesn’t move</b># a lot faster, we <sup>8</sup><b>won't be</b># ready to leave the Earth for 
        a very long time.<br>
        – Gaetan, China
        </div>
        </div>
		
    
      `,
        answer: [`'ll harm`, `✓`, `happens`, `✓`, `if`, `travel`, `moves`, `✓`],
      },
    ],
  },
  4: {
    unit: "Unit 3",
    id: "FP8-SB-demo-2023-U3-P33-E4",
    exerciseKey: "img/FriendsPlus/Page33/E4/key/E4answerkey.png",
    component: T6v2,
    inputSize: 250,
    maxLength: 25,
    stylesTextInput: {
      fontSize: "23px",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "4",
        title: `
        Complete the sentences with the correct 
        form of <i>might</i> or <i>will</i> and the verbs in brackets.
				`,
        color: "rgb(66,153,210)",
        star: 2,
      },
    ],
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <div style='line-height: 50px; font-size: 23px'>
        <div>They <strong style="text-decoration:underline; color: gray">’ll try</strong> hard to win. They always do. (try)</div>
				    <div style='display: flex; gap: 20px; flex-direction: column;'>
				    		<div style='display: flex; gap: 10px;'>
				    			<span style='font-weight: bold;'>1</span>
				    			<div>If the train is busy today, you # a 
                  seat. (find)</div>
				    		</div>
				    		<div style='display: flex; gap: 10px;'>
				    			<span style='font-weight: bold;'>2</span>
				    			<div>She # there, but she’s usually out. (be)</div>
				    		</div>
				    		<div style='display: flex; gap: 10px;'>
				    			<span style='font-weight: bold;'>3</span>
				    			<div>You # the book. It’s boring. (like)</div>
				    		</div>
				    		<div style='display: flex; gap: 10px;'>
				    			<span style='font-weight: bold;'>4</span>
				    			<div>It’s too far to walk. I # the bus. (get)</div>
				    		</div>
                <div style='display: flex; gap: 10px;'>
                <span style='font-weight: bold;'>5</span>
                <div>In the future, people # holidays in 
                space. (have)</div>
              </div>
              <div style='display: flex; gap: 10px;'>
              <span style='font-weight: bold;'>6</span>
              <div>Look at the traffic! We # early. (arrive)</div>
            </div>
					</div>

        </div>
				`,
        answer: [
          `might not find `,
          `might be`,
          `won't like`,
          `'ll get `,
          `might have`,
          `won't arrive`,
        ],
      },
    ],
  },
  5: {
    unit: "Unit 3",
    id: "FP8-SB-demo-2023-U3-P33-E5",
    component: T6v2,
    hideBtnFooter: true,
    hintBox: [
      {
        src: [`do`, `feel`, `listen`, `live`, `speak`, `use`],
        borderColor: "rgb(28, 192, 217)",
        width: 450,
      },
      {
        src: ["Have you been speaking a lot of English recently?"],
        borderColor: "rgb(28, 192, 217)",
        arrow: true,
        position: 1,
        width: 600,
      },
      {
        src: ["Yes, I have. I’ve been talking to my pen pal online."],
        borderColor: "rgb(28, 192, 217)",
        arrow: true,
        position: 2,
        width: 600,
      },
    ],
    textareaStyle: {
      width: 900,
    },
    inputSize: 250,
    stylesTextInput: {
      fontSize: "23px",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "5",
        title: `
        Think about the results of these future 
        events. Write one first conditional sentence with 
        <i>if</i> and one with <i>unless</i>.
				`,
        color: "rgb(66,153,210)",
        star: 3,
      },
    ],
    isHiddenCheck: true,
    questions: [
      {
        title: `
				 <span>We invent cleaner cars. 
         </span><br>
         <strong style="text-decoration:underline; color: gray">If we invent cleaner cars, we’ll have less pollution.
         Pollution won’t improve unless we invent cleaner cars.</strong>

         <div style="display:flex;flex-direction:column">
           <span><strong style="margin-right:30px">1</strong>I get a good job .</span>
           <textarea id="0" rows=2></textarea>
         <span><strong style="margin-right:30px">2</strong>People live a lot longer.</span>
         <textarea id="1" rows=2></textarea>
		     <span><strong style="margin-right:30px">3</strong>We use computers more and more.</span>
         <textarea id="2" rows=2></textarea>
         </div>

        
    
         `,
        answer: [],
      },
    ],
  },
};

export default json;
