import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";

const json = {
  1: {
    unit: "Unit Starter",
    id: "FP8-WB-demo-2023-U1-P11-E1",
    audio: "",
    video: "",
    exerciseKey: "/img/FriendsPlus/Page11/Key/E1answerKey.png",
    component: T6v2,
    // checkDuplicated: true,
    inputSize: 130,
    maxLength: 10,
    stylesTextInput: {
      fontSize: "24px",
      textAlign: "center",
      background: "transparent",
    },
    titleQuestion: [
      {
        num: "1",
        title: `Complete the table using was or were
        and the correct -ing form of the verbs in brackets.`,
        color: "rgb(120,199,215)",
        star: 1,
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    checkUppercase: true,
    questions: [
      {
        title: `
      <div style='position: relative; font-size: 24px'>
        <div style='display:flex'>
            <img src="/img/FriendsPlus/Page11/E1/1.jpg" style='height:17cm; width: 21cm'  >
        </div>
        <div style=" position: absolute; top: 100px; left: 51px; ">I / He / She / It<br><br>You / We / You / They</div>
        <div style=" position: absolute; top: 100px; left: 352px; ">was<br><br><sup>2</sup><input id='1'></div>
        <div style=" position: absolute; top: 82px; left: 575px; "><sup>1</sup><input id='0'> (listen) to music.<br><sup>3</sup><input id='2'>(watch) TV.</div>

        <div style=" position: absolute; top: 290px; left: 51px; ">I / He / She / It<br><br>You / We / You / They</div>
        <div style=" position: absolute; top: 290px; left: 352px; "><sup>4</sup><input id='3'>n’t<br><sup>5</sup><input id='4'>n’t</div>
        <div style=" position: absolute; top: 282px; left: 575px; ">drinking water.<br><sup>6</sup><input id='5'>(do)<br>the homework.</div>

        <div style=" position: absolute; top: 457px; left: 48px; "><sup>7</sup><input id='6'>she playing<br> tennis?<br><sup>9</sup><input id='8'>they <br>sleeping?</div>
        <div style=" position: absolute; top: 457px; left: 352px; ">Yes, she was.<br>No, she <sup>8</sup><input id='7'> n’t.<br>Yes, they <sup>10</sup><input id='9'>.<br> No, they weren’t.</div>
    
      </div>
        `,
        answer: [
          `were`,
          `listening`,

          `watching`,
          `was`,
          `were`,
          `doing`,

          `Was`,
          `Were`,
          `was`,

          `were`,
        ],
      },
    ],
  },
  2: {
    unit: "Unit Starter",
    id: "FP8-WB-demo-2023-U1-P11-E2",
    audio: "",
    video: "",
    exerciseKey: "/img/FriendsPlus/Page11/Key/E2answerKey.png",
    component: T6v2,
    checkUppercase: true,
    // checkDuplicated: true,
    inputSize: 800,
    maxLength: 70,
    stylesTextInput: {
      fontSize: "24px",
      textAlign: "left",
      background: "transparent",
    },
    titleQuestion: [
      {
        num: "2",
        title: `Write sentences and questions using the 
        past continuous.`,
        color: "rgb(120,199,215)",
        star: 2,
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
      <div style='line-height: 45px'>
          <div>Rashid / wait for / his bus for twenty minutes</div>
          <u style='color: gray'><strong>&ensp;Rashid was waiting for his bus for twenty minutes.&ensp;</strong></u>
          <div><strong style="margin-right:15px">1</strong>what / you / study / in class / ?</div>
          <div>#</div>
          
          <div><strong style="margin-right:15px">2</strong>they / not listen / to the song</div>
          <div>#</div>
          
          <div><strong style="margin-right:15px">3</strong>he / eat / a sandwich ? No / he / not</div>
          <div>#</div>
          
          <div><strong style="margin-right:15px">4</strong>we / play / computer games / all evening</div>
          <div>#</div>
          
          <div><strong style="margin-right:15px">5</strong>you / work ? Yes / I</div>
          <div>#</div>
          
          <div><strong style="margin-right:15px">6</strong>who / wear / a hoodie / ?</div>
          <div>#</div>
          
      </div>
        `,
        answer: [
          "What were you studying in class?",

          "They weren't listening to the song.",

          "Was he eating a sandwich? No, he wasn't.",
          "We were playing computer games all evening.",
          "Were you working? Yes, I was.",
          "Who was wearing a hoodie?",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 1",
    id: "FP8-WB-demo-2023-U1-P11-E3",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "/img/FriendsPlus/Page11/Key/E3answerKey.png",
    component: T6,
    textAlign: "center",
    // maxLength: 1,
    inputSize: 180,
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      fontSize: "22px",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "3",
        title: `
					
        Complete the description using the past 
        simple or past continuous form of the verbs in brackets.
				`,
        color: "rgb(120,199,215)",
        star: 2,
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <div style='width: 850px; font-size: 22px; line-height: 45px'>
        
        One of my favourite photos is of a barbecue that 
        we <u style='color: gray'>had</u> (have) on the beach last year. 
        My sister <sup>1</sup># (take) the photo while 
        I <sup>2</sup># (cook) the food. I remember the 
        delicious smell of food that <sup>3</sup># (be) in 
        the air! In the background of the photo you can 
        see my friends. They <sup>4</sup># (chat), 
        the sun <sup>5</sup># (go) down and the sky <sup>6</sup># (get) dark. A few minutes later, 
        the food <sup>7</sup># (be) ready and we <sup>8</sup># (eat) our dinner.
            <img src="/img/FriendsPlus/Page11/E3/1.jpg" style='height:9cm;'  >
        
      </div>
        `,
        answer: [
          "took",
          "was cooking",
          "was",
          "were chatting",
          "was going",
          "was getting",
          "was",
          "ate",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 1",
    id: "FP8-WB-demo-2023-U1-P11-E4",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "",
    component: T6,
    textAlign: "center",
    // maxLength: 1,
    hideBtnFooter: true,
    inputSize: 380,
    titleQuestion: [
      {
        num: "4",
        title: `
        Answer the questions. Write complete 
        sentences that are true for you.
				`,
        color: "rgb(120,199,215)",
        star: 3,
      },
    ],
    questionImage: [],
    textareaStyle: { width: 900 },
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <div>
        
        <div><strong style="margin-right:20px">1</strong>Think of a photo you like. Who took it? What 
        was happening? What were the people 
        wearing?</div>
        <div><b>When I was younger, I used to listen to music on an iPod.
        Now I use my smartphone or tablet.</b></div>
        <textarea id="0" rows="4"></textarea>
        <div><strong style="margin-right:20px">2</strong>What was your family doing when you arrived 
        home yesterday? What happened next?</div>
        <textarea id="1" rows="4"></textarea>
        <div><strong style="margin-right:20px">3</strong>What were you wearing the last time you went 
        out with friends? Where were you going? </div>
        <textarea id="2" rows="4"></textarea>

      </div>
        `,
        answer: [""],
      },
    ],
  },
};

export default json;
