import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  1: {
    unit: "Unit Starter",
    id: "FP8-WB-demo-2023-US-P5-E1",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "/img/FriendsPlus/Page5/Key/E1answerKey.png",
    component: T6,
    textAlign: "center",
    checkUppercase: true,
    maxLength: 8,
    inputSize: 95,
    stylesTextInput: {
      background: "none",
      // border: "none",
      borderBottom: "1px solid gray",
      fontSize: "20px",
      textAlign: "center",
      height: 25,
    },
    titleQuestion: [
      {
        num: "1",
        title: `
        Complete the table with the word.
				`,
        color: "rgb(29,193,215)",
        star: 1,
      },
    ],

    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
            <div style='margin-top: 20px; display: flex; gap: 30px;max-width:580px;'>
							<div style='flex: 1; border-radius: 20px; padding: 20px 30px; border: 3px solid rgb(169,225,236);'>
								<div><span style="margin-right:20px">aren't</span><span style="margin-right:20px"> do</span><span style="margin-right:20px"> does</span><span style="margin-right:20px"> doesn't</span><span style="margin-right:20px;text-decoration:line-through"> don't</span><span> has</span></div>
								<div><span style="margin-right:20px; margin-left:30px">have</span><span style="margin-right:20px"> having</span><span style="margin-right:20px"> is</span><span style="margin-right:20px"> isn't</span><span style="margin-right:20px"> 'm</span><span> 're</span></div>
							</div>
              
            </div>
            <div style='position: relative; font-size: 20px'>
							<div style='display:flex'>
									<img src="/img/FriendsPlus/Page5/E1/1.jpg" style='height:18cm; width: 17cm'  >
							</div>
              <div style=" position: absolute; top: 72px; left: 21px; ">I / You / We / You /<br> They</div>
              <div style=" position: absolute; top: 72px; left: 229px; ">have / <u style='color: gray'>&ensp;don’t&ensp;</u> have</div>
              <div style=" position: absolute; top: 72px; left: 442px; ">breakfast.</div>

              <div style=" position: absolute; top: 155px; left: 21px; "><sup>1</sup><input id='0'> you have breakfast? Yes, I do. / No, I don’t.</div>
          		
          		<div style=" position: absolute; top: 207px; left: 21px; ">He / She / It</div>
              <div style=" position: absolute; top: 186px; left: 229px; "><sup>2</sup><input id='1'> /<br> <sup>3</sup><input id='2'> have</div>
              <div style=" position: absolute; top: 207px; left: 444px; ">breakfast.</div>

              <div style=" position: absolute; top: 251px; left: 26px; "><sup>4</sup><input id='3'>  he have breakfast?<br> Yes, he does. / No, he doesn’t.</div>

              <div style=" position: absolute; top: 381px; left: 21px; ">We / You / They</div>
              <div style=" position: absolute; top: 381px; left: 202px; "><sup>5</sup><input id='4'> / ’m not<br> <sup>6</sup><input id='5'> / aren’t</div>
              <div style=" position: absolute; top: 381px; left: 406px; "><sup>7</sup><input id='6'></div>
              <div style=" position: absolute; top: 381px; left: 522px; ">breakfast.</div>

              <div style=" position: absolute; top: 480px; left: 21px; ">Are they having breakfast? Yes, they are. / No, they 
              <sup>8</sup><input id='7'> .</div>
          
              <div style=" position: absolute; top: 549px; left: 21px; ">He / She / It</div>
              <div style=" position: absolute; top: 549px; left: 209px; ">’s / <sup>9</sup><input id='8'></div>
              <div style=" position: absolute; top: 549px; left: 431px; ">having</div>
              <div style=" position: absolute; top: 549px; left: 522px; ">breakfast.</div>
              
              <div style=" position: absolute; top: 608px; left: 21px; "><sup>10</sup><input id='9'> she having breakfast? Yes, she is. / No, she isn’t.</div>
						</div>
        `,
        answer: [
          "Do",
          "has",
          "doesn't",
          "Does",
          "'m",
          "'re",
          "having",
          "aren't",
          "isn't",
          "Is",
        ],
      },
    ],
  },
  2: {
    unit: "Unit Starter",
    id: "FP8-WB-demo-2023-US-P5-E2",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "/img/FriendsPlus/Page5/Key/E2answerKey.jpg",
    component: Circle_Write,
    textAlign: "center",
    // maxLength: 1,
    inputSize: 80,
    titleQuestion: [
      {
        num: "2",
        title: `
        Choose the correct words.
				`,
        color: "rgb(29,193,215)",
        star: 2,
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,

    question: {
      Write: {
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          margin: "5 10",
          border: "none",
          borderColor: "transparent",
          borderRadius: "60%",
          padding: "5px",
        },
        selectWordStyle: {
          border: "solid 3px",
          borderColor: "#00a8ec",
          margin: "-2px",
        },
        limitSelect: 1,
        listWords: [
          "Does / Are / Is",
          " are_they_wanting / do_they_want / they_want ",
          " doesn’t_like / not_like / isn’t_liking",
          " do / enjoy / am",
          " Does_Anna_have / Has_Anna / Is_Anna_having ",
          " chats / ’s_chatting / does_chat",
          "does_the_bus_leave / the_bus_leaves / is_the_bus_leaving",
        ],
        answers: ["0-0", "1-6", "2-2", "3-10", "4-2", "5-6", "6-0"],
        initialValue: [],
      },
      Layout: /*html */ `
      <div style='line-height:2.5'>
       <div style="display: flex; column-gap: 20px;">
        <strong></strong>
        <div>
        I’m having problems sleeping <strong><span style=" border: 3px solid black;border-radius: 25px;">at the moment</span> / always / yesterday.</strong>
        </div>
       </div>
       <div style="display: flex; column-gap: 20px;">
        <strong>1</strong>
        <div>
          <strong><input id="0" type="Circle" /></strong> Aaron usually get up early?
        </div>
       </div>
       <div style="display: flex; column-gap: 20px">
        <strong>2</strong>
        <div>
        What time <strong><input id="1" type="Circle" /></strong> to leave?
        </div>
       </div>
       <div style="display: flex; column-gap: 20px">
        <strong>3</strong>
        <div>
        He <strong> <input id="2" type="Circle" /></strong> maths.

        </div>
       </div>
       <div style="display: flex; column-gap: 20px">
        <strong>4</strong>
        <div>
        ‘Are you enjoying the book?’ ‘Yes, I <strong><input id="3" type="Circle" /></strong>'.
        </div>
       </div>
       <div style="display: flex; column-gap: 20px">
        <strong>5</strong>
        <div>
          <strong><input id="4" type="Circle" /></strong> more than one mobile phone?
        </div>
       </div>
       <div style="display: flex; column-gap: 20px">
        <strong>6</strong>
        <div>
        Right now, she <strong><input id="5" type="Circle" /></strong> to her friends.
        </div>
       </div>
       <div style="display: flex; column-gap: 20px">
        <strong>7</strong>
        <div>
        ‘When <strong><input id="6" type="Circle" /></strong> ’ ‘At 7.45 every morning.’
        </div>
       </div>
      
      </div>
      `,
    },
  },
  // 3: {
  //   unit: "Unit Starter",
  //   id: "FP8-WB-demo-2023-US-P5-E3",
  //   audio: "",
  //   video: "",
  //   // recorder: true,
  //   exerciseKey: "/img/FriendsPlus/Page5/Key/E3answerKey.jpg",
  //   component: T6,
  //   textAlign: "center",
  //   // maxLength: 1,
  //   inputSize: 180,
  //   titleQuestion: [
  //     {
  //       num: "3",
  //       title: `
  //       Complete the postcard using the correct
  //       form of the verbs.
  // 			`,
  //       color: "rgb(3, 103, 181)",
  //       star: 2,
  //     },
  //   ],
  //   questionImage: [],
  //   // hideBtnFooter: true,
  //   isHiddenCheck: true,
  //   questions: [
  //     {
  //       title: `
  //       <div style='margin-top: 20px; display: flex; gap: 30px;margin-left:100px'>
  //       <div style='flex: 1; border-radius: 20px; padding: 20px 20px; border: 2px solid rgb(169,225,236);max-width:fix-content'>
  //         <div><span style="margin-right:35px">come</span><span style="margin-right:35px">not get up</span><span style="margin-right:35px"> go</span><span style="margin-right:35px"> go</span><span style="margin-right:35px"> have</span><span> help</span></div>
  //         <div><span style="margin-right:35px; margin-left:55px">stay</span><span style="margin-right:35px">wake up</span><span style="margin-right:35px;text-decoration:line-through">write</span><span style="margin-right:35px">you/do</span></div>
  //       </div>

  //     </div>
  //     <div style='position: relative;'>
  //       <div style='display:flex'>
  //           <img src="/img/FriendsPlus/Page5/E3/1.jpg" style='height:26cm;'  >
  //       </div>
  //       <div style=" position: absolute; top: 240px; left: 74px; "><input id='0' style="width:0px"></input></div>
  //       <div style=" position: absolute; top: 319px; left: 275px; "><input id='1' style="width:0px"></input></div>
  //       <div style=" position: absolute; top: 440px; left: 350px; "><input id='2' style="width:0px"></input></div>
  //       <div style=" position: absolute; top: 559px; left: 110px; "><input id='3' style="width:0px"></input></div>
  //       <div style=" position: absolute; top: 600px; left: 147px; "><input id='4' style="width:0px"></input></div>
  //       <div style=" position: absolute; top: 720px; left: 213px; "><input id='5' style="width:0px"></input></div>
  //       <div style=" position: absolute; top: 761px; left: 359px; "><input id='6' style="width:0px"></input></div>
  //       <div style=" position: absolute; top: 801px; left: 210px; "><input id='7' style="width:0px"></input></div>

  //     </div>
  //       `,
  //       answer: [
  //         "'m staying",
  //         "'re having",
  //         "wakes up",
  //         "don't get up",
  //         "go",
  //         "'m going",
  //         "'s helping",
  //         "are you doing",
  //       ],
  //     },
  //   ],
  // },
  3: {
    unit: "Unit Starter",
    id: "FP8-WB-demo-2023-US-P5-E3",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "/img/FriendsPlus/Page5/Key/E3answerKey.png",
    component: T6,
    textAlign: "center",
    // maxLength: 1,
    inputSize: 180,
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      fontSize: 22,
      height: 29,
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "3",
        title: `
        Complete the postcard using the correct 
        form of the verbs.
				`,
        color: "rgb(3, 103, 181)",
        star: 2,
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <div style='margin-top: 20px; display: flex; gap: 30px;margin-left:100px'>
        <div style='flex: 1; border-radius: 20px; padding: 20px 20px; border: 2px solid rgb(169,225,236);max-width:fix-content; text-align: center'>
          <div><span style="margin-right:35px">come</span><span style="margin-right:35px">not get up</span><span style="margin-right:35px"> go</span><span style="margin-right:35px"> go</span><span style="margin-right:35px"> have</span><span> help</span></div>
          <div><span style="margin-right:35px; margin-left:55px">stay</span><span style="margin-right:35px">wake up</span><span style="margin-right:35px;text-decoration:line-through">write</span><span style="margin-right:35px">you / do</span></div>
        </div>
        
      </div>
      <div style='position: relative;'>
        <div style='display:flex'>
            <img src="/img/FriendsPlus/Page5/E3/3.jpg" style='height:21cm; width: 21cm'  >
        </div>
        <div style=" position: absolute; top: 61px; left: 72px; width: 421px; font-size: 22px; line-height: 36px">
        
          Hi Cameron,<br>
          I <u style='color: gray'>&ensp;'m writing&ensp;</u> this postcard from Pisa!<br>
          I <sup>1</sup><input id='0'/> here for ten days 
          with my Italian exchange partner, 
          Liliana, and we <sup>2</sup><input id='1'/> a 
          lot of fun together. A typical day 
          here is different from at home. 
          Her brother Claudio <sup>3</sup><input id='2'/>
          really early every day to go to 
          college, but we’re on holiday, so 
          we <sup>4</sup><input id='3'/> before 9.30. We 
          often <sup>5</sup><input id='4'/> out to a local 
          café for breakfast – it’s really nice. 
          Then we go sightseeing. At the 
          moment, I <sup>6</sup><input id='5'/> shopping 
          for presents. Liliana <sup>7</sup><input id='6'/>
          me. What <sup>8</sup><input id='7'/> this week? 
          See you next Monday at school.<br>
          Georgia
        </div>

      </div>
        `,
        answer: [
          "'m staying",
          "'re having",
          "wakes up",
          "don't get up",
          "go",
          "'m going",
          "'s helping",
          "are you doing",
        ],
      },
    ],
  },

  4: {
    unit: "Unit Starter",
    id: "FP8-WB-demo-2023-US-P5-E4",
    audio: "",
    video: "",
    // recorder: true,
    // exerciseKey: "img/FriendsPlus/Page5/E4/Key/answerKey.png",
    component: T6,
    textareaStyle: { width: 740 },
    hideBtnFooter: true,
    questionImage: [],
    titleQuestion: [
      {
        num: "4",
        title: `
        Answer the questions. Write three or 
four complete sentences.
				`,
        color: "rgb(3, 103, 181)",
        star: 3,
      },
    ],
    questions: [
      {
        title: `
        <div>
          <div>How often do you cook? Who’s cooking dinner<br>
          tonight? What food do you like and dislike?</div>
          <div><strong>I don’t cook very often. My dad is cooking dinner tonight.<br>
          I like pasta and pizza, but I don’t like fish.</strong>
          </div>
          <div><strong style="margin-right:10px">1 </strong>Where do you usually go on holiday? Who do <br>
          you go with and what do you do there?</div>
          <textarea id="0" rows="3"></textarea>
          <div><strong style="margin-right:10px">2 </strong>What’s your best friend doing now? What do <br>
          you think your parents are doing now?</div>
          <textarea id="1" rows="3"></textarea>
        </div>
        
        `,
        answer: [""],
      },
    ],
  },
};

export default json;
