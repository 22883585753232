import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 3",
    id: "FP8-WB-demo-2023-U3-P63-E1",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page63/key/E1answerKey.png",
    component: T6v2,
    // maxLength: 6,
    inputSize: 250,
    stylesTextInput: {
      background: "none",
      //   border: 'none',
      fontSize: 22,
      textAlign: "center",
      borderBottom: "2px dotted",
    },
    isHiddenCheck: true,
    checkUppercase: true,
    titleQuestion: [
      {
        num: "1",

        title: `
        Complete the sentences using the present perfect simple or continuous form of the verbs in brackets.
	      `,
        color: "#4b3a97",
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
			<div style='line-height:50px; font-size: 22px'>
      I <b><u style='color: gray'>&ensp;'ve been reading&ensp;</u></b> (read) this book for ages, but I’m only on page 102.

      <div><b>1</b> <span style='margin-left:15px'> # you # (run)? You look hot and tired.</span></div>

      <div><b>2</b> <span style='margin-left:15px'> They # (work) all day on that project, but it isn’t finished.</span></div>

      <div><b>3</b> <span style='margin-left:15px'> We # (visit) Galicia many times. Our cousins live there.</span></div>

      <div><b>4</b> <span style='margin-left:15px'> I # (go) here before. I remember this place.</span></div>

      <div><b>5</b> <span style='margin-left:15px'> How long is this film? We # (watch) it for three hours!</span></div>

      <div><b>6</b> <span style='margin-left:15px'> He # (learn) Greek for a few weeks because he’s going there on holiday.</span></div>

      <div><b>7</b> <span style='margin-left:15px'> I # (buy) a new computer online. It’s arriving tomorrow.</span></div>

      </div>
      	`,
        answer: [
          `Have`,
          `been running`,
          `'ve been working`,
          `'ve visited`,
          `'ve been`,
          `'ve been watching`,
          `'s been learning`,
          `'ve bought`,
        ],
      },
    ],
  },
  2: {
    unit: "Unit 3",
    id: "FP8-WB-demo-2023-U3-P63-E2",
    audio: "",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page63/key/E2answerKey.png",
    component: T6v2,
    // maxLength: 6,
    inputSize: 450,
    stylesTextInput: {
      background: "none",
      // border: "none",
      fontSize: "22px",
      // fontWeight: "bold",
    },
    titleQuestion: [
      {
        num: "2",

        title: `
        Write sentences 1–5 with <i>just</i>. Then match them to situations a–f.
										`,
        color: "#4b3a97",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
      <div style='line-height: 45px; font-size: 22px; display: flex'>
        <div> 
          <div>
            he / eat / all of the cake <br>
            <u><b style='color: gray'>&ensp;He’s just eaten all of the cake!&ensp;</b></u> <br>
            <u><b style='color: gray'>&ensp;c&ensp;</b></u>
          </div>
        <div>
          <b>1</b>&ensp; we / go / on holiday <br>
          # &ensp; <input id='5' width='40px'>
          
        </div>

        <div>
          <b>2</b>&ensp; she / buy / a new bike / ? <br>
          # &ensp; <input id='5' width='40px'> 
         
        </div>

        <div>
          <b>3</b>&ensp; she / say / goodbye to her boyfriend<br>
          # &ensp; <input id='5' width='40px'> 
        
        </div>

        <div>
          <b>4</b>&ensp; someone / steal / his wallet <br>
          # &ensp; <input id='5' width='40px'> 
         
        </div>

        <div>
        <b>5</b>&ensp; they / pass / the test / ? <br>
        # &ensp; <input id='5' width='40px'> 
       
      </div>
      </div>

        <div style='margin-left:100px; margin-top:130px;line-height: 50px'>
          <div>
            <b>a</b>&ensp;Hannah looks sad.
          </div>
          <div>
            <b>b</b>&ensp;David and his friends are celebrating.
          </div>
          <div>
            <b>c</b>&ensp;Nathan feels sick.
          </div>
          <div>
            <b>d</b>&ensp;You all look very relaxed.
          </div>
          <div>
            <b>e</b>&ensp;Riley is really angry.
          </div>
          <div>
            <b>f</b>&ensp;Sandra hasn’t got much money.
          </div>
      </div>
          
      </div>
									`,
        answer: [
          `We've just been on holiday.`,
          `d`,
          `Has she just bought a new bike?`,
          `f`,
          `She's just said goodbye to her boyfriend.`,
          `a`,
          `Someone's just stolen his wallet.`,
          `e`,
          `Have they just passed the test?`,
          `b`,
        ],
      },
    ],
  },
  3: {
    unit: "Unit 3",
    id: "FP8-WB-demo-2023-U3-P63-E3",
    audio: "",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page63/key/E3answerKey.png",
    component: Circle_Write,

    // maxLength: 6,
    // inputSize: 900&
    stylesTextInput: {
      //   border: 'none',
      //   borderBottom: '1px solid black',
      //   textAlign: 'center',
      fontSize: "20px",
      fontWeight: "bold",
    },
    textareaStyle: { width: 500 },
    titleQuestion: [
      {
        num: "3",

        title: `
        Order the words to make sentences or questions. Then choose the correct meaning.
										`,
        color: "#4b3a97",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    question: {
      Write: {
        isHiddenCheck: true,
        // maxLength: 11,
        inputStyle: { width: 430, fontSize: 22, height: 30, marginBottom: 10 },
        answers: [
          `She's already bought a new phone.`,
          `We still haven't seen that film.`,
          `Has the party already finished?`,
          `Have you made dinner yet?`,
          `I still haven't talked to Andy.`,
        ],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: 5,
          borderRadius: "50%",
          fontWeight: "bold",
          borderColor: "transparent",
        },
        selectWordStyle: {
          padding: 5,
          border: "solid 2px",
          // borderColor: '#00aeef',
        },
        limitSelect: 1,
        listWords: [`a <br> b`, `a <br> b`, `a <br> b`, `a <br> b`, `a <br> b`],
        answers: ["0-0", "1-4", "2-0", "3-0", "4-4"],
        initialValue: [],
      },
      Layout: `

  <div style='display:flex; font-size: 22x'>
       
    <div>
        <div>
        <div style='font-size: 22x'>
        arrived / Mark / yet / hasn’t <br>
        <u><b style='color: gray' >&ensp;Mark hasn’t arrived yet.&ensp;</b></u>
      </div>
      <div>
        <b>a</b> He’s extremely late. <br>
        <span style='border:3px solid black;border-radius:50%'><b>b</b></span> <span> He’ll be here soon.
      </div>
   </div>

  <div style='margin-top:10px; font-size: 22px'>
    <b>1</b>&ensp; a / phone / bought / she’s / already / new <br>
    <input id='0' > </input> 
   <div style='display:flex ; margin-left:20px;margin-top:20px'>
   <div>
     <input id='0' type='Circle'/>&ensp;
   </div>
    <span style='margin-left:10px'>
     She did it more quickly than we expected. <br>
     She took longer than we expected.
    </span>
   </div>
 </div>

 <div style='margin-top:10px; font-size: 22px'>
    <b>2</b>&ensp; still / film / haven’t / we / seen / that <br>
    <input id='1' > </input>
   <div style='display:flex ; margin-left:20px;margin-top:20px'>
   <div>
     <input id='1' type='Circle'/>&ensp;
   </div>
    <span style='margin-left:10px'>
     I may go soon. <br>
     Everybody else has seen it.
    </span>
   </div>
 </div>
        </div>

    <div style='margin-left:80px; font-size: 22px'>
      <div style='margin-top:10px'>
      <b>3</b>&ensp; has / already / finished / party / the / ? <br>
      <input id='2' > </input>
     <div style='display:flex ; margin-left:20px;'>
     <div>
       <input id='2' type='Circle'/>&ensp;
     </div>
      <span style='margin-left:10px'>
       That’s earlier than we expected. <br>
       We are happy that it’s ended.
      </span>
     </div>
   </div>
 
   <div style='margin-top:10px; font-size: 22px'>
      <b>4</b>&ensp; dinner / you / yet / have / made / ? <br>
      <input id='3' > </input>
     <div style='display:flex ; margin-left:20px;'>
     <div>
       <input id='3' type='Circle'/>&ensp;
     </div>
      <span style='margin-left:10px'>
       I hope you’ll do it soon. <br>
       It’s earlier than usual.
      </span>
     </div>
   </div>
 
   <div style='margin-top:10px; font-size: 22px'>
      <b>5</b>&ensp; still / Andy / to / I / talked / haven’t <br>
      <input id='4' > </input>
     <div style='display:flex ; margin-left:20px;'>
     <div>
       <input id='4' type='Circle'/>&ensp;
     </div>
      <span style='margin-left:10px'>
       I’m not worried about when it happens. <br>
       I was going to do it earlier.
      </span>
     </div>
   </div>
      </div>
       
  </div>
    `,
    },
  },
  4: {
    unit: "Unit 3",
    id: "FP8-WB-demo-2023-U3-P63-E4",
    // audio: "Audios/2.16.mp3",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page63/key/E4answerKey.png",
    component: Circle_Write,
    // maxLength: 6,
    inputSize: 200,
    stylesTextInput: {
      //   background: 'transparent',
      //   border: 'none',
      //   borderBottom: '1px solid black',
      //   textAlign: 'center',
      fontSize: "20px",
      fontWeight: "bold",
    },
    titleQuestion: [
      {
        num: "4",

        title: `
        Choose the correct words.
										`,
        color: "#4b3a97",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    question: {
      Write: {
        isHiddenCheck: true,
        maxLength: 11,
        inputStyle: { width: 100, fontSize: 19, textAlign: "center" },
        answers: [""],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: 0,
          fontSize: 22,
          borderRadius: "50%",
          fontWeight: "bold",
          borderColor: "transparent",
        },
        selectWordStyle: {
          padding: 5,
          border: "solid 2px",
          // borderColor: '#00aeef',
        },
        limitSelect: 1,
        listWords: [
          `who / which / where`, //1
          `who / which / where`, //1
          `who / which / where`, //1
          `who / which / where`, //1
          `who / which / where`,
          `who / which / where`,
        ],
        answers: ["0-8", "1-4", "2-0", "3-0", "4-4", "5-8"],
        initialValue: [],
      },
      Layout: `
      
        
      
        <div style='line-height:50px; font-size: 22px'>
        Do you know anyone <b><span style='padding: 5px; border: 2px solid; border-radius: 50%'>who</span> / which / where</b> has climbed a mountain?
          <div><b>1.</b>&ensp; Tell me the place <input id='0' type='Circle'/>  you’d most like to travel.</div>

          <div><b>2.</b>&ensp; The watch <input id='1' type='Circle'/> I bought has already broken!</div>
          
          <div><b>3.</b>&ensp; I’d like to meet more people <input id='2' type='Circle'/> enjoy adventure sports.</div>
          
          <div><b>4.</b>&ensp; Is he the man <input id='3' type='Circle'/> broke the world record? </div>

          <div><b>5.</b>&ensp; I’ve got a new app on my phone <input id='4' type='Circle'/> shows me fun activities in my area. </div>

          <div><b>6.</b>&ensp; We’re going to a park <input id='5' type='Circle'/> you can do a lot of hiking.</div>
    
        </div>
      `,
    },
  },
  5: {
    unit: "Unit 3",
    id: "FP8-WB-demo-2023-U3-P63-E5",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "",
    component: T6v2,
    // maxLength: 1,
    inputSize: 500,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      fontSize: "24px",
    },
    titleQuestion: [
      {
        num: "5",
        title: `
        Work in pairs. Talk about the rules at your school. Which rules would you change? Would you add any? Decide which two rules are the most important.
        `,
        color: "#4b3a97",
        prefix: { icons: ["far fa-comment"], text: "USE IT!" },
      },
    ],
    questionImage: [],
    hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <div style="margin-left: 200px"><img src="img/FriendsPlus/Page63/13.jpg"/></div>
        `,
        answer: [],
      },
    ],
  },
};

export default json;
