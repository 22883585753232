import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  1: {
    unit: "Unit 2",
    id: "FP8-WB-demo-2023-U1-P19-E1",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "/img/FriendsPlus/Page19/Key/E1answerKey.jpg",
    component: T6,
    inputSize: 175,
    stylesTextInput: {
      background: "none",
      border: "none",
      // borderBottom: "1px solid gray",
      fontSize: "24px",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "1",
        title: `Complete the table with the adjectives 
        below.`,
        color: "rgb(1,172,118)",
        star: 1,
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
				<div style='margin-top: 20px; display: flex; gap: 30px;max-width:490px;margin-left:120px;margin-bottom:20px'>
				<div style='flex: 1; border-radius: 20px; padding: 20px 30px; border: 3px solid rgb(1,172,118)'>
				  <div><span style="margin-right:20px">amazing</span><span style="margin-right:20px;">loud</span><span style="margin-right:20px">sad</span><span style="margin-right:20px">terrible</span><span>wonderful</span></div>
				  
				  
				</div>
				
			  </div>
		
				<div style='position: relative;'>
				  <div><img src='img/FriendsPlus/Page19/E1/1.jpg' width='100%' /></div>
				  <div style='position: absolute; top: 148px; left: 25px;'>#</div>
				  <div style='position: absolute; top: 183px; left: 26px;'>#</div>
				  <div style='position: absolute; top: 365px; left: 26px;'>#</div>
				  <div style='position: absolute; top: 400px; left: 27px;'>#</div>
				  <div style='position: absolute; top: 435px; left: 25px;'>#</div>

		
				  
		
				  
				  
				</div>
			  </div>
        `,
        answer: ["loud", "sad", "amazing", "terrible", "wonderful"],
      },
    ],
  },
  2: {
    unit: "Unit 2",
    id: "FP8-WB-demo-2023-U1-P19-E2",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "/img/FriendsPlus/Page19/Key/E2answerKey.jpg",
    component: T6,
    inputSize: 175,
    isHiddenCheck: true,
    stylesTextInput: {
      background: "transparent",
      border: "none",
      // borderBottom: "1px solid gray",
      fontSize: "24px",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "2",
        title: ` Complete the rules with <i>extreme</i> or <i>normal</i>.`,
        color: "rgb(1,172,118)",
        star: 1,
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
				
		
				<div style='position: relative;'>
				  <div><img src='img/FriendsPlus/Page19/E2/1.jpg' width='100%' /></div>
				  <div style='position: absolute; top: 96px; left: 29px;'>#</div>
				  <div style='position: absolute; top: 134px; left: 459px;'>#</div>
				  

		
				  
		
				  
				  
				</div>
			  </div>
        `,
        answer: ["extreme", "normal"],
      },
    ],
  },
  // 3: {
  //   unit: "Unit 2",
  //   id: "FP8-WB-demo-2023-U1-P19-E3",
  //   audio: "",
  //   video: "",
  //   // recorder: true,
  //   exerciseKey: "",
  //   component: T6,
  //   inputSize: 200,
  //   hideBtnFooter: true,
  //   textAlign: "center",
  //   titleQuestion: [
  //     {
  //       num: "3",
  //       title: `Choose the correct words. There may be more than one answer.`,
  //       color: "rgb(1,172,118)",
  //       // prefix: { icons: ['far fa-comment'], text: '' },
  //       star: 2,
  //     },
  //   ],
  //   question: {
  //     Write: {
  //       inputStyle: {
  //         width: 120,
  //         textAlign: "center",
  //       },
  //       answers: [``],
  //       initialValue: [],
  //     },
  //     Circle: {
  //       initialWordStyle: {
  //         padding: "-40px",
  //         // fontWeight: 600,
  //         color: "",
  //         // fontSize: 27,
  //         borderRadius: "50%",
  //         // padding: "5px",
  //         // marginInline: '-1px',
  //       },
  //       selectWordStyle: { border: "3px solid rgb(0, 173, 238)" },
  //       limitSelect: 2,
  //       listWordsStyle: {},
  //       listWords: [
  //         "very / really",
  //         "very / absolutely",
  //         "miserable / sad ",
  //         "really / absolutely",
  //         "good / amazing",
  //         "really / absolutely",
  //         "very / amazing",
  //         "very / really",
  //       ],
  //       answers: [
  //         "0-0",
  //         "0-4",
  //         "1-4",
  //         "2-4",
  //         "3-0",
  //         "4-4",
  //         "5-0",
  //         "5-4",
  //         "6-0",
  //         "7-0",
  //         "7-4",
  //       ],
  //       initialValue: [],
  //     },
  //     Layout: `
  //     <div>The music at the festival is <b><span style="border:3px solid black;border-radius:50%;padding:5px">very </span> / absolutely</b>  loud.<div>
  // 		<div><strong style="margin-right:15px">1</strong>The beaches in this area are <strong><input id="0" type="Circle"></strong> pretty.</div>
  // 		<div><strong style="margin-right:15px">2</strong>The food is <strong><input id="1" type="Circle"></strong> disgusting</div>
  // 		<div><strong style="margin-right:15px">3</strong>I felt very <strong><input id="2" type="Circle"></strong> when we left.</div>
  // 		<div><strong style="margin-right:15px">4</strong>The tickets are <strong><input id="3" type="Circle"></strong> cheap.</div>
  // 		<div><strong style="margin-right:15px">5</strong>These sights are absolutely <strong><input id="4" type="Circle"></strong>.</div>

  // 		<div><strong style="margin-right:15px">6</strong>Our journey was <strong><input id="5" type="Circle"></strong> awful.</div>

  // 		<div><strong style="margin-right:15px">7</strong> Ireland is <strong><input id="6" type="Circle"></strong> beautiful.</div>

  // 		<div><strong style="margin-right:15px">8</strong>The water-slide was <strong><input id="7" type="Circle"></strong> terrifying. </div>

  //       `,
  //   },
  // },
  3: {
    unit: "Unit 2",
    id: "FP8-WB-demo-2023-U1-P19-E3",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "/img/FriendsPlus/Page19/Key/E3answerKey.png",
    component: T6,
    inputSize: 220,
    maxLength: 50,
    isHiddenCheck: true,
    stylesTextInput: {
      background: "none",
      // border: "none",
      // borderBottom: "1px solid gray",
      fontSize: "22px",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "3",
        title: `Choose the correct words. There may be more than one answer.`,
        color: "rgb(1,172,118)",
        // prefix: { icons: ['far fa-comment'], text: '' },
        star: 2,
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
				
		
				<div style='position: relative; font-size: 22px; line-height: 45px'>
				  <div>
          <div>The music at the festival is <b><span style='padding: 5px; border: 2px solid gray; border-radius: 50%'>very</span> / absolutely</b> loud.</div>
          <div><b>1&ensp;</b>The beaches in this area are <b>very / really</b> pretty.</div>
          <div><b>2&ensp;</b>The food is <b>very / absolutely</b> disgusting!</div>
          <div><b>3&ensp;</b>I felt very <b>miserable / sad</b> when we left.</div>
          <div><b>4&ensp;</b>The tickets are <b>really / absolutely</b> cheap.</div>
          <div><b>5&ensp;</b>These sights are absolutely <b>good / amazing</b>.</div>
          <div><b>6&ensp;</b>Our journey was <b>really / absolutely</b> awful.</div>
          <div><b>7&ensp;</b>Ireland is very <b>amazing / beautiful</b>.</div>
          <div><b>8&ensp;</b>The water-slide was <b>very / really</b> terrifying.</div>
          </div>
				  <b>Answer:</b><br>
          <div><i style='font-size:18px; color: rgb(0,103,180)'>*Write the selected words in the sorted order of the list above and between words seperated by commas (,).<br>Ex: very, absolutely</i></div>

          <div style='margin-left: 50px;'>
              <div style='display: flex'>
              &ensp;<b>1 - #&ensp;</b>
              &ensp;<b>2 - #&ensp;</b>
              &ensp;<b>3 - #&ensp;</b>
              &ensp;<b>4 - #&ensp;</b>
              </div>
              <div>
              &ensp;<b>5 - #&ensp;</b>
              &ensp;<b>6 - #&ensp;</b>
              &ensp;<b>7 - #&ensp;</b>
              &ensp;<b>8 - #&ensp;</b>
              </div>
          </div>
				  
				</div>
			  </div>
        `,
        answer: [
          "very, really",
          "absolutely",
          "sad",
          "really",
          "amazing",
          "really, absolutely",
          "beautiful",
          "really",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 2",
    id: "FP8-wB-demo-2023-U2-P19-E4",
    audio: "",
    video: "",
    exerciseKey: "/img/FriendsPlus/Page19/Key/E4answerKey.png",
    component: T6v2,
    inputSize: 170,
    maxLength: 25,
    stylesTextInput: {
      // borderBottom: "none",
      fontSize: "20px",
      textAlign: "center",
      background: "none",
    },
    titleQuestion: [
      {
        num: "4",
        title: `
        Read the advert and complete the 
        competition entry with the words.
				`,
        color: "rgb(1,172,118)",
        star: 2,
      },
    ],
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <div style="display:flex; font-size: 20px">
        <div style="margin-top:210px;margin-right:30px"><img src='img/FriendsPlus/Page19/E4/1.jpg' style='width: 13cm' /></div>
        <div>
        <div style='margin-top: 20px; display: flex; gap: 30px;max-width:490px;margin-left:50px;margin-bottom:20px'>
				<div style='flex: 1; border-radius: 20px; padding: 20px 30px; border: 3px solid rgb(1,172,118)'>
				  <div><span style="margin-right:20px;margin-left:70px">don't forget</span><span>is absolutely</span></div>
				  <div><span style="margin-right:20px;margin-left:40px">is quite a / an</span><span>looking forward to</span></div>
				  <div><span style="margin-right:20px;text-decoration:line-through;margin-left:60px">one of the most</span><span>surprisingly</span></div>
				  
				  
				</div>
				
			  </div>
		
				<div style='position: relative;'>
				  <div><img src='img/FriendsPlus/Page19/E4/2.jpg' style='width: 18cm; height: 16cm' /></div>
				  <div style='position: absolute; top: 82px; left: 40px; width: 600px; line-height: 37px'>
						The place I most want to go to is Rio, in Brazil, for the 
						Mardi Gras festival. It’s one of the most exciting 
						street carnivals in the world.<br>
						Mardi Gras <sup>1</sup># enormous, with five million 
						people.<br>
						The festival is nearly 300 years old, but <sup>2</sup># , 
						the famous samba dance has only been part of 
						the celebration for 100 years. The festival parade 
						<sup>3</sup># amazing sight.<br>
						On the day, <sup>4</sup># to take lots of water, as 
						February is the hottest time of year in Brazil. I’m going 
						next year, and I’m really <sup>5</sup># being part of 
						the world’s biggest party!
					</div>
	 
				</div>
			  </div>
        </div>
        </div>
				`,
        answer: [
          "is absolutely",
          "surprisingly",
          "is quite an",
          "don't forget",
          "looking forward to",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 2",
    id: "FP8-wB-demo-2023-U2-P19-E5",
    audio: "",
    video: "",
    component: T6v2,
    inputSize: 800,
    stylesTextInput: {
      fontSize: "24px",
    },
    textareaStyle: { width: "900px" },
    titleQuestion: [
      {
        num: "5",
        title: `
        Write your own competition entry. 
        Use the text in exercise 3 to help you. Include 
        intensifiers, adjectives and the phrases on this 
        page.
				`,
        color: "rgb(1,172,118)",
        star: 3,
      },
    ],
    questionImage: [],
    hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `<div style='width: 900px'>
					<textarea id="0" rows="7"></textarea>
          </div>
				`,
        answer: [],
      },
    ],
  },
};

export default json;
