import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";

const json = {
  1: {
    unit: "Unit 7",
    id: "FP8-WB-demo-2023-U7-P48-E1",
    audio: "Audios/1-18 Friends Plus 8_WB.mp3",
    video: "",
    component: Circle_Write,
    exerciseKey: "img/FriendsPlus/Page48/Key/E1answerKey.png",
    titleQuestion: [
      {
        num: "1",
        star: 1,
        title: `        <headphone name="18"></headphone >&ensp; Read and listen to Duncan’s article. Choose the correct answer. `,
        color: "#DA3541",
      },
    ],
    question: {
      Write: {
        isHiddenCheck: true,
        maxLength: 11,
        inputStyle: { width: 100, fontSize: 19, textAlign: "center" },
        answers: [""],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: 0,
          borderRadius: "50%",
          fontWeight: "bold",
          borderColor: "transparent",
        },
        selectWordStyle: {
          padding: 5,
          border: "solid 2px",
          // borderColor: '#00aeef',
        },
        limitSelect: 1,
        listWords: [
          `A <br> B <br> C`, //1
        ],
        answers: ["0-4"],
        initialValue: [],
      },
      Layout: `
			   
						   <div style='display:flex'>
						   <div style='flex:1'>
						   <img src='img/FriendsPlus/Page48/E1/1.jpg' style='max-width:100%'>
						   </div>
						   <div style ='flex:1;font-size:22px;line-height:2'>
						   The best heading for the article is … . <br>
						   <div style="display: flex;margin-left:40px">
			   
							 <div><input id='0' type='Circle'/></div>
							 <div style='margin-left:20px'>
							 kind things different people did in a year <br>
							someone who did kind things for a year <br>
							 how we can be kind to other people this year

						   </div>
						   
						   </div>
					   
		  </div>
	
	
	
		  `,
    },
  },
  2: {
    unit: "Unit 7",
    id: "FP8-WB-demo-2023-U7-P48-E2",
    audio: "",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page48/key/E2answerKey.png",
    component: T6v2,
    // maxLength: 6,
    inputSize: 750,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      textAlign: "left",
      fontSize: "22px",
      // fontWeight: "bold",
    },
    titleQuestion: [
      {
        num: "2",
        star: 2,

        title: `
			Scan the article quickly and find these dates and figures. What are they? `,
        color: "#DA3541",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
			 <br>
			 <div style='font-size: 22px; line-height: 50px'>
			 14<sup>th</sup> April <b style='border-bottom:1px solid black; color: gray'>&ensp;the day Duncan’s article went online &ensp; </b> 
			<div style='line-height:2.5'>
			<div style ='display:flex'>
			<b>1</b>
			<div style='margin-left:10px'>
			365<input/>
			</div>
			</div>
			<div style ='display:flex'>
			<b>2</b>
			<div style='margin-left:10px'>
			1<sup>st</sup> January<input/> 
			</div>
			</div>
			
		
			<div style ='display:flex;'>
			<b>3</b>
			<div style='margin-left:10px'>
			£365<input/>
			</div>
			</div>
	
			<div style ='display:flex'>
			<b>4</b>
			<div style='margin-left:10px'>
			£5<input/>
			</div>
			</div>
	
			<div style ='display:flex;'>
			<b>5</b>
			<div style='margin-left:10px'>
			£3,000 <input/>
			</div>
			</div>
	

			</div>
			</div>
      <img src='img/FriendsPlus/Page48/E1/1.jpg' style='width: 16cm'>
							`,
        answer: [
          `the number of good deeds Luke did in all`,
          `the day Luke started his project`,
          `the money Luke gave away on 31st December`,
          `the notes Luke gave away to people`,
          `the money Luke spent on other people during the year`,
        ],
      },
    ],
  },
  3: {
    unit: "Unit 7",
    id: "FP8-WB-demo-2023-U7-P48-E3",
    audio: "",
    video: "",
    component: Circle_Write,
    exerciseKey: "img/FriendsPlus/Page48/Key/E3answerKey.png",
    titleQuestion: [
      {
        num: "3",
        star: 2,
        title: `Read the article again and choose the correct answers.`,
        color: "#DA3541",
      },
    ],
    question: {
      Write: {
        isHiddenCheck: true,
        maxLength: 11,
        inputStyle: { width: 100, fontSize: 19, textAlign: "center" },
        answers: [""],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: 0,
          borderRadius: "50%",
          fontWeight: "bold",
          borderColor: "transparent",
        },
        selectWordStyle: {
          padding: 5,
          border: "solid 2px",
          // borderColor: '#00aeef',
        },
        limitSelect: 1,
        listWords: [
          `A <br> B <br> C`, //0
          `A <br> B <br> C`, //1
          `A <br> B <br> C`, //2
          `A <br> B <br> C`, //3
        ],
        answers: ["0-4", "1-0", "2-4", "3-8"],
        initialValue: [],
      },
      Layout: `
	 <div style='display:flex'>
	 <div style='flex:1'>
	 <img src='img/FriendsPlus/Page48/E1/1.jpg' style='max-width:80%'>

	 </div>
	 <div style='flex:1;margin-left:20px'>
	 <div style ='font-size:22px;line-height:1.5'>

	 What was Luke Cameron’s first good deed? <br>
	 <b>a</b>&ensp;to be generous and thoughtful <br>
	 <b>b</b>&ensp;to serve a shop assistant <br>
	 <b style='border:3px solid black;border-radius:50%'>c</b>&ensp;to wish a shop assistant ‘Happy New Year’ 

   <b>1</b>&ensp;Which deed does the article not mention? <br>
   <div style="display: flex;margin-left:40px">
		  
   <div><input id='0' type='Circle'/></div>
   <div style='margin-left:20px'>
		   helping old people <br>
		   buying food for friends and colleagues <br>
		   giving money to strangers

</div>
</div>
<b>2</b>&ensp;Why did Luke create a website? <br>
   <div style="display: flex;margin-left:40px">
		  
   <div><input id='1' type='Circle'/></div>
   <div style='margin-left:20px'>
   to record everything he did <br>
    to get a new job 
   <br> to become famous 

</div>
</div>
<b>3</b>&ensp;How did Luke help other people?  <br>
   <div style="display: flex;margin-left:40px">
		  
   <div><input id='2' type='Circle'/></div>
   <div style='margin-left:20px'>
   he only spent money on them <br>
   he spent money as well as time <br>
   he spent time, but not much money 

</div>
</div>
<b>4</b>&ensp;What’s Luke’s job now?  <br>
   <div style="display: flex;margin-left:40px">
		  
   <div><input id='3' type='Circle'/></div>
   <div style='margin-left:20px'>
   he creates websites <br>
   he writes about charities in The Independent <br>
   he suggests ways to raise money for charity 

</div>
</div>

				  
	 </div>
	 </div>
	 
	 </div>



	  `,
    },
  },
  4: {
    unit: "Unit 7",
    id: "FP8-WB-demo-2023-U7-P48-E4",
    audio: "",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page48/key/E4answerKey.png",
    component: T6v2,
    // maxLength: 6,
    inputSize: 1010,

    textareaStyle: {
      // background: 'none',
      width: 900,
      fontSize: 23,
      height: 90,
    },

    titleQuestion: [
      {
        num: "4",
        star: 3,

        title: `
        Answer the questions. Which sentence in the article tells you the answer?  `,
        color: "#DA3541",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
				<div style='font-size: 23px; line-heigth: 45px'></div>
        Do you think Duncan does many good deeds? <br>
         <b style='border-bottom:1px solid black; color: gray'>&ensp;No, he doesn’t. ‘I would honestly have to say “Nothing”’.&ensp; </b>  
        <div style='line-height:2'>
        <div style ='display:flex'>
        <b>1</b>
        <div style='margin-left:10px'>
		How often does Duncan think people do kind things for others? <br><textarea id='0' rows='3'>
        </div>
        </div>
        <div style ='display:flex'>
        <b>2</b>
        <div style='margin-left:10px'>
        Why does Duncan say Luke started small? <br><textarea id='1' rows='3'> 
        </div>
        </div>
        
    
        <div style ='display:flex;'>
        <b>3</b>
        <div style='margin-left:10px'>
        What did Luke do on 31st December?  <br><textarea id='2' rows='3'>
        </div>
        </div>

        <div style ='display:flex'>
        <b>4</b>
        <div style='margin-left:10px'>
        Does Duncan believe that spending money is the only way to be kind? <br><textarea id='3' rows='3'>
        </div>
        </div>

       

        
       
        </div>
			
						`,
        answer: [
          `Not very often. 'Perhaps, like most of us, you would answer the same.'`,
          `Because his first deed was small, 'he wished the shop assistant … a Happy New Year'`,
          `He gave money to people in the town centre. 'He gave away £365, in £5 notes, to strangers'.`,
          `No. 'He also gave up his time to help people and become their friend.'`,
        ],
      },
    ],
  },
  5: {
    unit: "Unit 7",
    id: "FP8-WB-demo-2023-U7-P48-E5",
    audio: "",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page48/key/E5answerKey.png",
    component: T6v2,
    maxLength: 15,
    inputSize: 130,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      textAlign: "center",
      fontSize: "23px",
      fontWeight: "bold",
    },
    titleQuestion: [
      {
        num: "5",
        star: 2,

        title: `
		<b>VOCABULARY PLUS</b> Complete the sentences using the correct form of <i>benefit, reward</i> and <i>award</i>. Write verb or noun.  `,
        color: "#DA3541",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
		 <br>
		 He won several <b style='border-bottom:1px solid gray; color: gray'>awards</b> for his acting. <b style='border-bottom:1px solid gray; color: gray'>noun</b>
		<div style='line-height: 50px; font-size: 23px'>
		<div style ='display:flex'>
		<b>1</b>
		<div style='margin-left:10px'>
		The money will <input/> charity. <input/>
		</div>
		</div>
		<div style ='display:flex'>
		<b>2</b>
		<div style='margin-left:10px'>
		There’s a £50 <input/> . <input/>
		</div>
		</div>
		
	
		<div style ='display:flex;'>
		<b>3</b>
		<div style='margin-left:10px'>
		We  <input/> her first prize. <input/>		</div>
		</div>

		<div style ='display:flex'>
		<b>4</b>
		<div style='margin-left:10px'>
		Exercise has health <input/>. <input/>
		</div>
		</div>

		<div style ='display:flex;'>
		<b>5</b>
		<div style='margin-left:10px'>
		They <input/> his hard work. <input/>
		</div>
		</div>

		
	   
		</div>
			
						`,
        answer: [
          `benefit`,
          `verb`,
          `reward`,
          `noun`,
          `awarded`,
          `verb`,
          `benefits`,
          `noun`,
          `rewarded`,
          `verb`,
        ],
      },
    ],
  },
};

export default json;
