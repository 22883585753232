import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  1: {
    unit: "Unit 8",
    id: "FP8-WB-demo-2023-U8-P68-E1",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page68/Key/E1answerKey.png",
    component: T6v2,
    textareaStyle: { width: "730px" },
    // maxLength: 6,
    inputSize: 90,

    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      //   borderBottom: '1px solid black',
      textAlign: "center",
      fontSize: "23px",
      fontWeight: "bold",
      borderBottom: "2px dotted",
      textTransform: "uppercase",
    },
    titleQuestion: [
      {
        num: "1",

        title: `
					
        Are the sentences active (A) or passive (P)? Write A or P.
											`,
        color: "#4d3c98",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
					
				<div style='line-height:50px; font-size: 23px'>
        My brother is loved by everyone. <u><b style='color: gray'>&ensp;P&ensp;</b></u> <br>
        Everyone loves my brother. <u><b style='color: gray'>&ensp;A&ensp;</b></u>

        <div><b>1.</b>&ensp;She was born on 1<sup>st</sup> June. # </div>

        <div><b>2.</b>&ensp; This music is playing very loudly. # </div>

        <div><b>3.</b>&ensp; The piano is played with both hands. # </div>

        <div><b>4.</b>&ensp; This room isn’t cleaned every week. # </div>

        <div><b>5.</b>&ensp; They were talking all evening. # </div>

        <div><b>6.</b>&ensp; They weren’t told what to do next. # </div>

        <div><b>7.</b>&ensp; We were given an hour to finish the exam. # </div>

        <div><b>8.</b>&ensp; My sister is really happy at university. # </div>
        
					
				</div>
						
											`,
        answer: [`P`, `A`, `P`, `P`, `A`, `P`, `P`, `A`],
      },
    ],
  },
  2: {
    unit: "Unit 8",
    id: "FP8-WB-demo-2023-U8-P68-E2",
    audio: "",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page68/Key/E2answerKey.png",
    component: T6v2,
    maxLength: 17,
    inputSize: 200,
    stylesTextInput: {
      //   background: 'transparent',
      //   border: 'none',
      //   borderBottom: '1px solid black',
      textAlign: "center",
      fontSize: "22px",
      // fontWeight: "bold",
      borderBottom: "2px dotted",
    },
    hintBox: [
      {
        src: [
          `cut`,
          `not`,
          `give`,
          `not`,
          `sell`,
          `serve`,
          `take`,
          `teach`,
          `write`,
        ],
        borderColor: "#4d3c98",
        width: 500,
      },
    ],
    titleQuestion: [
      {
        num: "2",

        title: `
					
        Complete the sentences using the correct present or past passive form of the verbs.

											`,
        color: "#4d3c98",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <hintBox id='0'></hintBox>
		<div style="line-height:50px; font-size: 22px">
        <div>
        The dog’s hair <u><b style='color: gray' >&ensp;is cut&ensp;</b></u> once every two months. 
        </div>
        <div><b>1.</b>&ensp; These photographs # on holiday.</div>

        <div><b>2.</b>&ensp; Milk # in this shop.</div>

        <div><b>3.</b>&ensp; This play # by Shakespeare in 1595.</div>

        <div><b>4.</b>&ensp; Italian, French and German # at that school now.</div>

        <div><b>5.</b>&ensp; Dinner # every night at 9.00 last week.</div>

        <div><b>6.</b>&ensp; Homework # on this course.</div>
    
    </div>				
											`,
        answer: [
          `were taken`,
          `isn't sold`,
          `was written`,
          `are taught`,
          `was served`,
          `isn't given`,
        ],
      },
    ],
  },
  3: {
    unit: "Unit 8",
    id: "FP8-WB-demo-2023-U8-P68-E3",
    audio: "",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page68/Key/E3answerKey.png",
    component: T6v2,
    maxLength: 50,
    inputSize: 500,
    stylesTextInput: {
      //   background: 'transparent',
      //   border: 'none',
      borderBottom: "2px dotted",
      // textAlign: 'center',
      fontSize: "22px",
      // fontWeight: "bold",
    },
    titleQuestion: [
      {
        num: "3",

        title: `
					
        Rewrite the sentences using the passive form. Add by where necessary.
											`,
        color: "#4d3c98",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
		<div style="line-height: 45px; font-size: 22px">
        <div>
        Mrs Hughes told the joke.<br>
        The joke <b><u style='color: gray'>was told by Mrs Hughes.</u></b> 
        </div>
        <div><b>1.</b>&ensp; I damaged my bike in the accident.<br>
        <span style='margin-left:35px'>My bike #.</span></div>

        <div><b>2.</b>&ensp; All the students use this library.<br>
        <span style='margin-left:35px'>This library #.</span></div>

        <div><b>3.</b>&ensp; People don’t use this computer every day.<br>
        <span style='margin-left:35px'>This computer #.</span></div>

        <div><b>4.</b>&ensp; Lucas gave those presents.<br>
        <span style='margin-left:35px'>Those presents #.</span></div>

        <div><b>5.</b>&ensp; They didn’t make these chairs very well.<br>
        <span style='margin-left:35px'>These chairs #.</span></div>

        <div><b>6.</b>&ensp; We play all our matches at the sports centre.<br>
        <span style='margin-left:35px'>All our matches #.</span></div>
    
    </div>				
											`,
        answer: [
          `was damaged in the accident`,
          `is used by all the students`,
          `isn't used every day`,
          `were given by Lucas`,
          `weren't made very well`,
          `are played at the sports centre`,
        ],
      },
    ],
  },
  4: {
    unit: "Unit 8",
    id: "FP8-WB-demo-2023-U8-P68-E4",
    audio: "",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page68/Key/E4answerKey.png",
    component: T6v2,
    maxLength: 70,
    inputSize: 800,
    stylesTextInput: {
      //   background: 'transparent',
      //   border: 'none',
      //   borderBottom: '1px solid black',
      // textAlign: 'center',
      fontSize: "22px",
      // fontWeight: "bold",
      borderBottom: "2px dotted",
    },
    titleQuestion: [
      {
        num: "4",

        title: `
					
        Order the words to make passive questions.

											`,
        color: "#4d3c98",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
        
		<div style="line-height: 45px; font-size: 22px">
        <div>
        this programme / was / prizes / any / awarded / ?<br>
        <b><u style='color: gray'>&ensp;Was this programme awarded any prizes?&ensp;&ensp;</u></b>   
        </div>
        <div><b>1.</b>&ensp; this / cooked / meat / was / when / ?
        #
        </div>

        <div><b>2.</b>&ensp; on Mondays / this / is / room / used / ?
        #
        </div>

        <div><b>3.</b>&ensp; much / actors / paid / how / are / ?
        #
        </div>

        <div><b>4.</b>&ensp; told / they / were / news / the / when / ?
        #
        </div>

        <div><b>5.</b>&ensp; from / film / adapted / was / the / a book / ?
        #
        </div>

        <div><b>6.</b>&ensp; novels / written / Cervantes / were / by / these / ?
        #
        </div>
    
    </div>				
											`,
        answer: [
          `When was this meat cooked?`,
          `Is this room used on Mondays?`,
          `How much are actors paid?`,
          `When were they told the news?`,
          `Was the film adapted from a book?`,
          `Were these novels written by Cervantes?`,
        ],
      },
    ],
  },
  5: {
    unit: "Unit 8",
    id: "FP8-WB-demo-2023-U8-P68-E5",
    audio: "",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page68/Key/E5answerKey.png",
    component: T6v2,
    maxLength: 20,
    inputSize: 250,
    checkUppercase: true,
    stylesTextInput: {
      //   background: 'transparent',
      //   border: 'none',
      //   borderBottom: '1px solid black',
      textAlign: "center",
      fontSize: "22px",
      // fontWeight: "bold",
    },
    titleQuestion: [
      {
        num: "5",

        title: `
					
        Complete the dialogue using the correct active or passive form of the verbs in brackets.
											`,
        color: "#4d3c98",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
    <div style='line-height: 45px; font-size: 22px'>
        <div style='display:flex'> <b>Eva</b> <span style='margin-left:20px'> Your room looks completely different from the last time I <b><u style='color: gray'>&ensp;visited&ensp;</u></b> (visit)!</span></div>

        <div style='display:flex'> <b>Lola</b> <span style='margin-left:20px'>  Yes, that’s because it <sup>1</sup> # (paint) last week. And we <sup>2</sup> # (get) new pictures for the walls, too.</span></div>

        <div style='display:flex'> <b>Eva</b> <span style='margin-left:20px'> Wow. <sup>3</sup> # (you / do) the painting? It <sup>4</sup> # (look) amazing!</span></div>

        <div style='display:flex'> <b>Lola</b> <span style='margin-left:20px'>No. It <sup>5</sup> # (do) by a friend of the family. He <sup>6</sup> # (do) a good job, didn’t he?</span></div>

        <div style='display:flex'> <b>Eva</b> <span style='margin-left:20px'>Yes. And the cushions match the colour of the walls, too!</span></div>

        <div style='display:flex'> <b>Lola</b> <span style='margin-left:20px'>Ah, they <sup>7</sup> # (not be) home-made. They <sup>8</sup> # (buy) for my mum last year, but she gave them to me.</span></div>

        <div style='display:flex'> <b>Eva</b> <span style='margin-left:20px'>Well, I hope you <sup>9</sup> # (please) with your room. I just love it!</span></div>

        <div style='display:flex'> <b>Lola</b> <span style='margin-left:20px'>Thanks – so do I. How about your room? 
        <sup>10</sup> # (it / decorate) often?</span></div>

        <div style='display:flex'> <b>Eva</b> <span style='margin-left:20px'>No, not enough! I’m going to ask my 
        parents about that!</span></div>


    </div>
		
	
			
								`,
        answer: [
          `was painted`,
          `got`,
          `Did you do`,
          `looks`,
          `was done`,
          `did`,
          `aren't`,
          `were bought`,
          `'re pleased`,
          `Is it decorated`,
        ],
      },
    ],
  },
};

export default json;
