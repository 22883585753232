import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 1",
    id: "FP8-WB-demo-2023-U1-P12-E1",
    audio: "/Audios/1-04 Friends Plus 8_WB.mp3",
    video: "",
    component: Circle_Write,
    exerciseKey: "/img/FriendsPlus/Page12/Key/E1answerKey.png",
    titleQuestion: [
      {
        num: "1",
        title: `<headphone src="/Audios/1-04 Friends Plus 8_WB.mp3" name="04"></headphone>&ensp; Read and listen to the timeline. 
        Choose the correct answer.`,
        color: "rgb(218,43,55)",
        star: 1,
      },
    ],
    question: {
      Write: {
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "5px",
          fontWeight: 600,
          color: "",
          // fontSize: 27,
          borderRadius: "50%",
          // marginInline: '-1px',
        },
        selectWordStyle: { border: "3px solid rgb(0, 173, 238)" },
        limitSelect: 1,
        listWordsStyle: {},
        listWords: [
          `
          a|&emsp;to&nbsp;tell&nbsp;us&nbsp;the&nbsp;best&nbsp;ways&nbsp;to&nbsp;communicate<br> 
          b|&emsp;to&nbsp;explain&nbsp;the&nbsp;history&nbsp;of&nbsp;communication<br> 
          c|&emsp;to&nbsp;give&nbsp;recent&nbsp;news&nbsp;about&nbsp;technology
          `,
        ],
        answers: ["0-48"],
        initialValue: [],
      },
      Layout: `
        <div style='margin-left: 74px; display: flex; flex-direction: column; gap: 10px;'>
        <div>The main purpose of the timeline is … .</div>
          <div><input id='0' type='Circle' /></div>
          <div><img src='img/FriendsPlus/Page12/E1/1.jpg' width='75%' /></div>
        </div>
        `,
    },
  },
  2: {
    unit: "Unit 1",
    id: "FP8-WB-demo-2023-U1-P12-E2",
    audio: "",
    video: "",
    component: Circle_Write,
    exerciseKey: "/img/FriendsPlus/Page12/Key/E2answerKey.png",
    titleQuestion: [
      {
        num: "2",
        title: `Read the timeline again. Choose the 
        correct words to describe each paragraph.`,
        color: "rgb(218,43,55)",
        star: 2,
      },
    ],
    question: {
      Write: {
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "5px",
          // fontWeight: 600,
          color: "",
          // fontSize: 27,
          borderRadius: "50%",
          // marginInline: '-1px',
        },
        selectWordStyle: { border: "3px solid rgb(0, 173, 238)" },
        limitSelect: 1,
        listWordsStyle: {},
        listWords: [
          "slowest / earliest / best",
          " beginning / end / cost",
          "ten / 100 / 200",
          "electronics / life / communication",
        ],
        answers: ["0-4", "1-2", "2-8", "3-8"],
        initialValue: [],
      },
      Layout: `
        <div style='line-height: 50px'>

        <div><b>A</b> why human communication is <strong><span style=" border: 3px solid black; border-radius: 50%;padding:5px">special</span> / 
        difficult / scientific<strong></div>
        <div><b>B</b> the<strong><input id="0" type="Circle"/></strong> forms of communication</div>  
        <div><b>C</b> the<strong><input id="1" type="Circle"/></strong> of printing</div>  
        <div><b>D</b> inventions over the last<strong><input id="2" type="Circle"/></strong> years</div>  
        <div><b>E</b> <strong><input id="3" type="Circle"/></strong> today</div>  
        </div>
        `,
    },
  },
  3: {
    unit: "Unit 1",
    id: "FP8-WB-demo-2023-U1-P12-E3",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "/img/FriendsPlus/Page12/Key/E3answerKey.jpg",
    component: T6,
    maxLength: 1,
    inputSize: 50,
    stylesTextInput: {
      fontSize: 23,
      height: 27,
      textTransform: "uppercase",
      // borderStyle: "dotted",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "3",
        title: `
        Match sentences 1–5 with photos A–E.`,
        color: "rgb(219, 46, 57)",
        star: 2,
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
          <div style='line-height: 50px; font-size: 23px'>
            <div><strong style="margin-right:15px">1</strong>It was very different from modern writing. <strong style="margin-left:15px">D</strong></div>
            <div><strong style="margin-right:15px">2</strong>It made it quicker and easier to copy texts. #</div>
            <div><strong style="margin-right:15px">3</strong>It was the first technology for instant messaging.  #</div>
            <div><strong style="margin-right:15px">4</strong>It became common with the internet. #</div>
            <div><strong style="margin-right:15px">5</strong>It lets you speak to people from a distance. #</div>
          </div>
          <div>
            <img src="/img/FriendsPlus/Page12/E3/1.jpg" style='width: 16cm'>
          </div>
        `,
        answer: ["E", "C", "A", "B"],
      },
    ],
  },
  4: {
    unit: "Unit 1",
    id: "FP8-WB-demo-2023-U1-P12-E4",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "/img/FriendsPlus/Page12/Key/E4answerKey.jpg",
    component: T6v2,
    // maxLength: 1,
    inputSize: 320,
    textareaStyle: {
      width: "800px",
      fontSize: 22,
      height: 80,
    },
    titleQuestion: [
      {
        num: "4",
        title: `
        Answer the questions. Write complete 
        sentences.`,
        color: "rgb(219, 46, 57)",
        star: 3,
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
          <div style='font-size: 22px; line-height: 30px'>
            <div>Why does the writer say language is important?</div>
            <u style='color: gray'><b>&ensp;Because only humans can share thoughts and ideas
            through language.&ensp;</b></u>
            <div><strong style="margin-right:15px">1</strong>How were early writing systems different?</div>
            <div><textarea id="0" rows="2"></textarea></div>
            
            <div><strong style="margin-right:15px">2</strong>Why didn’t there use to be many books?</div>
            
            <div><textarea id="1" rows="2"></textarea></div>
            <div><strong style="margin-right:15px">3</strong>What effect did the printing press have on people?</div>
            <div><textarea id="2" rows="2"></textarea></div>
            
            <div><strong style="margin-right:15px">4</strong>How did the telephone change communication?</div>
            <div><textarea id="3" rows="2"></textarea></div>
            
            <div><strong style="margin-right:15px">5</strong>Why do people today find it difficult to imagine 
            communication in the past?</div>
            <div><textarea id="4" rows="2"></textarea></div>
            
          </div>
        `,
        answer: [
          "They had pictures in them.",
          "Because it took a long time to copy books by hand.",
          "Many more people learned to read.",
          "People could talk to other people over long distances for the first time.",
          "Because communication is so easy today.",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 1",
    id: "FP8-WB-demo-2023-U1-P12-E5",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "/img/FriendsPlus/Page12/Key/E5answerKey.jpg",
    component: T6v2,
    maxLength: 15,
    inputSize: 200,
    stylesTextInput: {
      fontSize: 23,
      height: 29,
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "5",
        title: `
        VOCABULARY PLUS Complete the 
sentences with the words.`,
        color: "rgb(219, 46, 57)",
        star: 2,
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
          <div style='line-height: 50px; font-size: 23px'>
          <div style='margin-top: 20px; display: flex; gap: 30px;max-width:510px;margin-left:120px;margin-bottom:20px'>
          <div style='flex: 1; border-radius: 20px; padding: 20px 30px; border: 2px solid rgb(218,43,55);'>
            <div>
            <span style="margin-right:20px">amazingly</span><span style="margin-right:20px">apparently</span><span style="margin-right:20px">generally</span><span>obviously</span></div>
           
            
          </div>
          </div>
          <div style='line-height: 50px; font-size: 23px'>
            <div><strong style="margin-right:15px">1</strong>It isn’t true for everyone, but # teenagers use technology a lot.</div>
      
            <div><strong style="margin-right:15px">2</strong>This new computer is very fast and it looks 
            great. #, it only costs £179!</div>
      
            <div><strong style="margin-right:15px">3</strong>In the past, we didn’t have telephones, so 
            #, communication was slower.</div>
      
            <div><strong style="margin-right:15px">4</strong> I don’t know what computers used to look like, 
            but # they were big and heavy.</div>
      
           
      
          </div>
        `,
        answer: ["generally", "Amazingly", "obviously", "apparently"],
      },
    ],
  },
};

export default json;
