import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";

const json = {
  1: {
    unit: "Unit 6",
    id: "FP8-WB-demo-2023-U6-P40-E1",
    audio: "",
    video: "",
    component: Circle_Write,
    exerciseKey: "img/FriendsPlus/Page40/Key/E1answerKey.png",
    titleQuestion: [
      {
        num: "1",
        title: " Choose the correct words.",
        color: "#F88125",
        star: 1,
      },
    ],
    question: {
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: 5,
          borderRadius: "50%",
          border: "2px solid black", // Sửa giá trị border thành "1px solid #000000"
          borderColor: "transparent",
          // color: "#000000",
        },
        selectWordStyle: {
          padding: 5,
          borderColor: "#0067B4",
        },

        limitSelect: 1,
        listWords: [
          "attending / training", //0
          "law / management", //1
          "position / course", //2
          "qualifications / notes", //3
          "go_to / assist", //4
          "nursing / job", //5
        ],
        answers: ["0-4", "1-4", "2-4", "3-0", "4-0", "5-4"],
        initialValue: [],
      },
      Layout: `
      <div style='line-height:2'>
      You need to do a <b><span style="display: inline-block; border: 2px solid gray; border-radius: 50%; padding: 4px;">degree</span> / project</b> in science to become a physicist. 
        <div><b>1</b> They’re <b><input id='0' type='Circle' /> </b> to be engineers. </div>
        <div><b>2</b> Amy wants to study <b><input id='1' type='Circle' /> </b>, so she can start her own restaurant business one day. </div>
        <div><b>3</b>  Jim is doing a   <b><input id='2' type='Circle' /> </b> in construction, because he wants to be a builder. </div>
        <div><b>4</b>  My sister didn’t get the right  <b><input id='3' type='Circle' /></b>  for the teaching course. </div>
        <div><b>5</b>  Aidan didn’t <b><input id='4' type='Circle' /></b>  school today. </div>
        <div><b>6</b>  A car drove into our wall, but it didn’t  <b><input id='5' type='Circle' />  </b> much damage.</div>
      </div>


      `,
    },
  },
  2: {
    unit: "Unit 6",
    id: "FP8-WB-demo-2023-U6-P40-E2",
    audio: "",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page40/key/E2answerKey.png",
    component: T6v2,
    maxLength: 20,
    inputSize: 250,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      fontSize: "23px",
    },
    titleQuestion: [
      {
        num: "2",

        title: `
        Complete Jordan’s blog with the words.

			`,
        color: "#F88125",
        star: 2,
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
		
		<div class="container">
			<div style=" width:1000px;border: 1px solid red; display: flex; justify-content:center;padding: 10px 20px; border-radius: 40px">
			<div style="margin-right:20px"><span>an apprenticeship</span></div>
			<div style="margin-right:20px"><span>a course</span> </div>
			<div style="margin-right:20px"><span>a position</span></div>
      <div style="margin-right:20px"><span>entertainment</span></div>
			<div style="margin-right:20px"><span>job</span></div>
			<div style="margin-right:20px"><span>j̶̶o̶̶u̶̶r̶̶n̶̶a̶̶l̶̶i̶̶s̶̶m̶</span></div>
			<div style="margin-right:20px"><span>marks
      </span></div>
      </div>

			<div style='display:flex'>
      <div style='flex:1'>
      <div style = "margin-top:20px; margin-right: 20px">
      <img src ="img/FriendsPlus/Page40/6.jpg" style="width: 14cm; height: 14cm"/>
      </div>        
      </div>

      <div style='flex:1;line-height:2.5;margin-top:20px'>
      <b>1</b>&ensp; <input/> <br>
      <b>2</b>&ensp; <input/> <br>
      <b>3</b>&ensp; <input/> <br>
      <b>4</b>&ensp; <input/> <br>
      <b>5</b>&ensp; <input/> <br>
      <b>6</b>&ensp; <input/> <br>

      </div>
      </div>    
   
			`,
        answer: [
          "an apprenticeship",
          "a course",
          "entertainment",
          "marks",
          "job",
          "a position",
        ],
      },
    ],
  },
  3: {
    // Exercise num
    unit: "Unit 6",
    id: "FP8-WB-demo-2023-U6-P40-E3",
    audio: "Audios/1-14 Friends Plus 8_WB.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page40/Key/E3answerKey.png",

    component: DesignUnderLine,
    totalInput: 4,
    titleQuestion: [
      {
        num: "3",
        title:
          "&ensp;<headphone name='14'></headphone>&ensp;Listen to Nora giving a talk about how she found her ideal job. Tick (✔) four more things that she says she did. ",
        color: "#F88125",
        star: 2,
      },
    ],
    isHiddenCheck: true,
    // isAllowSubmit: false,
    questionImage: [
      [{ url: "img/FriendsPlus/Page40/E3/1.jpg" }],
      // Row 1
      [
        { url: "img/FriendsPlus/Page40/E3/2.jpg" },
        { url: "img/FriendsPlus/Page40/E3/3.jpg", input: 1 },
      ],
      [
        { url: "img/FriendsPlus/Page40/E3/4.jpg" },
        { url: "img/FriendsPlus/Page40/E3/5.jpg", input: 2, isCorrect: true },
      ],
      [
        { url: "img/FriendsPlus/Page40/E3/6.jpg" },
        { url: "img/FriendsPlus/Page40/E3/7.jpg", input: 3 },
      ],
      [
        { url: "img/FriendsPlus/Page40/E3/8.jpg" },
        { url: "img/FriendsPlus/Page40/E3/9.jpg", input: 4, isCorrect: true },
      ],
      [
        { url: "img/FriendsPlus/Page40/E3/10.jpg" },
        { url: "img/FriendsPlus/Page40/E3/11.jpg", input: 5, isCorrect: true },
      ],
      [
        { url: "img/FriendsPlus/Page40/E3/12.jpg" },
        { url: "img/FriendsPlus/Page40/E3/13.jpg", input: 6 },
      ],
      [
        { url: "img/FriendsPlus/Page40/E3/14.jpg" },
        { url: "img/FriendsPlus/Page40/E3/15.jpg", input: 7, isCorrect: true },
      ],
    ],
  },
  4: {
    unit: "Unit 6",
    id: "FP8-WB-demo-2023-U6-P40-E4",
    audio: "Audios/1-14 Friends Plus 8_WB.mp3",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page40/Key/E4answerKey.png",
    component: T6v2,
    inputSize: 150,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      fontSize: "24px",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "4",
        title: `
        &ensp; <headphone name='14'></headphone>&ensp; Listen again. Complete the sentences with the words.
        
		`,
        color: "#F88125",
        star: 2,
      },
    ],
    maxLength: 13,
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <div class="container">
			<div style=" width:1000px;border: 1px solid red; display: flex; justify-content:center;padding: 10px 20px; border-radius: 40px">
			<div style="margin-right:20px"><span>difficult</span></div>
			<div style="margin-right:20px"><span>i̶m̶m̶e̶d̶i̶a̶t̶e̶l̶y̶</span> </div>
			<div style="margin-right:20px"><span>interviews</span></div>
      <div style="margin-right:20px"><span>media</span></div>
			<div style="margin-right:20px"><span>money</span></div>
			<div style="margin-right:20px"><span>useful</span></div>
			<div style="margin-right:20px"><span>website 
      </span></div>
       
      </div>
<div style='line-height:2.5;margin-top:10px'>
Nora says she <i><b style='border-bottom:1px solid black; color: gray'>immediately</b></i> knew she wanted to work in social media. <br>
<b>1</b>&ensp;Nora did <input/> projects at university.  <br>
<b>2</b>&ensp;She worked for a <input/>.   <br>
<b>3</b>&ensp;She says that you often get no  <input/>  for work experience.   <br>
<b>4</b>&ensp;She enjoyed doing  <input/> with people.   <br>
<b>5</b>&ensp; She says it has been <input/>  to meet people through work.   <br>
<b>6</b>&ensp;She says it’s  <input/>  to find the right job.   <br>
</div>

        `,
        answer: [
          "media",
          "website",
          "money",
          "interviews",
          "useful",
          "difficult",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 6",
    id: "FP8-WB-demo-2023-U6-P40-E5",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "",
    component: T6v2,
    // inputSize: 1000,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      fontSize: "24px",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "5",
        title: `
        Choose two of the careers. Write three or four sentences for each career, giving advice about the things you can do to get this type of job.
        
		`,
        color: "#F88125",
        star: 3,
      },
    ],
    textareaStyle: { width: 1200 },
    // maxLength:10,
    questionImage: [],
    hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <div class="container">
			<div style=" width:max-content;border: 1px solid rgb(251,99,64); display: flex; justify-content:center;padding: 10px 20px; border-radius: 40px">
			<div style="margin-right:20px"><span>construction</span></div>
			<div style="margin-right:20px"><span>engineering</span> </div>
			<div style="margin-right:20px"><span>entertainment</span></div>
      <div style="margin-right:20px"><span>IT journalis</span></div>
			<div style="margin-right:20px"><span>l̶a̶w̶</span></div>
			<div style="margin-right:20px"><span>management</span></div>
			<div style="margin-right:20px"><span>nursing  
      </span></div>
      <div style="margin-right:20px"><span>science  
      </span></div>
      <div style="margin-right:20px"><span>teaching   
      </span></div>
      </div>
<div style='margin-top:10px;width:1200px;'>
 <b style='color: gray'>To become a lawyer, you have to get good marks at school and then attend university. You then need to do a course at law college so you can get the right qualifications to start training at a law firm.
 </b>
 <textarea id='0' rows='8'></textarea>

</div>

        `,
        answer: [""],
      },
    ],
  },
};

export default json;
