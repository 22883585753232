import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";

const json = {
  1: {
    unit: "Unit 8",
    id: "FP8-WB-demo-2023-U8-P54-E1",
    audio: "Audios/1-20 Friends Plus 8_WB.mp3",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page54/key/E1answerKey.png",
    component: Circle_Write,
    // maxLength: 1,
    inputSize: 210,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      textAlign: "center",
      fontSize: "20px",
      fontWeight: "bold",
    },
    titleQuestion: [
      {
        num: "1",
        star: "1",
        title: `
        <headphone margin-left='10px' name="20"></headphone >&ensp; Read and listen to the blog post. What is the writer’s main argument? Choose the correct answer.
										`,
        color: "#D82E37",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,

    question: {
      Write: {
        isHiddenCheck: true,
        maxLength: 11,
        inputStyle: { width: 100, fontSize: 19, textAlign: "center" },
        answers: [""],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: 0,
          borderRadius: "50%",
          fontWeight: "bold",
          borderColor: "transparent",
        },
        selectWordStyle: {
          padding: 5,
          border: "solid 2px",
          // borderColor: '#00aeef',
        },
        limitSelect: 1,
        listWords: [
          `a <br> b <br> c`, //1
        ],
        answers: ["0-8"],
        initialValue: [],
      },
      Layout: `
      <div style='display:flex'> 
        <img src="img/FriendsPlus/Page54/E1/1.jpg" width="" /> 
      
        <div>

          <div style='display:flex ; margin-left:20px;margin-top:20px'>
          <div>
          <input id='0' type='Circle'/>&ensp;
          </div>
          <span style='margin-left:10px'>
          Making books is cheaper than making films. <br>
          People who don’t read books are lazy. <br>
          You use your imagination more when you read.
          </span>
          </div>
          

        </div>
      
      
      </div>
     
      

              


        
      `,
    },
  },
  2: {
    unit: "Unit 8",
    id: "FP8-WB-demo-2023-U8-P54-E2",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page54/key/E2answerKey.png",
    component: DesignUnderLine,
    // maxLength: 1,
    inputSize: 210,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      textAlign: "center",
      fontSize: "20px",
      fontWeight: "bold",
    },
    totalInput: 3,
    titleQuestion: [
      {
        num: "2",
        star: 2,
        title: `
        Read the blog post again. Tick (✔) three 
        more sentences which are true about the tone 
        and intention of the text.
										`,
        color: "#D82E37",
      },
    ],

    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `

										`,
        answer: [],
      },
    ],
    questionImage: [
      // Row 1
      [
        { url: "img/FriendsPlus/Page54/E2/1.jpg" },
        { url: "img/FriendsPlus/Page54/E2/2.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page54/E2/3.jpg" },
        { url: "img/FriendsPlus/Page54/E2/4.jpg", input: 1, isCorrect: true },
      ],
      [
        { url: "img/FriendsPlus/Page54/E2/5.jpg" },
        { url: "img/FriendsPlus/Page54/E2/6.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page54/E2/7.jpg" },
        { url: "img/FriendsPlus/Page54/E2/8.jpg", input: 2 },
      ],
      [
        { url: "img/FriendsPlus/Page54/E2/9.jpg" },
        { url: "img/FriendsPlus/Page54/E2/10.jpg", input: 3, isCorrect: true },
      ],
      [
        { url: "img/FriendsPlus/Page54/E2/11.jpg" },
        { url: "img/FriendsPlus/Page54/E2/12.jpg", input: 4 },
      ],
      [
        { url: "img/FriendsPlus/Page54/E2/13.jpg" },
        { url: "img/FriendsPlus/Page54/E2/14.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page54/E2/15.jpg" },
        { url: "img/FriendsPlus/Page54/E2/16.jpg", input: 5 },
      ],
      [
        { url: "img/FriendsPlus/Page54/E2/17.jpg" },
        { url: "img/FriendsPlus/Page54/E2/18.jpg", input: 6, isCorrect: true },
      ],
    ],
  },
  3: {
    unit: "Unit 8",
    id: "FP8-WB-demo-2023-U8-P54-E3",
    // audio: "Audios/2.07.mp3",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page54/key/E3answerKey.png",
    component: T6v2,
    // maxLength: 1,
    inputSize: 150,
    textareaStyle: { width: "800px" },
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      textAlign: "center",
      fontSize: "23px",
      // fontWeight: "bold",
    },
    titleQuestion: [
      {
        num: "3",
        star: 2,
        title: `
        Read the blog post again. Write <i>true, false</i> or <i>don't know</i> if the blog post doesn’t mention it. Correct the false sentences.
										`,
        color: "#D82E37",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <div style="line-height:2">
        <div><i style='font-size:20px; color: rgb(0,103,180)'>*If the sentence is true or don't know, rewrite the full sentence.</i></div>
          <div><b>1.</b>&ensp;  The writer often prefers the book.#</div>
          <textarea id="4" rows="1"></textarea>
          <div><b>2.</b>&ensp;  The writer thinks the Twilight and Harry Potter films are fantastic.#</div>
          <textarea id="5" rows="1"></textarea>
          <div><b>3.</b>&ensp;  The writer thinks some film adaptations were created too cheaply.#</div>
          <textarea id="6" rows="1"></textarea>
          <div><b>4.</b>&ensp;  The writer often watches the films with friends before reading the novels.#</div>
          <textarea id="7" rows="1"></textarea>

          
        </div>
										`,
        answer: [
          `true`,

          `don't know`,

          `false`,

          `false`,
          "The writer often prefers the book.",
          "The writer thinks the Twilight and Harry Potter films are fantastic.",
          `The writer knows a lot of money was spent.`,
          `The writer always downloads the book first.`,
        ],
      },
    ],
  },
  4: {
    unit: "Unit 8",
    id: "FP8-WB-demo-2023-U8-P54-E4",
    // audio: "Audios/2.07.mp3",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page54/key/E4answerKey.png",
    component: T6v2,
    // maxLength: 5,
    inputSize: 850,
    textareaStyle: { width: "600px", fontSize: "21px" },
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      //   textAlign: 'center',
      fontSize: "21px",
      fontWeight: "bold",
    },
    titleQuestion: [
      {
        num: "4",
        star: 3,
        title: `
        Correct the main ideas for paragraphs A–E using your own words.

										`,
        color: "#D82E37",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `  
        <div style='display:flex'>
          <img src="img/FriendsPlus/Page54/E1/1.jpg" style='width: 13cm' />
          
            <div style='line-height:40px; font-size: 21px'>
              <div style='display:flex' ><b>A</b>&ensp; <span>There have been one or two film versions of popular books, and they were exciting.</span></div>
              <div style='margin-left:20px' > <b><u style='color: gray'>&ensp;There have been lots of film versions of popular books, but I didn’t get excited about them.&ensp;</u></b> </div>
              <div style='display:flex' ><b>B</b>&ensp; <span>The films are much better than the way I imagined the stories in my head.</span></div>
              <textarea id="0" rows="2"></textarea>
              <div style='display:flex' ><b>C</b>&ensp; <span>I use my imagination a lot when I watch a film.</span></div>
              <textarea id="1" rows="2"></textarea>
              <div style='display:flex' ><b>D</b>&ensp; <span>Watching a film before reading a book doesn’t make me enjoy the book less.</span></div>
              <textarea id="2" rows="2"></textarea>
              <div style='display:flex' ><b>E</b>&ensp; <span>It’s boring to wait to read a story before seeing it on screen.</span></div>
              <textarea id="3" rows="3"></textarea>
            </div>
          

        </div>

										`,
        answer: [
          `The films are not as good as how I imagined the stories in my head.`,
          `I can't use my imagination when I watch a film.`,
          `Watching a film first can make it difficult for me to enjoy the book.`,
          `I prefer to read the story before seeing it on screen.`,
        ],
      },
    ],
  },
  5: {
    unit: "Unit 8",
    id: "FP8-WB-demo-2023-U8-P54-E5",
    audio: "",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page54/key/E5answerKey.png",
    component: T6v2,
    maxLength: 13,
    inputSize: 250,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      textAlign: "center",
      fontSize: "24px",
      borderStyle: "dotted",
      // fontWeight: "bold",
    },
    hintBox: [
      {
        src: [`classic`, "extraordinary", "terrible", "unbelievable"],
        borderColor: "rgb(248,129,37)",
        width: 600,
        fontColr: "red",
      },
    ],
    titleQuestion: [
      {
        num: "5",
        star: 2,
        title: `
        <span>
        VOCABULARY PLUS Replace the <b>bold</b> phrases with the words.
        </span>
										`,
        color: "#D82E37",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <hintBox id='0'></hintBox>
        <div>
          <div><b>1.</b> This book has an <b>extremely unusual</b> plot.</div>
          #
          <div><b>2.</b> The special effects were <b>not at all like real life</b>.</div>
          #
          <div><b>3.</b> These <b>brilliant, old</b> films have many fans.</div>
          #
          <div><b>4.</b> These old horror films have some <b>very bad</b> acting in them.</div>
          #
        </div>


										`,
        answer: ["extraordinary", "unbelievable", "classic", `terrible`],
      },
    ],
  },
};

export default json;
