import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import TB2 from "../../components/ExcerciseTypes/Table/TB2";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  1: {
    unit: "Unit 2",
    id: "FP8-WB-demo-2023-U2-P18-E1",
    audio: "/Audios/1-06 Friends Plus 8_WB.mp3",
    video: "",
    exerciseKey: "/img/FriendsPlus/Page18/Key/E1answerKey.jpg",
    component: T6v2,
    inputSize: 50,
    stylesTextInput: {
      fontSize: "24px",
      textTransform: "uppercase",
    },
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: "1",
        title: `
        <headphone scr="/Audios/1-06 Friends Plus 8_WB.mp3" name="06"></headphone>&ensp; Read and listen to the article. 
        Match ideas 1–5 with paragraphs A–E.
				`,
        color: "rgb(218,43,55)",
        star: 1,
      },
    ],
    // isHiddenCheck: true,
    questions: [
      {
        title: `
					<div><strong style="margin-right:15px">1</strong>famous people who are colour-blind <b>A</b></div>
					<div><strong style="margin-right:15px">2</strong>difficulties the condition causes at school #</div>
					<div><strong style="margin-right:15px">3</strong>what colour-blindness means #</div>
					<div><strong style="margin-right:15px">4</strong>difficulties the condition causes in daily life #</div>
					<div><strong style="margin-right:15px">5</strong>when most people become colour-blind #</div>
          <div><img src="/img/FriendsPlus/Page18/E1/1.jpg"></div>
				`,
        answer: ["E", "C", "D", "B"],
      },
    ],
  },
  2: {
    unit: "Unit 2",
    id: "FP8-WB-demo-2023-U2-P18-E2",
    audio: "",
    video: "",
    exerciseKey: "/img/FriendsPlus/Page18/Key/E2answerKey.jpg",
    component: T6v2,
    inputSize: 50,
    maxLength: 1,
    stylesTextInput: {
      fontSize: "23px",
      textTransform: "uppercase",
    },
    titleQuestion: [
      {
        num: "2",
        title: `
        Look at the ideas from the article. Are 
        they extra details (D) or examples (E)?`,
        color: "rgb(218,43,55)",
        star: 2,
      },
    ],
    // isHiddenCheck: true,
    questions: [
      {
        title: `
        <div style='font-size: 23px; line-height: 50px'>
          <div>Around 8% of men are colour-blind. &emsp; <b>D</b></div>
					<div><strong style="margin-right:15px">1</strong>Colour-‘blindness’ isn’t a good description.  #</div>
					<div><strong style="margin-right:15px">2</strong>Colour-blind people might drive through red 
          lights, thinking they’re green.  #</div>
					<div><strong style="margin-right:15px">3</strong>Countries on maps can be difficult to see.  #</div>
					<div><strong style="margin-right:15px">4</strong>Some teachers help colour-blind students. #</div>
        </div>
				`,
        answer: ["D", "E", "E", "D"],
      },
    ],
  },
  3: {
    unit: "Unit 2",
    id: "FP8-WB-demo-2023-U2-P18-E3",
    audio: "",
    video: "",
    exerciseKey: "/img/FriendsPlus/Page18/Key/E3answerKey.png",
    component: T6v2,
    // hideBtnFooter: true,
    inputSize: 100,
    stylesTextInput: {
      fontSize: "24px",
    },
    titleQuestion: [
      {
        num: "3",
        title: `
        Read the article again. Write <i>true</i> or <i>false</i>.
				`,
        color: "rgb(218,43,55)",
        star: 2,
      },
    ],
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <div>Mark Zuckerberg is colour-blind. &emsp; <u style='color: gray'><b>&ensp;true&ensp;</b></u></div>
        <div><strong style="margin-right:15px">1</strong>Not everyone knows they’re colour-blind.   #</div>
        <div><strong style="margin-right:15px">2</strong>The condition is more common in men.   #</div>
        <div><strong style="margin-right:15px">3</strong>No one sees only in black and white.  #</div>
        <div><strong style="margin-right:15px">4</strong>All people who are colour-blind see the same 
        colours. #</div>
        <img src="/img/FriendsPlus/Page18/E1/1.jpg">
				`,
        answer: ["true", "true", "false", "false"],
      },
    ],
  },
  4: {
    unit: "Unit 2",
    id: "FP8-WB-demo-2023-U2-P18-E4",
    audio: "",
    video: "",
    exerciseKey: "/img/FriendsPlus/Page18/Key/E4answerKey.jpg",
    component: T6v2,
    // hideBtnFooter: true,
    textareaStyle: {
      width: "900px",
    },
    inputSize: 800,
    stylesTextInput: {
      fontSize: "22px",
    },
    titleQuestion: [
      {
        num: "4",
        title: `
        Answer the questions. Write complete sentences.
				`,
        color: "rgb(218,43,55)",
        star: 3,
      },
    ],
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <div style='font-size: 22px; line-height: 45px'>
        <div>When does colour-blindness usually start?</div>
        <u style='color: gray'><strong>&ensp;Most people are born with colour-blindness.&ensp;</strong></u>
        <div><strong style="margin-right:15px">1</strong>Why can it take a long time for people to 
        realise they are colour-blind?</div>
        <textarea id="0" rows="2"></textarea>
        <div><strong style="margin-right:15px">2</strong>Which colours do most people with the 
        condition have problems seeing? </div>
        <textarea id="1" rows="2"></textarea>
        <div><strong style="margin-right:15px">3</strong>Which school subjects do you think students 
        with the condition have problems in?</div>
        <textarea id="2" rows="2"></textarea>
        <div><strong style="margin-right:15px">4</strong>How have things improved for some school 
        students with the condition? </div>
        <textarea id="3" rows="2"></textarea>

        </div>
				`,
        answer: [
          "Because they've always seen things this way.",
          "They have problems seeing reds, oranges and greens.",
          "The text suggests that they have problems with maths, geography and art.",
          "Some teachers have learned about their problems and help them.",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 2",
    id: "FP8-WB-demo-2023-U1-P18-E5",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "/img/FriendsPlus/Page18/Key/E5answerKey.png",
    component: Circle_Write,
    inputSize: 200,
    textAlign: "center",
    titleQuestion: [
      {
        num: "5",
        title: `<b>VOCABULARY PLUS</b> Choose the correct 
        words.`,
        color: "rgb(218,43,55)",
        // prefix: { icons: ['far fa-comment'], text: '' },
        star: 2,
      },
    ],
    question: {
      Write: {
        inputStyle: {
          width: 120,
          textAlign: "center",
        },
        answers: [``],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "-40px",
          // fontWeight: 600,
          color: "",
          // fontSize: 27,
          borderRadius: "50%",
          padding: "5px",
          // marginInline: '-1px',
        },
        selectWordStyle: { border: "3px solid rgb(0, 173, 238)" },
        limitSelect: 1,
        listWordsStyle: {},
        listWords: [
          "odour / texture / perfume",
          "fragrance / texture / flavour",
          "odour / texture / fragrance",
          "texture / fragrance / flavour",
          "perfume / texture / flavour",
        ],
        answers: ["0-8", "1-8", "2-0", "3-4", "4-4"],
        initialValue: [],
      },
      Layout: `
      <div style='line-height: 50px'>
      <div>Cats use <b>odour / <span style="border:3px solid black;border-radius:50%;padding:5px">scent</span> / flavour</b> to find food.<div>
			<div><strong style="margin-right:15px">1</strong>This is my favourite <strong><input id="0" type="Circle"></strong>.</div>
			<div><strong style="margin-right:15px">2</strong>Pick an ice cream <strong><input id="1" type="Circle"></strong>.</div>
			<div><strong style="margin-right:15px">3</strong>There’s a terrible <strong><input id="2" type="Circle"></strong> coming from the fridge. Is it the milk?</div>
			<div><strong style="margin-right:15px">4</strong>I love the <strong><input id="3" type="Circle"></strong> of spring 
      flowers.</div>
			<div><strong style="margin-right:15px">5</strong>The shirt’s <strong><input id="4" type="Circle"></strong> is soft.</div>
			
      </div>
        `,
    },
  },
};

export default json;
