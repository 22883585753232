import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 3",
    id: "FP8-SB-demo-2023-U3-P35-E1",
    exerciseKey: "img/FriendsPlus/Page35/E1/Key/E1answerKey.png",
    component: T6v2,
    inputSize: 70,
    stylesTextInput: {
      background: "transparent",
      border: "none",
      fontSize: "24px",
      borderBottom: "2px dotted rgb(204, 204, 204)",
      textTransform: "uppercase",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "1",
        title: `
				Are the sentences predictions (P) or 
plans / intentions (PI)? Write P or PI.
				`,
        color: "rgb(66,153,210)",
        star: 1,
      },
    ],
    isHiddenCheck: true,
    questions: [
      {
        title: `
				<span>I’m going to see Mark tomorrow. <strong style="text-decoration:underline; color: gray">&ensp;PI&ensp;</strong></span><br>
				<span>The weather won’t be very nice. <strong style="text-decoration:underline; color: gray">&ensp;P&ensp;</strong></span>
					<div style="display:flex;flex-direction:column; line-height: 50px">
					 <span><strong style="margin-right:30px">1</strong>Ian isn’t going to go out until after his exams. #</span>
					 <span><strong style="margin-right:30px">2</strong>What are you going to do next weekend? #</span>
					 <span><strong style="margin-right:30px">3</strong>What will the weather be like on Friday? #</span>
					 <span><strong style="margin-right:30px">4</strong>We’re going to stop for dinner in an hour. #</span>
					 <span><strong style="margin-right:30px">5</strong>The price of food will continue to rise. #</span>
					 <span><strong style="margin-right:30px">6</strong>When do you think your train will get here? #</span>
					</div>
        `,
        answer: [`PI`, `PI`, `P`, `PI`, `P`, `P`],
      },
    ],
  },
  2: {
    unit: "Unit 3",
    id: "FP8-SB-demo-2023-U3-P35-E1",
    exerciseKey: "img/FriendsPlus/Page35/E2/Key/E2answerKey.png",
    component: T6v2,
    inputSize: 700,
    maxLenght: 55,
    stylesTextInput: {
      background: "none",
      border: "none",
      fontSize: "23px",
      borderBottom: "2px dotted rgb(204, 204, 204)",
      height: 29,
    },
    titleQuestion: [
      {
        num: "2",
        title: `
				Write sentences using <i>will</i> or <i>be going to</i>.
				`,
        color: "rgb(66,153,210)",
        star: 2,
      },
    ],
    isHiddenCheck: true,
    questions: [
      {
        title: `<div style='font-size: 23px; line-height: 50px'>
				<span>I think / I / be / home late</span><br>
				<span><strong style="text-decoration:underline; color: gray">&ensp;I think I will be home late.&ensp;</strong></span>
					<div style="display:flex;flex-direction:column">
					 <span><strong style="margin-right:30px">1</strong>Stella is studying medicine at university. 
					 she / be / a doctor  </span>#
					 <span><strong style="margin-right:30px">2</strong>we / not / get married / this year </span>#
					 <span><strong style="margin-right:30px">3</strong>I / probably / move abroad / one day </span>#
					 <span><strong style="margin-right:30px">4</strong>how / schools / change / in the future / ? </span>#
					 <span><strong style="margin-right:30px">5</strong>Alex / travel around Europe / next month </span>#
					
					</div>
					</div>
        `,
        answer: [
          `She's going to be a doctor.`,
          `We aren't going to get married this year.`,
          `I'll probably move abroad one day.`,
          `How will schools change in the future?`,
          `Alex is going to travel around Europe next month.`,
        ],
      },
    ],
  },
  3: {
    unit: "Unit 3",
    id: "FP8-SB-demo-2023-U3-P35-E1",
    exerciseKey: "img/FriendsPlus/Page35/E3/Key/E3answerKey.png",
    component: T6v2,
    inputSize: 250,
    maxLenght: 20,
    stylesTextInput: {
      background: "transparent",
      border: "none",
      fontSize: "23px",
      borderBottom: "2px dotted rgb(204, 204, 204)",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "3",
        title: `
				Complete the sentences with the correct future continuous form of the verbs.
				`,
        color: "rgb(66,153,210)",
        star: 2,
      },
    ],
    isHiddenCheck: true,
    questions: [
      {
        title: `
				<img  src="img/FriendsPlus/Page35/E3/1.png"/> <br>
				<div style='font-size: 23px; line-height: 50px'>
				<span>I’ve got a history test tomorrow, so I <strong style="text-decoration:underline; color: gray">’ll be studying</strong>   tonight.</span>
			
					<div style="display:flex;flex-direction:column">
					 <span><strong style="margin-right:30px">1</strong> # you # that film later tonight? 
				 </span>
					 <span><strong style="margin-right:30px">2</strong>Adriana # home at four o’clock. She’ll 
					 still be at school. </span>
					 <span><strong style="margin-right:30px">3</strong> # they # in town at 11.30 
					 tomorrow?</span>
					 <span><strong style="margin-right:30px">4</strong>I  can’t tidy my room after dinner. I 
					# my friend then. </span>
					 <span><strong style="margin-right:30px">5</strong>I # some time out around nine o’clock. 
					 I’ll go online then. </span>
					 <span><strong style="margin-right:30px">6</strong>My parents # dinner tonight, so we’re 
					 going to get a takeaway. </span>
					
					</div>
					</div>
        `,
        answer: [
          `Will `,
          `be watching`,
          `won't be arriving`,
          `Will`,
          `be shopping`,
          `'ll be calling`,
          `'ll be taking`,
          `won't be making`,
        ],
      },
    ],
  },
  4: {
    unit: "Unit 3",
    id: "FP8-SB-demo-2023-U3-P35-E1",
    exerciseKey: "img/FriendsPlus/Page35/E4/Key/E4answerKey.png",
    component: T6v2,
    inputSize: 180,
    maxLength: 25,
    stylesTextInput: {
      background: "none",
      // border: "none",
      fontSize: "20px",
      fontWeight: "bold",
      textAlign: "center",
      height: 27,
    },
    titleQuestion: [
      {
        num: "4",
        title: `
				Correct the phrases.
				`,
        color: "rgb(66,153,210)",
        star: 2,
      },
    ],
    isHiddenCheck: true,
    questions: [
      {
        title: `
				<div style="position:relative; font-size: 20px;">
				<img  src="img/FriendsPlus/Page35/E4/1.jpg"/ style='width: 16cm; height: 23cm'>
				<div style="position:absolute; top: 34px; left: 40px;  width: 530px">
					In 1900, an engineer called <b>John Elfreth Watkins</b>
					asked himself, ‘What <b><s>we will</s></b> <u style='color: gray'>&ensp;will we&ensp;</u> be doing 
					in 100 years?’ Incredibly, some of his predictions 
					have now come true!
				</div>
				<div style="position:absolute; top:412px; left: 40px;  width: 530px; line-height: 25px">
					<ul>
					 <li type='disc'>
					 … it <sup>1</sup><b>'ll be being</b># possible to take 
					 photographs in colour, and we <sup>2</sup><b>all be sending</b># 
						photographs around the world instantly.
					 </li>
					 <li type='disc'>
					  … most people <sup>3</sup><b>will be having</b>#
						wireless telephones, and communication <sup>4</sup><b>is going to be</b># much easier – even men 
						working on boats in the ocean will be in touch with 
						their families back home.
					 </li>
					 <li type='disc'>
					 … we <sup>5</sup><b>aren't</b># be preparing all 
					 our meals at home any more. We <sup>6</sup><b>'ll buying</b>#
						them in shops, and just warming 
					 them up at home.
					 </li>
					 <li type='disc'>
					 … we <sup>7</sup><b>'re going to grow</b># our 
					 favourite vegetables in glasshouses all year round.
					 </li>
					</ul>
				</div>
				
			
				
				</div>
				
				
        `,
        answer: [
          `'ll be`,
          `'ll all be sending`,
          `will have`,
          `will be`,
          `won't`,
          `'ll be buying`,
          `'ll be growing`,
        ],
      },
    ],
  },
  5: {
    unit: "Unit 3",
    id: "FP8-SB-demo-2023-U3-P35-E1",
    exerciseKey: "img/FriendsPlus/Page35/E1/Key/answerKey.png",
    component: T6v2,
    hideBtnFooter: true,
    inputSize: 800,
    stylesTextInput: {
      background: "none",
      border: "none",
      fontSize: "23px",
      borderBottom: "2px dotted rgb(204, 204, 204)",
    },
    titleQuestion: [
      {
        num: "5",
        title: `
				What do you think these people will be doing at these times? Write complete sentences.
				`,
        color: "rgb(66,153,210)",
        star: 3,
      },
    ],
    isHiddenCheck: true,
    questions: [
      {
        title: `
				<div style='font-size: 23px; line-height: 60px'>
				<span>your teacher / later today</span><br>
				<span><strong style="text-decoration:underline; color: gray">&ensp;Mr Akin will be teaching football later today.&ensp;&ensp;&ensp;&ensp;&ensp;</strong></span>
					<div style="display:flex;flex-direction:column">
					 <span><strong style="margin-right:30px">1</strong>you / next Saturday morning
					 </span>#
					 <span><strong style="margin-right:30px">2</strong>your parents / twenty-five years’ time </span> #
					 <span><strong style="margin-right:30px">3</strong>your best friend / their next holiday </span> #
					 <span><strong style="margin-right:30px">4</strong>your classmates / after class ? </span> #
					 <span><strong style="margin-right:30px">5</strong>your favourite celebrity / the next few months </span> #
					
					</div>
					</div>
        `,
        answer: [``],
      },
    ],
  },
};

export default json;
