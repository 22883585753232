import T6v2 from '../../components/ExcerciseTypes/TypeIn/T6v2';
import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';

const json = {
  12: {
    unit: 'Unit 6',
    id: 'FP8-SB-demo-2023-U6-P69-E12',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page69/key/E12answerKey.png',
    component: Circle_Write,
    titleQuestion: [
      {
        num: '12',
        title: `
        Choose the correct words.
					`,
        color: 'rgb(121,106,175)',
      },
    ],
    question: {
      Write: {
        underlineStyle: {},
        inputStyle: {
          width: 100,
          textAlign: 'center',
          fontSize: 20,
          color: 'black',
          maxLength: 5,
        },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: '-40px',
          color: '',
          borderRadius: '50%',
          fontWeight: '700',
        },
        selectWordStyle: { border: '2px solid rgb(0, 173, 238)' },
        limitSelect: 1,
        listWords: [
          "mustn't / don't_have_to / don't_must",
          'should to / need / should',
          "shouldn't / don't_need / needn't",
          "mustn't / don't_need / don't_have_to",
          'must / have / need',
          'has / have / need',
        ],
        answers: ['0-0', '1-10', '2-4', '3-8', '4-0', '5-0'],
        initialValue: {},
      },
      Layout: `
          <p style="font-size: 25px">
                <span style="font-weight: bold; margin-right: 10px">1</span>
                <span>
                You 
                <input id='0' type='Circle' style="letter-spacing: 10px;"/> cheat in exams.
                </span>
          </p>

          <p style="font-size: 25px">
                <span style="font-weight: bold; margin-right: 10px">2</span>
                <span>
                If you need help, you 
                <input id='1' type='Circle' style="letter-spacing: 10px;"/> talk to your teacher.
                </span>
          </p>

          <p style="font-size: 25px">
                <span style="font-weight: bold; margin-right: 10px">3</span>
                <span>
                I’m not thirsty, so I 
                <input id='2' type='Circle' style="letter-spacing: 10px;"/> to stop and have a drink.
                </span>
          </p>

          <p style="font-size: 25px">
                <span style="font-weight: bold; margin-right: 10px">4</span>
                <span>
                You 
 
                <input id='3' type='Circle' style="letter-spacing: 10px;"/> do this extra homework. It’s your choice.
                </span>
          </p>

          <p style="font-size: 25px">
                <span style="font-weight: bold; margin-right: 10px">5</span>
                <span>
                We  
 
                <input id='4' type='Circle' style="letter-spacing: 10px;"/> arrive on time to school every day.
                </span>
          </p>

          <p style="font-size: 25px">
                <span style="font-weight: bold; margin-right: 10px">6</span>
                <span>
                Rosie  
                  
                <input id='5' type='Circle' style="letter-spacing: 10px;"/> to catch the next bus.
                </span>
          </p>


				`,
    },
  },
  13: {
    unit: 'Unit 6',
    id: 'FP8-SB-demo-2023-U6-P69-E13',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page69/key/E13answerKey.png',
    component: Circle_Write,
    titleQuestion: [
      {
        num: '13',
        title: `
        Choose the correct words. Then practise with your partner.
					`,
        color: 'rgb(121,106,175)',
      },
    ],
    question: {
      Write: {
        underlineStyle: {},
        inputStyle: {
          width: 100,
          textAlign: 'center',
          fontSize: 20,
          color: 'black',
          maxLength: 5,
        },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: '-40px',
          color: '',
          borderRadius: '50%',
          fontWeight: '700',
        },
        selectWordStyle: { border: '2px solid rgb(0, 173, 238)' },
        limitSelect: 1,
        listWords: [
          'a_speak b_spoken c_spoke', //0
          'a_advice b_plan c_information', //1
          "a_do b_don't c_did", //2
          'a_What b_How c_Why', //3
          'a_hould b_can c_need', //4
          'a_right b_true c_good', //5
        ],
        answers: ['0-2', '1-0', '2-2', '3-0', '4-4', '5-0'],
        initialValue: {},
      },
      Layout: `

      <div class="container">

        <div style="display: flex; flex-wrap: wrap; font-size: 25px">
          <div class="col-1">
            <span style="font-weight: bold">Raul</span>
          </div>
          <div class="col-10">
            <span >Hi, Layla. Is everything all right? You
            don’t look very happy.</span>
          </div>
        </div>

        <div style="display: flex; flex-wrap: wrap; font-size: 25px;margin-top:20px">
          <div class="col-1">
            <span style="font-weight: bold">Layla</span>
          </div>
          <div class="col-10">
            <span >I’m really worried about next week’s
            chemistry test. I haven’t really
            understood anything we’ve done. </span>
          </div>
        </div>

        <div style="display: flex; flex-wrap: wrap; font-size: 25px;margin-top:20px">
          <div class="col-1">
            <span style="font-weight: bold">Raul</span>
          </div>
          <div class="col-10">
            <span >Have you <sup>1</sup>................. to anyone
            about it?</span>
          </div>
        </div>

        <div style="display: flex; flex-wrap: wrap; font-size: 25px;margin-top:20px">
          <div class="col-1">
            <span style="font-weight: bold">Layla</span>
          </div>
          <div class="col-10">
          <span >No. Can you give me any <sup>2</sup>................. ?
            It’s all such a disaster!</span>
          </div>
        </div>

        <div style="display: flex; flex-wrap: wrap; font-size: 25px;margin-top:20px">
          <div class="col-1">
            <span style="font-weight: bold">Raul</span>
          </div>
          <div class="col-10">
          <span >Listen, <sup>3</sup>................. panic  </span>
          </div>
        </div>

        <div style="display: flex; flex-wrap: wrap; font-size: 25px;margin-top:20px">
          <div class="col-1">
            <span style="font-weight: bold">Layla</span>
          </div>
          <div class="col-10">
          <span >But I know I’m going to fail the test.
          <sup>4</sup>................ should I do?</span>
          </div>
        </div>

        <div style="display: flex; flex-wrap: wrap; font-size: 25px;margin-top:20px">
          <div class="col-1">
            <span style="font-weight: bold">Raul</span>
          </div>
          <div class="col-10">
          
          <span>You <sup>5</sup>................ to speak to Miss
          Wilson. Teachers are there to help us,
          you know. </span>
          </div>
        </div>

        <div style="display: flex; flex-wrap: wrap; font-size: 25px;margin-top:20px">
          <div class="col-1">
            <span style="font-weight: bold">Layla</span>
          </div>
          <div class="col-10">
          <span>Yes, you’re <sup>6</sup>................ . I’ll go and
          see her right now. </span>
          </div>
        </div>

      </div>

      <div class="container">
            <div style="display: flex;margin-top:30px;">
              <div class="col-1" >
                <span style="font-weight: bold;">1</span>
              </div>
              <div class="col-10">
                <div style="display: flex;gap:30px">
                  <input id='0' type='Circle' "/> 
                </div>
              </div>
            </div>

            <div style="display: flex;margin-top:30px;">
              <div class="col-1" >
                <span style="font-weight: bold;">2</span>
              </div>
              <div class="col-10">
                <div style="display: flex;gap:30px">
                  <input id='1' type='Circle' "/> 
                </div>
              </div>
            </div>

            <div style="display: flex;margin-top:30px;">
              <div class="col-1" >
                <span style="font-weight: bold;">3</span>
              </div>
              <div class="col-10">
                <div style="display: flex;gap:30px">
                  <input id='2' type='Circle' "/> 
                </div>
              </div>
            </div>

            <div style="display: flex;margin-top:30px;">
              <div class="col-1" >
                <span style="font-weight: bold;">4</span>
              </div>
              <div class="col-10">
                <div style="display: flex;gap:30px">
                  <input id='3' type='Circle' "/> 
                </div>
              </div>
            </div>

            <div style="display: flex;margin-top:30px;">
              <div class="col-1" >
                <span style="font-weight: bold;">5</span>
              </div>
              <div class="col-10">
                <div style="display: flex;gap:30px">
                  <input id='4' type='Circle' "/> 
                </div>
              </div>
            </div>

            <div style="display: flex;margin-top:30px;">
              <div class="col-1" >
                <span style="font-weight: bold;">6</span>
              </div>
              <div class="col-10">
                <div style="display: flex;gap:30px">
                  <input id='5' type='Circle' "/> 
                </div>
              </div>
            </div>

            
      </div>
          


				`,
    },
  },
  14: {
    unit: 'Unit 6',
    id: 'FP8-SB-demo-2023-U6-P69-E14',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page69/key/E14answerKey.png',
    component: Circle_Write,
    titleQuestion: [
      {
        num: '14',
        title: `
        Read the opinion essay and choose the correct words.

					`,
        color: 'rgb(121,106,175)',
      },
    ],
    question: {
      Write: {
        underlineStyle: {},
        inputStyle: {
          width: 100,
          textAlign: 'center',
          fontSize: 20,
          color: 'black',
          maxLength: 5,
        },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: '-40px',
          color: '',
          borderRadius: '50%',
          fontWeight: '700',
          fontSize: '20px',
        },
        selectWordStyle: { border: '2px solid rgb(0, 173, 238)' },
        limitSelect: 1,
        listWords: [
          'In_my_opinion / As_a_result', //0
          'All_in_all / Firstly', //1
          'Finally / Secondly', //2
          'Finally / I_feel', //3
          'To_conclude / Secondly', //4
          'All_in_all / Firstly', //5
          'feel / am feeling', //6
        ],
        answers: ['0-0', '1-4', '2-4', '3-0', '4-0', '5-0', '6-0'],
        initialValue: {},
      },
      Layout: `
      <div style="margin-left: 100px;position: relative;">
        <img src="img/FriendsPlus/Page69/6.jpg"/>

        <div style="position: absolute;top:172px;left:31px">
          <input id='0' type='Circle' /> 
        </div>

        <div style="position: absolute;top:200px;left:182px">
          <input id='1' type='Circle' /> 
        </div>

        <div style="position: absolute;top:286px;left:211px">
          <input id='2' type='Circle' /> 
        </div>

        <div style="position: absolute;top:401px;left:207px">
          <input id='3' type='Circle' /> 
        </div>

        <div style="position: absolute;top:561px;left:30px">
          <input id='4' type='Circle' /> 
        </div>

        <div style="position: absolute;top:592px;left:159px">
          <input id='5' type='Circle' /> 
        </div>

        <div style="position: absolute;top:592px;left:336px">
          <input id='6' type='Circle' /> 
        </div>


      </div>
      

				`,
    },
  },
};

export default json;
