import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import MatchDotsWrite from "../../components/ExcerciseTypes/LineTo/MatchDotsWrite";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";

const json = {
  1: {
    // Exercise num
    unit: "Unit 2",
    id: "FP8-SB-demo-2023-U2-P26-E1",
    // audio: 'Audios/Track 08.mp3',
    video: "",
    component: MatchDotsWrite,
    exerciseKey: "/img/FriendsPlus/Page26/Key/E1answerKey.png",
    titleQuestion: [
      {
        num: "1",
        title: `
            Find eight more verbs in the
            wordsearch.
      			`,
        color: "rgb(54,146,208)",
        star: 1,
      },
    ],
    // inputSize: 50,
    question: {
      Write: {
        underlineStyle: {},
        inputStyle: {
          width: 300,
          height: 30,
          borderBottom: "1px solid black",
          textAlign: "center",
          fontSize: 21,
          // color: "black",
          marginTop: "3px",
          maxLength: 100,
          // /backgroundColor: "blue",
        },
        isHiddenCheck: true,

        answers: [
          "destroy / damage / waste / throw away",
          "destroy / damage / waste / throw away",
          "destroy / damage / waste / throw away",
          "destroy / damage / waste / throw away",
        ],
        initialValue: [],
      },
      DrawLines: {
        multipleLine: true,
        boxMatch: [
          //HANG 1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "60px",
              left: "59px",
              width: 52,
              height: 47,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "60px",
              left: "115px",
              width: 52,
              height: 47,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "60px",
              left: "171px",
              width: 52,
              height: 47,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //3

          {
            boxMatchStyle: {
              position: "absolute",
              top: "60px",
              left: "227px",
              width: 52,
              height: 47,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //4
          {
            boxMatchStyle: {
              position: "absolute",
              top: "60px",
              left: "282px",
              width: 52,
              height: 47,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //5
          {
            boxMatchStyle: {
              position: "absolute",
              top: "60px",
              left: "338px",
              width: 52,
              height: 47,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //6
          {
            boxMatchStyle: {
              position: "absolute",
              top: "60px",
              left: "394px",
              width: 52,
              height: 47,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "60px",
              left: "449px",
              width: 52,
              height: 47,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //8
          {
            boxMatchStyle: {
              position: "absolute",
              top: "60px",
              left: "505px",
              width: 52,
              height: 47,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //9

          //HANG 2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "110px",
              left: "59px",
              width: 52,
              height: 47,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "110px",
              left: "115px",
              width: 52,
              height: 47,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "110px",
              left: "171px",
              width: 52,
              height: 47,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //3

          {
            boxMatchStyle: {
              position: "absolute",
              top: "110px",
              left: "227px",
              width: 52,
              height: 47,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //4
          {
            boxMatchStyle: {
              position: "absolute",
              top: "110px",
              left: "282px",
              width: 52,
              height: 47,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //5
          {
            boxMatchStyle: {
              position: "absolute",
              top: "110px",
              left: "338px",
              width: 52,
              height: 47,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //6
          {
            boxMatchStyle: {
              position: "absolute",
              top: "110px",
              left: "394px",
              width: 52,
              height: 47,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "110px",
              left: "449px",
              width: 52,
              height: 47,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //8
          {
            boxMatchStyle: {
              position: "absolute",
              top: "110px",
              left: "505px",
              width: 52,
              height: 47,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //9

          //HANG 3
          {
            boxMatchStyle: {
              position: "absolute",
              top: "159px",
              left: "59px",
              width: 52,
              height: 47,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "159px",
              left: "115px",
              width: 52,
              height: 47,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "159px",
              left: "171px",
              width: 52,
              height: 47,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //3

          {
            boxMatchStyle: {
              position: "absolute",
              top: "159px",
              left: "227px",
              width: 52,
              height: 47,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //4
          {
            boxMatchStyle: {
              position: "absolute",
              top: "159px",
              left: "282px",
              width: 52,
              height: 47,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //5
          {
            boxMatchStyle: {
              position: "absolute",
              top: "159px",
              left: "338px",
              width: 52,
              height: 47,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //6
          {
            boxMatchStyle: {
              position: "absolute",
              top: "159px",
              left: "394px",
              width: 52,
              height: 47,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "159px",
              left: "449px",
              width: 52,
              height: 47,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //8
          {
            boxMatchStyle: {
              position: "absolute",
              top: "159px",
              left: "505px",
              width: 52,
              height: 47,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //9

          //HANG 4
          {
            boxMatchStyle: {
              position: "absolute",
              top: "209px",
              left: "59px",
              width: 52,
              height: 47,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "209px",
              left: "115px",
              width: 52,
              height: 47,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "209px",
              left: "171px",
              width: 52,
              height: 47,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //3

          {
            boxMatchStyle: {
              position: "absolute",
              top: "209px",
              left: "227px",
              width: 52,
              height: 47,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //4
          {
            boxMatchStyle: {
              position: "absolute",
              top: "209px",
              left: "282px",
              width: 52,
              height: 47,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //5
          {
            boxMatchStyle: {
              position: "absolute",
              top: "209px",
              left: "338px",
              width: 52,
              height: 47,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //6
          {
            boxMatchStyle: {
              position: "absolute",
              top: "209px",
              left: "394px",
              width: 52,
              height: 47,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "209px",
              left: "449px",
              width: 52,
              height: 47,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //8
          {
            boxMatchStyle: {
              position: "absolute",
              top: "209px",
              left: "505px",
              width: 52,
              height: 47,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //9

          //HANG 5
          {
            boxMatchStyle: {
              position: "absolute",
              top: "258px",
              left: "59px",
              width: 52,
              height: 47,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "258px",
              left: "115px",
              width: 52,
              height: 47,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "258px",
              left: "171px",
              width: 52,
              height: 47,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //3

          {
            boxMatchStyle: {
              position: "absolute",
              top: "258px",
              left: "227px",
              width: 52,
              height: 47,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //4
          {
            boxMatchStyle: {
              position: "absolute",
              top: "258px",
              left: "282px",
              width: 52,
              height: 47,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //5
          {
            boxMatchStyle: {
              position: "absolute",
              top: "258px",
              left: "338px",
              width: 52,
              height: 47,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //6
          {
            boxMatchStyle: {
              position: "absolute",
              top: "258px",
              left: "394px",
              width: 52,
              height: 47,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "258px",
              left: "449px",
              width: 52,
              height: 47,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //8
          {
            boxMatchStyle: {
              position: "absolute",
              top: "258px",
              left: "505px",
              width: 52,
              height: 47,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //9

          //HANG 6
          {
            boxMatchStyle: {
              position: "absolute",
              top: "308px",
              left: "59px",
              width: 52,
              height: 47,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "308px",
              left: "115px",
              width: 52,
              height: 47,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "308px",
              left: "171px",
              width: 52,
              height: 47,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //3

          {
            boxMatchStyle: {
              position: "absolute",
              top: "308px",
              left: "227px",
              width: 52,
              height: 47,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //4
          {
            boxMatchStyle: {
              position: "absolute",
              top: "308px",
              left: "282px",
              width: 52,
              height: 47,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //5
          {
            boxMatchStyle: {
              position: "absolute",
              top: "308px",
              left: "338px",
              width: 52,
              height: 47,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //6
          {
            boxMatchStyle: {
              position: "absolute",
              top: "308px",
              left: "394px",
              width: 52,
              height: 47,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "308px",
              left: "449px",
              width: 52,
              height: 47,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //8
          {
            boxMatchStyle: {
              position: "absolute",
              top: "308px",
              left: "505px",
              width: 52,
              height: 47,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //9

          //HANG 7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "358px",
              left: "59px",
              width: 52,
              height: 47,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "358px",
              left: "115px",
              width: 52,
              height: 47,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "358px",
              left: "171px",
              width: 52,
              height: 47,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //3

          {
            boxMatchStyle: {
              position: "absolute",
              top: "358px",
              left: "227px",
              width: 52,
              height: 47,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //4
          {
            boxMatchStyle: {
              position: "absolute",
              top: "358px",
              left: "282px",
              width: 52,
              height: 47,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //5
          {
            boxMatchStyle: {
              position: "absolute",
              top: "358px",
              left: "338px",
              width: 52,
              height: 47,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //6
          {
            boxMatchStyle: {
              position: "absolute",
              top: "358px",
              left: "394px",
              width: 52,
              height: 47,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "358px",
              left: "449px",
              width: 52,
              height: 47,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //8
          {
            boxMatchStyle: {
              position: "absolute",
              top: "358px",
              left: "505px",
              width: 52,
              height: 47,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //9

          //HANG 8
          {
            boxMatchStyle: {
              position: "absolute",
              top: "407px",
              left: "59px",
              width: 52,
              height: 47,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "407px",
              left: "115px",
              width: 52,
              height: 47,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "407px",
              left: "171px",
              width: 52,
              height: 47,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //3

          {
            boxMatchStyle: {
              position: "absolute",
              top: "407px",
              left: "227px",
              width: 52,
              height: 47,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //4
          {
            boxMatchStyle: {
              position: "absolute",
              top: "407px",
              left: "282px",
              width: 52,
              height: 47,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //5
          {
            boxMatchStyle: {
              position: "absolute",
              top: "407px",
              left: "338px",
              width: 52,
              height: 47,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //6
          {
            boxMatchStyle: {
              position: "absolute",
              top: "407px",
              left: "394px",
              width: 52,
              height: 47,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "407px",
              left: "449px",
              width: 52,
              height: 47,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //8
          {
            boxMatchStyle: {
              position: "absolute",
              top: "407px",
              left: "505px",
              width: 52,
              height: 47,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //9

          //HANG 9
          {
            boxMatchStyle: {
              position: "absolute",
              top: "457px",
              left: "59px",
              width: 52,
              height: 47,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "457px",
              left: "115px",
              width: 52,
              height: 47,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "457px",
              left: "171px",
              width: 52,
              height: 47,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //3

          {
            boxMatchStyle: {
              position: "absolute",
              top: "457px",
              left: "227px",
              width: 52,
              height: 47,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //4
          {
            boxMatchStyle: {
              position: "absolute",
              top: "457px",
              left: "282px",
              width: 52,
              height: 47,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //5
          {
            boxMatchStyle: {
              position: "absolute",
              top: "457px",
              left: "338px",
              width: 52,
              height: 47,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //6
          {
            boxMatchStyle: {
              position: "absolute",
              top: "457px",
              left: "394px",
              width: 52,
              height: 47,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "457px",
              left: "449px",
              width: 52,
              height: 47,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //8
          {
            boxMatchStyle: {
              position: "absolute",
              top: "457px",
              left: "505px",
              width: 52,
              height: 47,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //9

          //HANG 10
          {
            boxMatchStyle: {
              position: "absolute",
              top: "506px",
              left: "59px",
              width: 52,
              height: 47,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "506px",
              left: "115px",
              width: 52,
              height: 47,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "506px",
              left: "171px",
              width: 52,
              height: 47,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //3

          {
            boxMatchStyle: {
              position: "absolute",
              top: "506px",
              left: "227px",
              width: 52,
              height: 47,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //4
          {
            boxMatchStyle: {
              position: "absolute",
              top: "506px",
              left: "282px",
              width: 52,
              height: 47,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //5
          {
            boxMatchStyle: {
              position: "absolute",
              top: "506px",
              left: "338px",
              width: 52,
              height: 47,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //6
          {
            boxMatchStyle: {
              position: "absolute",
              top: "506px",
              left: "394px",
              width: 52,
              height: 47,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "506px",
              left: "449px",
              width: 52,
              height: 47,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //8
          {
            boxMatchStyle: {
              position: "absolute",
              top: "506px",
              left: "505px",
              width: 52,
              height: 47,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //9
        ],
        answers: [
          "18-72",
          "2-29",
          "27-32",
          "16-70",
          "40-44",
          "45-50",
          "75-78",
          "81-89",
        ],
        initialValue: [],
      },
      Layout: `
      <div><i style='font-size:19px; color: rgb(245,54,92)'>*Choose the first and last character of the word.</i></div>
			<div style='position: relative; text-align:left; margin-bottom: 100px' >
       
      <div style=""><img src='img/FriendsPlus/Page26/E1/1.jpg' style='width: 15cm' /></div>    
				<div style="margin-left:20px;margin-top:20px; font-size: 21px; line-height: 30px">
    			<div>Which five verbs in the wordsearch have a
            negative meaning?</div>
            <u style="margin-left:35px; color: gray"><b>&ensp;run out of&ensp;</b></u>
            <div><input id='0'/></div>
            <div><input id='1'/></div>
            <div><input id='2'/></div>
            <div><input id='3'/></div>
            </div>
			</div>  

			  <input id='0' type= 'boxMatch' />
			  <input id='1' type= 'boxMatch' />
			  <input id='2' type= 'boxMatch' />
			  <input id='3' type= 'boxMatch' />
        <input id='4' type= 'boxMatch' />
			  <input id='5' type= 'boxMatch' />
        <input id='6' type= 'boxMatch' />
			  <input id='7' type= 'boxMatch' />
        <input id='8' type= 'boxMatch' />

        <input id='9' type= 'boxMatch' />
        <input id='10' type= 'boxMatch' />
			  <input id='11' type= 'boxMatch' />
			  <input id='12' type= 'boxMatch' />
			  <input id='13' type= 'boxMatch' />
        <input id='14' type= 'boxMatch' />
			  <input id='15' type= 'boxMatch' />
        <input id='16' type= 'boxMatch' />
			  <input id='17' type= 'boxMatch' />
        
        
        <input id='18' type= 'boxMatch' />
			  <input id='19' type= 'boxMatch' />
			  <input id='20' type= 'boxMatch' />
			  <input id='21' type= 'boxMatch' />
        <input id='22' type= 'boxMatch' />
			  <input id='23' type= 'boxMatch' />
        <input id='24' type= 'boxMatch' />
			  <input id='25' type= 'boxMatch' />
        <input id='26' type= 'boxMatch' />

        <input id='27' type= 'boxMatch' />
			  <input id='28' type= 'boxMatch' />
			  <input id='29' type= 'boxMatch' />
			  <input id='30' type= 'boxMatch' />
        <input id='31' type= 'boxMatch' />
			  <input id='32' type= 'boxMatch' />
        <input id='33' type= 'boxMatch' />
			  <input id='34' type= 'boxMatch' />
        <input id='35' type= 'boxMatch' />

        <input id='36' type= 'boxMatch' />
        <input id='37' type= 'boxMatch' />
			  <input id='38' type= 'boxMatch' />
			  <input id='39' type= 'boxMatch' />
			  <input id='40' type= 'boxMatch' />
        <input id='41' type= 'boxMatch' />
			  <input id='42' type= 'boxMatch' />
        <input id='43' type= 'boxMatch' />
			  <input id='44' type= 'boxMatch' />
        

        <input id='45' type= 'boxMatch' />
			  <input id='46' type= 'boxMatch' />
			  <input id='47' type= 'boxMatch' />
			  <input id='48' type= 'boxMatch' />
        <input id='49' type= 'boxMatch' />
			  <input id='50' type= 'boxMatch' />
        <input id='51' type= 'boxMatch' />
			  <input id='52' type= 'boxMatch' />
        <input id='53' type= 'boxMatch' />

        <input id='54' type= 'boxMatch' />
			  <input id='55' type= 'boxMatch' />
			  <input id='56' type= 'boxMatch' />
			  <input id='57' type= 'boxMatch' />
        <input id='58' type= 'boxMatch' />
			  <input id='59' type= 'boxMatch' />
        <input id='60' type= 'boxMatch' />
			  <input id='61' type= 'boxMatch' />
        <input id='62' type= 'boxMatch' />

        <input id='63' type= 'boxMatch' />
			  <input id='64' type= 'boxMatch' />
			  <input id='65' type= 'boxMatch' />
			  <input id='66' type= 'boxMatch' />
        <input id='67' type= 'boxMatch' />
			  <input id='68' type= 'boxMatch' />
        <input id='69' type= 'boxMatch' />
			  <input id='70' type= 'boxMatch' />
        <input id='71' type= 'boxMatch' />

        <input id='72' type= 'boxMatch' />
			  <input id='73' type= 'boxMatch' />
			  <input id='74' type= 'boxMatch' />
			  <input id='75' type= 'boxMatch' />
        <input id='76' type= 'boxMatch' />
			  <input id='77' type= 'boxMatch' />
        <input id='78' type= 'boxMatch' />
			  <input id='79' type= 'boxMatch' />
        <input id='80' type= 'boxMatch' />

        <input id='81' type= 'boxMatch' />
			  <input id='82' type= 'boxMatch' />
			  <input id='83' type= 'boxMatch' />
			  <input id='84' type= 'boxMatch' />
        <input id='85' type= 'boxMatch' />
			  <input id='86' type= 'boxMatch' />
        <input id='87' type= 'boxMatch' />
			  <input id='88' type= 'boxMatch' />
        <input id='89' type= 'boxMatch' />

		  `,
    },
  },

  2: {
    unit: "Unit 2",
    id: "FP8-SB-demo-2023-U2-P26-E2",
    audio: "",
    video: "",
    component: Circle_Write,
    exerciseKey: "img/FriendsPlus/Page26/Key/E2answerKey.png",
    titleQuestion: [
      {
        num: "2",
        title: `
        Choose the correct verbs for the definitions.
				`,
        color: "rgb(78, 59, 151)",
        star: 2,
      },
    ],
    question: {
      Write: {
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "-40px",
          fontWeight: 600,
          //fontStyle: "italic",
          color: "",
          fontSize: 21,
          border: "none",
          borderRadius: "60%",
          // marginInline: '-1px',
        },
        selectWordStyle: {
          border: "2px solid",
          padding: "2px",
        },
        limitSelect: 1,
        listWords: [
          "a  <br>|b <br> |c",
          "a  <br>|b <br> |c",
          "a  <br>|b <br> |c",
          "a  <br>|b <br> |c",
          "a  <br>|b <br> |c",
          "a  <br>|b <br> |c",
          "a  <br>|b <br> |c",
          "a  <br>|b <br> |c",
        ],
        //  listWords: [
        //   `should have % `,
        //   `shouldn’t have % `,
        //   `must have % `,
        //   `may not have % `,
        //   `may have % `,
        //   `don’t have to % `,
        //   `might have % `,
        //   `have to % `,
        //   `have to % `,
        // ],
        answers: ["1-6", "0-0", "2-0", "3-0", "4-0", "5-6", "6-6", "7-12"],

        initialValue: [],
      },

      Layout: `
      <div style='font-size: 22px'>
      make something dirty, contaminate<br>
      <div style='display: flex; justify-content: space-between'>
        <div><b>a&ensp;</b>damage</div>
        <div><b style='padding: 3px; border: 2px solid gray; border-radius: 50%'>b&ensp;</b>pollute</div>
        <div><b>c&ensp;</b>waste</div>
      </div>
      </div>
            <div style='position: relative; font-size: 22px'>
                          <div>
                            <b>1</b>&ensp;make bigger in size or number
                            <div style='width:17cm;margin-left:20px'>
                              <input id='0'  type='Circle' />
                            </div>

                            <b>2</b>&ensp;not use and keep for future use
                            <div style='width:17cm;margin-left:20px'>
                              <input id='1'  type='Circle' />
                            </div>

                            <b>3</b>&ensp;use more than you need of something, or throw away something useful
                            <div style='width:17cm;margin-left:20px'>
                              <input id='2'  type='Circle' />
                            </div>

                            <b>4</b>&ensp;make smaller in size or number
                            <div style='width:17cm;margin-left:20px'>
                              <input id='3'  type='Circle' />
                            </div>
                          </div>

                          <div>
                            <b>5</b>&ensp;give, or make something ready for use
                            <div style='width:17cm;margin-left:20px'>
                              <input id='4'  type='Circle' />
                            </div>

                            <b>6</b>&ensp;give something energy to make it work
                            <div style='width:17cm;margin-left:20px'>
                              <input id='5'  type='Circle' />
                            </div>

                            <b>7</b>&ensp;stop something from existing
                            <div style='width:17cm;margin-left:20px'>
                              <input id='6'  type='Circle' />
                            </div>

                            
                              <b>8</b>&ensp;keep something safe from damage
                              <div style='width:17cm;margin-left:20px'>
                                <input id='7'  type='Circle' />
                              </div>
                          </div>
                               
                  <div style=" position: absolute; top: 37px; left: 50px;">
                        <div>
                          increase<br>
                          produce<br>
                          reduce<br><br>
                        
                          protect<br>
                          save<br>
                          reduce<br><br>

                          waste<br>
                          afford<br>
                          pollute<br><br>

                          reduce<br>
                          increase<br>
                          produce<br><br>
                        </div>

                        <div style="">
                          

                          provide<br>
                          save<br>
                          reduce<br><br>

                          user<br>
                          power<br>
                          provide<br><br>

                          damage<br>
                          destroy<br>
                          reduce<br><br>

                          
                            provide<br>
                            pollute<br>
                            protect<br><br>

                        </div>
                    </div>
            </div>  
                
      `,
    },
  },
  3: {
    unit: "Unit 2",
    id: "FP8-SB-demo-2023-U2-P26-E3",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page26/Key/E3answerKey.png",
    component: T6v2,
    inputSize: 150,
    stylesTextInput: {
      textAlign: "center",
      borderStyle: "2px dotted",
      fontSize: "23px",
    },
    hintBox: [
      {
        src: [
          "afford / provide",
          "<del>increase / produce</del>",
          "power / develop",
          "throw away / recycle",
        ],
        borderColor: "rgb(28, 192, 217)",
        width: 900,
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: `
				Complete the sentences with the correct forms of the pairs of verbs.
				`,
        color: "rgb(78, 59, 151)",
        star: 2,
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <div style='padding-left:80px;' ><hintBox id='0'></hintBox></div>

        <div style="display:flex; font-size: 23px; line-height: 50px">
          <div><img style='padding-top:10px; height:260px; width:420px;' src="img/FriendsPlus/Page26/E3/1.jpg" /></div>

          <div style="padding-left:23px">
            <div>The factory <i><u style='color: gray'>produces</u></i> more cars now that it has <i><u style='color: gray'>increased</u></i> in size.
            <div><b>1</b> Don't # those old bottles! You can # them and save resources.
            <div><b>2</b> Last year, they # a bus to the festival for visitors who couldn't # a taxi.
            <div><b>3</b> We are # a solar energy park to # 20,000 homes.
          </div>
        </div>
        `,
        answer: [
          `throw away`,
          `recycle`,
          `provided`,
          `afford`,
          `developing`,
          `power`,
        ],
      },
    ],
  },
  4: {
    unit: "Unit 2",
    id: "FP8-SB-demo-2023-U2-P26-E4",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page26/Key/E4answerKey.png",
    component: T6v2,
    inputSize: 120,
    stylesTextInput: {
      textAlign: "center",
      //borderStyle: "dotted",
      background: "none",
      borderBottom: "none",
      fontSize: "22px",
    },
    textareaStyle: {
      background: "none",
      width: 500,
      fontSize: 22,
      height: 100,
    },
    titleQuestion: [
      {
        num: "4",
        title: `<span style='font-size: 24px'> Two students are talking about how
        their school could do more for the environment.
        Complete the dialogue with the correct form of
        environment and consumerism verbs and add
        your own ideas.</span>
       
				`,
        color: "rgb(78, 59, 151)",
        star: 3,
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <div style='position:relative'>
        <img src='img/FriendsPlus/Page26/E4/1.jpg' style='width: 17cm; '/>
          <div style=" position: absolute; top: 177px; left: 70px; "><input id='0' value='text' width=''></input></div>
          <div style=" position: absolute; top: 247px; left: 334px; "><input id='1' value='text' width=''></input></div>
          <div style=" position: absolute; top: 387px; left: 378px; "><input id='2' value='text' width=''></input></div>
          <div style=" position: absolute; top: 493px; left: 80px; "><input id='3' value='text' width=''></input></div>
          <div style=" position: absolute; top: 561px; left: 190px; "><input id='4' value='text' width=''></input></div>
          <div style=" position: absolute; top: 630px; left: 120px; "><input id='5' value='text' width=''></input></div>
          <div style=" position: absolute; top: 662px; left: 114px; "><textarea id='6' rows='3'></input></div>
        </div>
        `,
        answer: [
          "recycle",
          "provide",
          "afford",
          "wasting",
          "damages",
          "run out of",
          "",
        ],
      },
    ],
  },
};

export default json;
