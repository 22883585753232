// import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
// import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
// import MatchDots from "../../components/ExcerciseTypes/TypeIn/MatchDots";
// import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
const json = {
  1: {
    unit: "Unit 8",
    id: "FP8-WB-demo-2023-U8-P52-E1",
    audio: "",
    video: "",
    recorder: true,
    exerciseKey: "img/FriendsPlus/Page53/Key/E1answerKey.png",
    component: T6v2,
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted black",
      textAlign: "center",
      fontSize: "22px",
      height: 28,
    },
    inputSize: 125,
    isHiddenCheck: true,

    maxLength: 10,
    titleQuestion: [
      {
        num: "1",
        title: `
        Order the words to complete the table.
				`,
        color: "rgb(28,190,215)",
        star: 1,
      },
    ],
    questions: [
      {
        title: ` <div style='font-size: 22px; position: relative'>
       
        <div style='margin-top: 10px;'>
        the film / is / where / set<br>
        <b>1</b> made / are / what / of / they<br>
        <b>2</b> the book / was / by / who / written<br>
        <b>3</b> they / when / born / were<br>
        <b>4</b> surprised / you / are<br>
        <b>5</b> paid / the actor / was<br>
        </div>
        <img style="width:18cm; height:15cm; margin-top: 20px" src='img/FriendsPlus/Page53/E1/1.jpg' />
        <div style=" position: absolute; top: 396px; left: 32px; ">
          <b>1</b>#<br><b>2</b>#<br><b>3</b>#<br>
        </div>

        <div style=" position: absolute; top: 396px; left: 195px; ">
          # <br># <br># <br>
        </div>
        <div style=" position: absolute; top: 396px; left: 346px; ">
          # <br># <br># <br>
        </div>
        <div style=" position: absolute; top: 396px; left: 506px; ">
          #? <br>#? <br>#? <br>
        </div>

        <div style=" position: absolute; top: 598px; left: 32px; ">
          <b>4</b><input id='12' width='130px'><br>Yes, I am. / No, I’m not.
        </div>
        <div style=" position: absolute; top: 598px; left: 247px; ">
        <input id='13' width='150px'>
        </div>
        <div style=" position: absolute; top: 598px; left: 498px; ">
        <input id='14' width='145px'>?
        </div>

        <div style=" position: absolute; top: 685px; left: 32px; ">
          <b>5</b><input id='15' width='130px'><br>Yes, he was. / No, he wasn’t.
        </div>
        <div style=" position: absolute; top: 685px; left: 247px; ">
        <input id='16' width='150px'>
        </div>
        <div style=" position: absolute; top: 685px; left: 498px; ">
        <input id='17' width='145px'>?
        </div>
        </div>

        `,
        answer: [
          `What`,
          `Who`,
          `When`,
          `are`,
          `was`,
          `were`,
          `they`,
          `the book`,
          `they`,
          `made of`,
          `written by`,
          `born`,
          `Are`,
          `you`,
          `surprised`,
          `Was`,
          `the actor`,
          `paid`,
        ],
      },
    ],
  },
  2: {
    unit: "Unit 8",
    id: "FP8-WB-demo-2023-U8-P52-E5",
    // audio: "Audios/1-19 Friends Plus 8_WB.mp3",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page53/key/E2answerKey.png",
    component: T6v2,
    maxLength: 20,
    inputSize: 170,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      fontSize: "24px",
      borderStyle: "dotted",
      fontWeight: "bold",
      textAlign: "center ",
    },
    titleQuestion: [
      {
        num: "2",
        star: 2,
        title: `
        Complete the questions using the correct form of the verbs in brackets.
        `,
        color: "rgb(28,190,215)",
        // prefix: { icons: ["far fa-comment"], text: "USE IT!" },
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
          <div style='line-height:2'>
            <div>Where <b><u style='color: gray'>&ensp;were&ensp;</u></b> you <b><u  style='color: gray'>&ensp;given&ensp;</u></b> those special tickets? (give)</div>

            <div><b>1.</b> # adverts # usually before the films? (show)</div>

            <div><b>2.</b> Which prize # his most popular novel # ? (award)</div>

            <div><b>3.</b> When # your parents # ? (marry)</div>

            <div><b>4.</b> How many films # each year in the USA and Canada? (make)</div>

            <div><b>5.</b> # the film # in 3D? (release) (show)</div>

            <div><b>6.</b> Who # the main character # by? (play)</div>

          </div>

        
        `,
        answer: [
          `Are`,
          `shown`,
          `was`,
          `awarded`,
          `were`,
          `married`,
          `are made`,
          `Was`,
          `released`,
          `is`,
          `played`,
        ],
      },
    ],
  },
  3: {
    unit: "Unit 8",
    id: "FP8-WB-demo-2023-U8-P53-E3",
    audio: "",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page53/key/E3answerKey.png",
    component: T6v2,
    textareaStyle: { width: "730px" },
    // maxLength: 1,
    inputSize: 700,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      // textAlign: "center",
      fontSize: "22px",
      // fontWeight: "bold",
      borderStyle: "dotted",
    },
    titleQuestion: [
      {
        num: "3",

        title: `
        Read the poster for the Hightown Village 
        Film Festival. Write questions for the answers. Use the correct past passive form of the verbs.
									`,
        color: "rgb(28,190,215)",
        star: 2,
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,

    hintBox: [
      {
        src: [`call`, "choose", "direct", "̶h̶̶o̶̶l̶̶d̶", "organise", "sell", "show"],
        borderColor: "#1dbfd7",
        width: 700,
      },
    ],
    questions: [
      {
        title: `
        <div style="line-height:45px; font-size: 22px">
         <hintBox id='0'></hintBox>
        <div><b><u style='color: gray'>&ensp;Where was the festival held?&ensp;</u></b> In Hightown Village.</div>
        <div style="line-height:45px; font-size: 22px">
            <div><b>1.</b> #  </div>
            <div style="margin-left:25px">‘The Summer of Love’.</div>
        </div>
         <div style="line-height:45px; font-size: 22px">
            <div><b>2.</b> #  </div>
            <div style="margin-left:25px">Twenty-five.</div>
        </div>
        <div style="line-height:45px; font-size: 22px">
          <div><b>3.</b> #  </div>
          <div style="margin-left:25px">At Hightown Village Hall.</div>
       </div>
       <div style="line-height:45px; font-size: 22px">
        <div><b>4.</b> #  </div>
        <div style="margin-left:25px">Alice Valdez.</div>
      </div>
      <div style="line-height:45px; font-size: 22px"><b>5.</b> #  </div>
        <div style="margin-left:25px">Billy and Olivia Hartley.</div>
      </div>
      <div style="line-height:45px; font-size: 22px"><b>6.</b> #  </div>
        <div style="margin-left:25px">At local shops.</div>
      </div>
        
        </div>
        
      
			
				
									`,
        answer: [
          `What was the festival called?`,
          `How many films were chosen?`,
          `Where were the films shown?`,
          `Who was the festival directed by?`,
          `Who was it organised by?`,
          `Where were the tickets sold?`,
        ],
      },
    ],
  },
  4: {
    unit: "Unit 8",
    id: "FP8-WB-demo-2023-U8-P53-E4",
    // audio: "Audios/2.05.mp3",
    exerciseKey: "img/FriendsPlus/Page53/key/E4answerKey.png",
    video: "",
    // recorder: true,
    component: T6v2,
    maxLength: 40,
    inputSize: 370,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      // textAlign: "left",
      fontSize: "22px",
      // fontWeight: "bold",
      borderStyle: "2px dotted",
    },
    titleQuestion: [
      {
        num: "4",

        title: `
        Complete the dialogue with questions using the present and past passive.
									`,
        color: "rgb(28,190,215)",
        star: 2,
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <div style='line-height:45px; font-size: 22px'>
          <div><b>Chris</b> <span style='margin-left:20px'>Do you fancy seeing the latest Leo Turner film? It’s on at the cinema tonight.</span></div>
          <div><b>Abby</b> <span style='margin-left:20px'>Oh! (What / it / call / ?) 
                <span style=''><b><u style='color: gray'>&ensp;What is it called? &ensp;</u></b></span></div>
          <div><b>Chris</b> <span style='margin-left:20px'>The Tenth Day, I think. Here’s a review. It says it’s a historical drama.</span></div>
          <div><b>Abby</b> <span style='margin-left:20px'><sup><b>1</b></sup> (When / it / set / ?)
                <span> # I’m not sure how I feel about historical films.</span></div>
          <div><b>Chris</b> <span style='margin-left:20px'>Er, the 1940s. It says here it’s based on a novel by Irving Brent.</span></div>
          <div><b>Abby</b> <span style='margin-left:20px'>Oh, I love his stuff. <sup><b>2</b></sup> (Who / the film / adapt / ?) #</div>
          <div><b>Chris</b> <span style='margin-left:20px'>Kyle Adams.</span></div>
          <div><b>Abby</b> <span style='margin-left:20px'><sup><b>3</b></sup> (it / direct / him / too / ?) # </span></div>
          <div><b>Chris</b> <span style='margin-left:20px'>Yes, it was. So, <sup><b>4</b></sup> (you / interest in coming / ?) #</span></div>
          <div><b>Abby</b> <span style='margin-left:20px'>Yes, I am! But I’m busy tonight.</span></div>


        </div>
				
									`,
        answer: [
          `When is it set?`,
          `Who was the film adapted by?`,
          `Was it directed by him, too?`,
          `are you interested in coming?`,
        ],
      },
    ],
  },
  5: {
    unit: "Unit 8",
    id: "FP8-WB-demo-2023-U8-P53-E5",
    audio: "",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page53/key/E5answerKey.png",
    component: T6v2,
    // maxLength: 1,
    inputSize: 450,
    textareaStyle: { width: "900px" },
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      // textAlign: 'center',
      fontSize: "20px",
      fontWeight: "bold",
      borderStyle: "dotted",
    },
    titleQuestion: [
      {
        num: "5",

        title: `
        Imagine you are going to interview a star about his / her latest film. Write five questions using the present and past passive.
									`,
        color: "rgb(28,190,215)",
        star: 3,
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
            <div style="display:flex">
              <img src="/img/FriendsPlus/Page53/E5/1.jpg" style='width:50%;'  >
              <div>
              <u><b style='color: gray'><i>Is the film based on a true story?</i></b></u> 
              #
              #
              #
              #
              #
              </div>
            </div>
				
									`,
        answer: [
          //`Where is the film set?`,
          //`Where was it filmed?`,
          //`Who was it directed by?`,
          //`Who was the script written by?`,
          //`Who were the costumes designed by?`,
          ``,
          ``,
          ``,
          ``,
          ``,
        ],
      },
    ],
  },
};

export default json;
