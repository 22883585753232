import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import DrawColor from "../../components/ExcerciseTypes/DrawColor";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit Starter",
    id: "FP8-WB-demo-2023-US-P7-E1",
    audio: "",
    video: "",
    exerciseKey: "/img/FriendsPlus/Page7/Key/E1answerKey.png",
    component: T6,
    checkDuplicated: true,
    inputSize: 130,
    maxLength: 6,
    stylesTextInput: {
      background: "none",
      // border: "none",
      borderBottom: "1px solid gray",
      fontSize: "21px",
      textAlign: "center",
      height: 25,
    },
    titleQuestion: [
      {
        num: "1",
        title: `Complete the table with the words.`,
        color: "rgb(28, 192, 217)",
        star: 1,
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <div style='margin-top: 20px; display: flex; gap: 30px;max-width:480px;margin-left:50px'>
        <div style='flex: 1; border-radius: 20px; padding: 20px 30px; border: 3px solid rgb(169,225,236);'>
          <div><span style="margin-right:20px">ate</span><span style="margin-right:20px">bought</span><span style="margin-right:20px">buy</span><span style="margin-right:20px">did</span><span style="margin-right:20px">didn't</span><span style="margin-right:20px">eat</span><span style="text-decoration:line-through">wanted</span></div>
          
        </div>
        
      </div>
      <div style='position: relative; font-size: 21px'>
        <div style='display:flex'>
            <img src="/img/FriendsPlus/Page7/E1/1.jpg" style='height:17cm; width: 19cm' >
        </div>
        <div style=" position: absolute; top: 121px; left: 47px; "><b>Regular <br>verbs (+ed)</b><br><br><b>Irregular<br>verbs</b></div>
        <div style=" position: absolute; top: 155px; left: 212px; ">I / You / He /<br> She / It / We /<br> You / They</div>
        <div style=" position: absolute; top: 147px; left: 391px; "><u style='color: gray'>&ensp;wanted&ensp;</u><br><br><sup>1</sup><input id='0'><br><sup>2</sup><input id='1'></div>
        <div style=" position: absolute; top: 153px; left: 615px; ">pizza.</div>

        <div style=" position: absolute; top: 355px; left: 47px; ">I / You / He / She / It /<br>We / You / They</div>
        <div style=" position: absolute; top: 355px; left: 291px; "><sup>3</sup><input id='2'></div>
        <div style=" position: absolute; top: 332px; left: 448px; ">&ensp;want<br><sup>4</sup><input id='3' width='115px'>/<br><sup>5</sup><input id='4' width='115px'></div>
        <div style=" position: absolute; top: 353px; left: 615px; ">pizza.</div>
        
        <div style=" position: absolute; top: 498px; left: 47px; "><sup>6</sup><input id='5'> you eat pizza?<br><br>What did you eat?</div>
        <div style=" position: absolute; top: 498px; left: 487px; ">Yes, we did. /<br> No, we didn’t.<br>We ate pizza.</div>
    
      </div>
        `,
        answer: [
          "ate / bought",
          "ate / bought",
          "didn't",
          "eat / buy",
          "buy / eat",
          "Did",
        ],
      },
    ],
  },
  2: {
    unit: "Unit Starter",
    id: "FP8-WB-demo-2023-US-P7-E2",
    audio: "",
    video: "",
    exerciseKey: "/img/FriendsPlus/Page7/Key/E2answerKey.png",
    component: T6,
    checkDuplicated: true,
    inputSize: 180,
    stylesTextInput: {
      fontSize: "22px",
      textAlign: "center	",
      background: "none",
      borderBottom: "1px solid black",
    },
    titleQuestion: [
      {
        num: "2",
        title: ` Complete the biography using the past 
        simple form of the verbs.`,
        color: "rgb(28, 192, 217)",
        star: 2,
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <div style='margin-top: 20px; display: flex; gap: 30px;max-width:580px;margin-left:50px'>
        <div style='flex: 1; border-radius: 20px; padding: 20px 30px; border: 4px solid rgb(169,225,236);'>
          <div><span style="margin-right:20px">be</span><span style="margin-right:20px">be</span><span style="margin-right:20px">live</span><span style="margin-right:20px">love</span><span style="margin-right:20px">move</span><span style="margin-right:20px">not join</span><span>start</span></div>
          <span style="margin-right:20px;margin-left:80px">teach</span><span style="margin-right:20px">want</span><span style="margin-right:20px">work</span><span>write</span>
        </div>
        
      </div>
      <div style='position: relative; max-width: 679px; font-size: 22px'>
        <div style='display:flex'>
            <div style='margin-right: 10px'>
            John Ronald Reuel Tolkien 
            <u style='color: gray'>&ensp;was&ensp;</u> a writer and 
            a university professor. He 
            <sup>1</sup><input id='0'> fantasy novels 
            such as The Hobbit and 
            The Lord of the Rings. Tolkien 
            was born in South Africa in 
            1892 and <sup>2</sup><input id='1'> to be 
            eighty-one years old. At the 
            age of three, he <sup>3</sup><input id='2'> with his family to England.  When the First World 
            War <sup>4</sup><input id='3'> ,
            </div>
            <img src="/img/FriendsPlus/Page7/E2/1.jpg" style='height:11cm;'  >
        </div>
        <div>
          Tolkien <sup>5</sup><input id='4'> a 
          university student. He <sup>6</sup><input id='5'> the army 
          like many of his friends because he <sup>7</sup><input id='6'>
          to finish his studies first. All his life, Tolkien 
          <sup>8</sup><input id='7'> reading and studying languages. 
          He managed to turn his hobby into a job. He 
          <sup>9</sup><input id='8'> on the Oxford English Dictionary
          and later <sup>10</sup><input id='9'> English Language and 
          Literature at the University of Oxford. 
        </div>
    
      </div>
        `,
        answer: [
          "wrote",
          "lived",
          "moved",
          "started",
          "was",
          "didn't join",
          "wanted",
          "loved",
          "worked",
          "taught",
        ],
      },
    ],
  },
  3: {
    unit: "Unit Starter",
    id: "FP8-WB-demo-2023-US-P7-E3",
    audio: "",
    video: "",
    exerciseKey: "/img/FriendsPlus/Page7/Key/E3answerKey.png",
    component: T6,
    inputSize: 800,
    stylesTextInput: {
      fontSize: "24px",
      textAlign: "left",
    },
    titleQuestion: [
      {
        num: "3",
        title: `
        Rewrite the sentences using the past 
        simple.
				`,
        color: "rgb(28, 192, 217)",
        star: 2,
      },
    ],
    isHiddenCheck: true,
    questions: [
      {
        title: `
          <div style='line-height: 45px'>
            <div>I have an exam today. <u style="margin-left:30px; color: gray">I had an exam today.</u></div>
            <div><strong style="margin-right:15px">1</strong>‘Do you drink this juice?’ ‘No, I don’t.’</div>
            <div>#</div>
            <div><strong style="margin-right:15px">2</strong>They often go to the cinema.</div>
            <div>#</div>
            <div><strong style="margin-right:15px">3</strong>We don’t send many text messages.</div>
            <div>#</div>
            <div><strong style="margin-right:15px">4</strong>‘Does Jake work there?’ ‘Yes, he does.’</div>
            <div>#</div>
            <div><strong style="margin-right:15px">5</strong>Melisa always wears expensive clothes.</div>
            <div>#</div>
          </div>
					
        `,
        answer: [
          "'Did you drink this juice?' 'No, I didn't.'",
          "They often went to the cinema.",
          "We didn't send many text messages.",
          "'Did Jake work there?' 'Yes, he did.'",
          "Melisa always wore expensive clothes.",
        ],
      },
    ],
  },
  4: {
    unit: "Unit Starter",
    id: "FP8-WB-demo-2023-US-P7-E4",
    audio: "",
    video: "",
    exerciseKey: "/img/FriendsPlus/Page7/Key/E4answerKey.jpg",
    component: T6,
    inputSize: 800,
    stylesTextInput: {
      fontSize: "24px",
      textAlign: "left",
    },
    titleQuestion: [
      {
        num: "4",
        title: `
        Write questions about the information 
        in bold.
				`,
        color: "rgb(28, 192, 217)",
        star: 2,
      },
    ],
    isHiddenCheck: true,
    questions: [
      {
        title: `
          <div>
            <div><strong>Alex</strong> told me. <strong>Who told you?</strong></div>
            <div><strong style="margin-right:15px">1</strong><strong>We</strong> ate all the pasta.</div>
            <div>#</div>
            <div><strong style="margin-right:15px">2</strong> We saw a <strong>boring film.</strong></div>
            <div>#</div>
            <div><strong style="margin-right:15px">3</strong> Olivia went <strong>home.</strong></div>
            <div>#</div>
            <div><strong style="margin-right:15px">4</strong> <strong>Mum</strong> made this soup</div>
            <div>#</div>
            <div><strong style="margin-right:15px">5</strong> Victor wrote <strong>that card.</strong></div>
            <div>#</div>
          </div>
					
        `,
        answer: [
          "Who ate all the pasta?",
          "What did you see?",
          "Where did Olivia go?",
          "Who made this soup?",
          "What did Victor write?",
        ],
      },
    ],
  },
  5: {
    unit: "Unit Starter",
    id: "FP8-WB-demo-2023-US-P7-E5",
    video: "",
    exerciseKey: "",
    component: T6v2,
    hideBtnFooter: true,
    stylesTextInput: {
      background: "transparent",
      borderBottom: "1px dotted #ccc",
      fontSize: "24px",
      textAlign: "center",
    },
    inputSize: 230,
    titleQuestion: [
      {
        num: "5",
        title: `
        Imagine you are interviewing a painter, 
        writer or musician from the past. Write five 
        object and subject questions. Use the verbs and 
        your own ideas.
				`,
        color: "rgb(28, 192, 217)",
        star: 3,
      },
    ],
    questionImage: [],
    textareaStyle: { width: 1120 },
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <div style='margin-top: 20px; display: flex; gap: 30px;max-width:510px;margin-left:190px;margin-bottom:20px'>
        <div style='flex: 1; border-radius: 20px; padding: 20px 30px; border: 4px solid rgb(169,225,236);'>
          <div>
          <span style="margin-right:20px">become</span><span style="margin-right:20px">give</span><span style="margin-right:20px">go</span><span style="margin-right:20px">learn</span><span style="margin-right:20px">live</span><span>make</span></div>
          <span style="margin-right:20px">meet</span><span style="margin-right:20px">paint</span><span style="margin-right:20px">play</span><span style="margin-right:20px">teach</span><span style="margin-right:20px">win</span><span>write</span></div>
          
        </div>
        <div>
          <strong>Who gave you your first guitar?</strong>
          <textarea id="0" rows="5"></textarea>
        </div>
      </div>
        `,
        answer: [""],
      },
    ],
  },
};

export default json;
