import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  1: {
    unit: "Unit 2",
    id: "FP8-SB-demo-2023-U2-P29-E13",
    audio: "Audios/1-13 Friends Plus 8_WB.mp3",
    video: "",
    component: Circle_Write,
    exerciseKey: "img/FriendsPlus/Page36/E1/Key/E1answerKey.png",
    titleQuestion: [
      {
        num: "1",
        title: ` <headphone name="13"></headphone>&ensp;
				Read and listen to the web 
article. Choose the correct answer.
				`,
        color: "rgb(220,44,55)",
        star: 1,
      },
    ],
    question: {
      Write: {
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "5px",
          fontWeight: 600,
          // fontStyle: "italic",
          color: "",
          // fontSize: 27,
          borderRadius: "50%",
          // marginInline: '-1px',
        },
        selectWordStyle: { border: "3px solid rgb(0, 173, 238)" },
        limitSelect: 1,
        listWordsStyle: {},
        listWords: [`a <br/> b <br/> c`],
        answers: ["0-8"],
        initialValue: [],
      },
      Layout: `
			<div style='display: flex;'>
			<img src="img/FriendsPlus/Page36/E1/1.jpg" style="width: 16cm; height: 24cm; margin-right: 10px"/>
		 
   
      
			<div style="margin-top: 5px; display: flex; flex gap: 20px; flex-direction: column; width: 300px">
			  <div style="font-weight: bold; text-align: right;"></div>
			  <div style="display:inline-block; position:relative">
			  The article is about … . <br>
		  <input id='0' type='Circle' /> 
		  <div style="position:absolute;top:35px;left:50px">people in space now</div>
		  <div style="position:absolute;top:72px;left:50px">aliens visiting Earth</div>
		  <div style="position:absolute;top:112px;left:50px">space travel in the future</div>
			  </div>
			 
			</div>
			
		 
			</div>
		  </div>
			
			  `,
    },
  },
  2: {
    unit: "Unit 3",
    id: "FP8-SB-demo-2023-U3-P36-E2",
    exerciseKey: "img/FriendsPlus/Page36/E2/Key/E2answerKey.png",
    audio: "",
    video: "",
    component: T6v2,
    inputSize: 70,
    maxLength: 1,
    stylesTextInput: {
      textTransform: "uppercase",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "2",
        title: `
				Read the article again. Match 
paragraphs 1–3 with A–C.
				`,
        color: "rgb(220,44,55)",
        star: 2,
      },
    ],
    isHiddenCheck: true,
    questions: [
      {
        title: `
					<div style='position: relative;'>
					  <span><strong style="margin-rightL:30px">1</strong> # </span>
            <span style="margin-left:100px"><strong style="margin-rightL:30px">2</strong> # </span>
            <span style="margin-left:100px"><strong style="margin-rightL:30px">3</strong> # </span> <br>
            <div style='display: flex'>
            <img  src="img/FriendsPlus/Page36/E2/1.png" style='width: 15cm; height: 9cm'/>
            <img src="img/FriendsPlus/Page36/E1/1.jpg" style="width: 16cm; height: 24cm"/>
            </div>
           
            
					</div>
          
        `,
        answer: [`C`, `A`, `B`],
      },
    ],
  },
  3: {
    unit: "Unit 3",
    id: "FP8-SB-demo-2023-U3-P35-E1",
    exerciseKey: "img/FriendsPlus/Page36/E3/Key/E3answerKey.png",
    component: T6v2,
    inputSize: 150,
    maxLength: 10,
    stylesTextInput: {
      background: "transparent",
      border: "none",
      fontSize: "21px",
      borderBottom: "2px dotted rgb(204, 204, 204)",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "3",
        title: `
				Read the article again. Write <i>true, false</i> or <i>don't know</i> if the article doesn’t mention it.
				`,
        color: "rgb(220,44,55)",
        star: 2,
      },
    ],
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page36/E1/1.jpg" style="width: 16cm; height: 24cm"/>
				<div style='line-height: 40px; font-size: 21px'>
				<span>Space tourism is already very common.&ensp;<strong style="text-decoration:underline; color: gray">&ensp;false&ensp;</strong>  </span>
			
					<div style="display:flex;flex-direction:column">
					 <span><strong style="margin-right:30px">1</strong> Many people have already given money to 
           Virgin Galactic.#
				 </span>
					 <span><strong style="margin-right:30px">2</strong>Mars is known as the ‘red planet’. # </span>
					 <span><strong style="margin-right:30px">3</strong>A lot of experts think the Mars One project 
           won’t happen soon. #</span>
					 <span><strong style="margin-right:30px">4</strong>Most people don’t like the idea of travelling to 
           Mars. # </span>
					 <span><strong style="margin-right:30px">5</strong>Bas Lansdorp feels a mission to Mars is 
           impossible.# </span>
			
					
					</div>
          </div>
        `,
        answer: [`don't know`, `true`, `true`, `don't know`, `false`],
      },
    ],
  },
  4: {
    unit: "Unit 3",
    id: "FP8-SB-demo-2023-U3-P35-E1",
    exerciseKey: "img/FriendsPlus/Page36/E4/Key/E4answerKey.png",
    component: T6v2,
    inputSize: 800,
    stylesTextInput: {
      background: "none",
      border: "none",
      fontSize: "22px",
      borderBottom: "2px dotted rgb(204, 204, 204)",
    },
    titleQuestion: [
      {
        num: "4",
        title: `
        Answer the questions. Write complete sentences.
				`,
        color: "rgb(220,44,55)",
        star: 3,
      },
    ],
    isHiddenCheck: true,
    textareaStyle: {
      width: 800,
    },
    questions: [
      {
        title: `
				<div style='font-size: 22px; line-height: 60px'>
				<span>How long will people stay on Mars?  </span> <br>
        <strong style="text-decoration:underline; color: gray">&ensp;They’ll stay there for the rest of their lives.&ensp;</strong> 
			
					<div style="display:flex;flex-direction:column">
					 <span><strong style="margin-right:30px">1</strong> When do scientists think it will be possible for 
           a lot of people to visit space?
				 </span> #
					 <span><strong style="margin-right:30px">2</strong>What’s the main problem for people who want 
           to be space tourists now? </span> #
					 <span><strong style="margin-right:30px">3</strong>What does Richard Branson think will happen 
           to the price of space trips in the future?</span> #
					 <span><strong style="margin-right:30px">4</strong>How do you think the author feels about the 
           idea of moving to Mars? </span> #
					 <span><strong style="margin-right:30px">5</strong>If you get the chance to go into space, will you 
           go? Why / Why not? </span> #

					</div>
          </div>
        `,
        answer: [
          `Scientists think it will be possible in a decade or two.`,
          `It's extremely expensive.`,
          `He thinks it will become cheaper.`,
          `He's worried about not returning to Earth.`,
          `I will go because I want to see the Earth from space.`,
        ],
      },
    ],
  },
  5: {
    unit: "Unit 3",
    id: "FP8-SB-demo-2023-U3-P35-E1",
    exerciseKey: "img/FriendsPlus/Page36/E5/Key/E5answerKey.png",
    component: T6v2,
    inputSize: 180,
    maxLength: 14,
    stylesTextInput: {
      background: "none",
      border: "none",
      fontSize: "23px",
      borderBottom: "2px dotted rgb(204, 204, 204)",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "5",
        title: `
        VOCABULARY PLUS Complete the 
        sentences with the words.
				`,
        color: "rgb(220,44,55)",
        star: 2,
      },
    ],
    isHiddenCheck: true,
    textareaStyle: {
      width: 800,
    },
    questions: [
      {
        title: `
        <div style='font-size: 23px; line-height: 50px'>
        <img  src="img/FriendsPlus/Page36/E5/1.png"/ style='width: 15cm'> <br>
				
				<span>Computers <strong style="text-decoration:underline; color: gray">&ensp;process&ensp;</strong> information through 
        numbers.  </span> <br>
        
			
					<div style="display:flex;flex-direction:column">
					 <span><strong style="margin-right:30px">1</strong> A fire can # a forest. 
				 </span> 
					 <span><strong style="margin-right:30px">2</strong>On Mars, they will # the conditions 
           so that plants can grow. </span> 
					 <span><strong style="margin-right:30px">3</strong>At night, our eyes # to the dark.</span> 

					</div>
          </div>
        `,
        answer: [`destroy`, `manipulate`, `adapt`],
      },
    ],
  },
};

export default json;
