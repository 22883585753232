import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";

const json = {
  1: {
    unit: "Unit 7",
    id: "FP8-WB-demo-2023-U7-P47-E1",
    audio: "",
    video: "",
    recorder: true,
    exerciseKey: "img/FriendsPlus/Page47/key/E1answerKey.png",
    component: T6v2,
    stylesTextInput: {
      background: "transparent",
      border: "none",
      textAlign: "center",
      fontSize: "23px",
      fontWeight: "bold",
    },
    hintBox: [
      {
        src: [`̶i̶̶f̶`, `loved`, `rained`, `ran`, `would (x2) `, `wouldn't (x2)`],
        borderColor: "rgb(28,190,215)",
        width: 700,
      },
    ],

    isHiddenCheck: true,
    inputSize: 540,
    maxLength: 8,
    titleQuestion: [
      {
        num: "1",
        title: `
        Complete the table with the words.

				`,
        color: "#82D8E4",
        star: 1,
      },
    ],
    questions: [
      {
        title: ` <div>
        <div style='margin-top: 10px; display: flex; gap: 20px;'>
							<div>
							<hintBox id='0'></hintBox></div>
        </div>
					
              <div style=" display:flex; position: relative; padding-top:10px ">
              
              <div style="position: absolute; top:106px; left: 270px; "><input id='0' font-size='23px' width='120px' border-bottom='0px'></input></div>
              <div style=" position: absolute; top: 106px; left:486px; "><input id='1' font-size='23px' width='120px' border-bottom='0px'></input></div>
              <div style="position: absolute; top: 220px; left: 59px; "><input id='2' font-size='23px' width='120px' border-bottom='0px'></input></div>
              <div style=" position: absolute; top: 220px; left: 487px; "><input id='3' font-size='23px' width='120px' border-bottom='0px'></input></div>
              <div style=" position: absolute; top: 340px; left: 113px; "><input id='4' font-size='23px' width='120px' border-bottom='0px'></input></div>
              <div style=" position: absolute; top: 406px; left: 130px; "><input id='5' font-size='23px' width='120px' border-bottom='0px'></input></div>
              <div style=" position: absolute; top: 406px; left: 376px; "><input id='5' font-size='23px' width='120px' border-bottom='0px'></input></div>

              <div> <img style='width:84%' src='img/FriendsPlus/Page47/E1/1.jpg' /> </div>

          </div>
		 
        `,
        answer: [
          `ran`,
          `would`,
          `wouldn't`,
          `rained`,
          `loved`,
          `would`,
          `wouldn't`,
        ],
      },
    ],
  },
  3: {
    unit: "Unit 7",
    id: "FP8-WB-demo-2023-U7-P47-E3",
    audio: "",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page47/key/E3answerKey.png",
    component: T6v2,
    maxLength: 90,
    inputSize: 750,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      textAlign: "left",
      fontSize: "23px",
    },
    titleQuestion: [
      {
        num: "3",
        star: 2,

        title: `
        Write sentences that are true for you using the second conditional. Use short forms.  `,
        color: "#82D8E4",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `<div style='font-size: 23px'>
        you find a lost wallet / take it to the police <br>
         <b style='border-bottom:1px solid gray; color: gray'>If I found a lost wallet, I’d take it to the police ... </b>
        <div style='line-height:1.9'>
        <div style ='display:flex'>
        <b>1</b>
        <div style='margin-left:10px'>
        you go to the party / have fun <br><input/>
        </div>
        </div>
        <div style ='display:flex'>
        <b>2</b>
        <div style='margin-left:10px'>
        there is a charity event in your town / help out  <br><input/> 
        </div>
        </div>
        
    
        <div style ='display:flex;'>
        <b>3</b>
        <div style='margin-left:10px'>
        your friend moves to a different town / visit him   <br><input/>
        </div>
        </div>

        <div style ='display:flex'>
        <b>4</b>
        <div style='margin-left:10px'>
        your smartphone breaks / buy another one <br><input/>
        </div>
        </div>

        <div style ='display:flex;'>
        <b>5</b>
        <div style='margin-left:10px'>
        there is a new student in your class / talk to her <br><input/>
        </div>
        </div>

        </div>
        </div>
						`,
        answer: [
          `If I went to the party, I'd have fun.`,
          `If there was a charity event in my town, I'd help out.`,
          `If my friend moved to a different town, I'd visit him.`,
          `If my smartphone broke, I'd buy another one.`,
          `If there was a new student in my class, I'd talk to her.`,
        ],
      },
    ],
  },
  2: {
    unit: "Unit 7",
    id: "FP8-WB-demo-2023-U7-P47-E2",
    audio: "",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page47/key/E2answerKey.png",
    component: T6v2,
    maxLength: 20,
    inputSize: 250,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      textAlign: "center",
      fontSize: "23px",
    },
    titleQuestion: [
      {
        num: "2",
        star: 2,

        title: `
        Correct the words. `,
        color: "#82D8E4",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <div style='line-height:50px; font-size: 23px'>
        If there ̶w̶̶o̶̶u̶̶l̶̶d̶̶n̶̶t̶ ̶b̶̶e̶  so many wars, there would be less poverty. <b>weren’t</b>
        <div style ='display:flex'>
        <b>1</b>
        <div style='margin-left:10px'>
        They would feel better if they <b>would get</b>.more exercise.<input/>
        </div>
        </div>
        <div style ='display:flex'>
        <b>2</b>
        <div style='margin-left:10px'>
        What <b>did</b>you write about if you had your own blog? <input/>
        </div>
        </div>
        
    
        <div style ='display:flex;'>
        <b>3</b>
        <div style='margin-left:10px'>
        If the charity campaigned more, they <b>raised</b> more money. <input/>
        </div>
        </div>

        <div style ='display:flex'>
        <b>4</b>
        <div style='margin-left:10px'>
        She wouldn’t work as a police officer if she <b>wouldn’t be</b> brave. <input/>
        </div>
        </div>

        <div style ='display:flex;'>
        <b>5</b>
        <div style='margin-left:10px'>
        If I were you, I <b>was</b> more patient with people.<input/>
        </div>
        </div>

        <div style ='display:flex;'>
        <b>6</b>
        <div style='margin-left:10px'>
        If you <b>would have</b> more money, would you go travelling?<input/>
        </div>
        </div>
       
        </div>
			
						`,
        answer: [
          `got`,
          `would`,
          `would raise`,
          `wasn't | weren't`,
          `would be`,
          `had`,
        ],
      },
    ],
  },
  4: {
    unit: "Unit 7",
    id: "FP8-WB-demo-2023-U7-P47-E4",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page47/key/E4answerKey.png",
    component: T6v2,
    maxLength: 15,
    inputSize: 145,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      textAlign: "center",
      fontSize: "21px",
      fontWeight: "bold",
    },
    titleQuestion: [
      {
        num: "4",
        star: 2,
        title: `Complete the dialogue using the correct form of the verbs in brackets.`,
        color: "#82D8E4",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
		    <div style='line-height: 40px; font-size: 21px; display: flex; width: 900px'>
          <div style='margin-right: 25px'>
            <b>Joel</b><br><br>
            <b>Alba</b><br><br>
            <b>Joel</b><br><br>
            <b>Alba</b><br><br>
            <b>Joel</b><br>
            <b>Alba</b><br><br>
            <b>Joel</b><br><br>
            <b>Alba</b><br>
          </div>
          <div>
            <div>
            Have you heard of a website called 
            Bucket List? It’s for making a list of the 
            things that you want to do in your life.
            </div>
            <div>
            No. I always thought if I <u>&ensp;tried&ensp;</u> (try) 
            to write a bucket list, I <sup>1</sup># (find) it difficult.
            </div>
            <div>
            I guess it would depend on how long 
            your list was. If there <sup>2</sup># (be) only 
            a few activities, it <sup>3</sup># (be) easy.
            </div>
            <div>
            But if you <sup>4</sup># (can) have as many as you liked, that <sup>5</sup># (take) longer!
            </div>
            <div>
            Yeah, that’s true. Anyway, the site is great.
            </div>
            <div>
            I <sup>6</sup># (look) at it if I <sup>7</sup># (have) time. But I’m really busy. So, 
            what’s on your list? Skydiving? Climbing Mount Kilimanjaro?
            </div>
            <div>
            I’m not telling you! You <sup>8</sup># (see) it if you <sup>9</sup># (go) on the website!
            </div>
            <div>
            Oh, all right, then! Give me the address!
            </div>
          </div>
        </div>
							`,
        answer: [
          "'d find",
          "were",
          "would be",
          "could",
          "would take",
          "'d look",
          "had",
          "'d see",
          "went",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 7",
    id: "FP8-WB-demo-2023-U7-P47-E5",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "",
    component: T6v2,
    // inputSize: 1000,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      fontSize: "24px",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "5",
        star: 3,
        title: `Write your own bucket list with four things you’d like to do before you get old. Imagine how you’d feel and what would happen if you did the activities. `,
        color: "#82D8E4",
      },
    ],
    textareaStyle: { width: 1200 },
    // maxLength:10,
    questionImage: [],
    hideBtnFooter: true,
    questions: [
      {
        title: `

<div style='margin-top:10px;width:1200px;'>
 <b style='color: gray'>I’d love to dive at Australia’s Great Barrier Reef. I’d see hundreds of fish if I went there ...
 
 
 </b>
 <textarea id='0' rows='8'></textarea>

</div>

        `,
        answer: [""],
      },
    ],
  },
};

export default json;
