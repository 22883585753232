import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 8",
    id: "FP8-WB-demo-2023-U8-P55-E1",
    audio: "",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page55/key/E1answerKey.png",
    component: T6v2,
    textareaStyle: { width: "730px" },
    // maxLength: 1,
    inputSize: 500,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      textAlign: "center",
      fontSize: "24px",
      // fontWeight: "bold",
      borderStyle: "dotted",
    },
    titleQuestion: [
      {
        num: "1",
        star: 1,
        title: `
        Look at the pictures of a festival. What type of festival do you think it is?
											`,
        color: "#26B467",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
       <div style='display:flex'>
       
       <img src="img/FriendsPlus/Page55/E1/1.jpg"  />
       <div style='margin-left:20px'>#</div>
       </div>

						
											`,
        answer: ["a literature festival"],
      },
    ],
  },
  2: {
    unit: "Unit 8",
    id: "FP8-WB-demo-2023-U8-P55-E2",
    audio: "",
    video: "",
    recorder: true,
    exerciseKey: "img/FriendsPlus/Page55/Key/E2answerKey.png",
    component: T6v2,
    stylesTextInput: {
      background: "none",
      borderBottom: "2px dotted black",
      textAlign: "center",
      fontSize: "20px",
      height: 28,
    },
    inputSize: 320,
    isHiddenCheck: true,

    maxLength: 50,
    titleQuestion: [
      {
        num: "2",
        title: `
        Read the review in exercise 3 and complete the middle column of the table.
				`,
        color: "#26B467",
        star: 2,
      },
    ],
    questions: [
      {
        title: ` <div style='font-size: 20px; position: relative; display: flex'>
       
        <img style="width:16cm; height:18cm; margin-top: 20px" src='img/FriendsPlus/Page55/E2/1.jpg' />
        <div style='position: relative'>
        <img style="width:20cm; height:16cm; margin-top: 15px" src='img/FriendsPlus/Page55/E2/2.jpg' />
        <div style='position: absolute; top: 130px; left: 181px'>#</div>  
        
        <div style='position: absolute; top: 175px; left: 181px'>#</div>
          <div style='position: absolute; top: 222px; left: 181px'>#</div>
          <div style='position: absolute; top: 273px; left: 181px'>#</div>
          <div style='position: absolute; top: 320px; left: 181px'>#</div>
          <div style='position: absolute; top: 369px; left: 181px'>#</div>
          <div style='position: absolute; top: 441px; left: 181px'>#</div>
          <div style='position: absolute; top: 498px; left: 181px'>#</div>
          <div style='position: absolute; top: 545px; left: 181px'>#</div>
          
        </div>

        </div>
        `,
        answer: [
          `literature`,
          `Hay-on-Wye`,
          `May`,
          `ten days`,
          `talks by authors, films, music, ...`,
          `250,000`,
          `30 years ago`,
          `father and son`,
          `relaxed atmosphere`,
        ],
      },
    ],
  },
  3: {
    unit: "Unit 8",
    id: "FP8-WB-demo-2023-U8-P55-E3",
    audio: "",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page55/key/E3answerKey.png",
    component: T6v2,
    maxLength: 25,
    inputSize: 150,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      textAlign: "center",
      fontSize: "20px",
      // fontWeight: "bold",
      borderStyle: "dotted",
    },
    titleQuestion: [
      {
        num: "3",

        title: `
        Read the review again and complete the sentences.
							`,
        color: "#26B467",
        star: 2,
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
      <div style='display:flex; font-size: 20px'>
        <img src="img/FriendsPlus/Page55/E2/1.jpg" style='width: 23cm; height: 19cm' />
        <div style='line-height:2; margin-left: 10px'>
          <div style='display:flex'><b>1.</b><span> It’s # Hay Festival.</span></div>
          <div style='display:flex'><b>2.</b><span> It’s held # Hay-on-Wye, # ten days # May.</span></div>
          <div style='display:flex'><b>3.</b><span> The festival is # literature.</span></div>
          <div style='display:flex'><b>4.</b><span> Speakers are # to give talks.</span></div>
          <div style='display:flex'><b>5.</b><span> It # around thirty years ago, # a father and son.</span></div>
          <div style='display:flex'><b>6.</b><span> The festival is a # of literature, films, music and children’s events.</span></div>
          <div style='display:flex'><b>7.</b><span> The thing I liked # about the festival was its relaxed atmosphere.</span></div>
        </div>
      </div>
    
		
							`,
        answer: [
          `called`,
          `in`,
          `for`,
          `every`,
          `about`,
          `invited`,
          `was started`,
          `by`,
          `mixture`,
          `most`,
        ],
      },
    ],
  },
  4: {
    unit: "Unit 8",
    id: "FP8-WB-demo-2023-U8-P55-E4",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page55/key/E4answerKey.png",
    component: T6v2,
    textareaStyle: { width: "900px", height: 250 },
    hideBtnFooter: true,
    titleQuestion: [
      {
        num: "4",
        star: 3,
        title: `
        Write your own review of a festival. Think and plan by completing the table in exercise 2
        with information for your festival. Include the phrases on this page in your review
					`,
        color: "#26B467",
      },
    ],
    questions: [
      {
        title: `
         <div style='line-height:2'>
            Include the following information:
            <ul>
              <li>the name, place and time of the festival</li>
              <li>what the festival is about</li>
              <li>the history of the festival</li>
              <li>what you like most about it</li>
            </ul>
          <textarea id='0' row='6 '></textarea>
        </div>
										`,
        answer: [],
      },
    ],
  },
  5: {
    unit: "Unit 8",
    id: "FP8-WB-demo-2023-U8-P55-E5",
    audio: "",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page55/key/E5answerKey.png",
    component: T6v2,
    // maxLength: 5,
    inputSize: 650,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      //   textAlign: 'center',
      fontSize: "24px",
      fontWeight: "bold",
    },
    titleQuestion: [
      {
        num: "5",

        title: `
		Order the words to make sentences. There is
one extra word in each sentence.
										`,
        color: "rgb(28,190,215)",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `

		<p style="font-size: 25px;margin-top:10px">
            <span style="font-weight: bold;">1</span> 
			watching TV / you / be / will / been / this time tomorrow / ? <br/>
			#
        </p>

		<p style="font-size: 25px;margin-top:20px">
            <span style="font-weight: bold;">2</span> 
			the match / won’t / don’t / we / be / watching / on Sunday <br/>
			#
        </p>

		<p style="font-size: 25px;margin-top:20px">
            <span style="font-weight: bold;">3</span> 
			will / staying / you / are / in a hotel / be / this time next week / ? <br/>
			#
        </p>

		<p style="font-size: 25px;margin-top:20px">
            <span style="font-weight: bold;">4</span> 
			 studying here / I / be / won’t / to / when I’m older <br/>
			#
        </p>

		<p style="font-size: 25px;margin-top:20px">
            <span style="font-weight: bold;">5</span> 
			humans / living / live / be / will / in the future / on Mars / ? <br/>
			#
        </p>

		

										`,
        answer: [
          "Will you be watching TV this time tomorrow? (been)",
          "We won't be watching the match on Sunday. (don't)",
          "Will you be staying in a hotel this time next week? (are)",
          "I won't be studying here when I'm older. (to)",
          "Will humans be living on Mars in the future? (live)",
        ],
      },
    ],
  },
  6: {
    unit: "Unit 8",
    id: "FP8-WB-demo-2023-U8-P55-E6",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "",
    component: T6v2,
    // maxLength: 1,
    inputSize: 500,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      fontSize: "24px",
    },
    titleQuestion: [
      {
        num: "6",
        title: `
        Work in pairs using the future continuous and words from the boxes. Ask and answer the questions about your future
        `,
        color: "rgb(28,190,215)",
        prefix: { icons: ["far fa-comment"], text: "USE IT!" },
      },
    ],
    questionImage: [],
    hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
		<div style="margin-left:200px;margin-top:40px"><img src="img/FriendsPlus/Page55/12.jpg"  /></div>
        `,
        answer: [],
      },
    ],
  },
};

export default json;
