import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";

const json = {
  1: {
    unit: "Unit 6",
    id: "FP8-WB-demo-2023-U6-P42-E1",
    audio: "Audios/1-15 Friends Plus 8_WB.mp3",
    video: "",
    component: Circle_Write,
    exerciseKey: "img/FriendsPlus/Page42/Key/E1answerKey.png",
    titleQuestion: [
      {
        num: "1",
        star: 1,
        title: `        <headphone name="15"></headphone >&ensp; Read and listen to the article about an unusual school. Choose the correct answer. `,
        color: "#DA3541",
      },
    ],
    question: {
      Write: {
        isHiddenCheck: true,
        maxLength: 11,
        inputStyle: { width: 100, fontSize: 19, textAlign: "center" },
        answers: [""],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: 5,
          borderRadius: "50%",
          fontWeight: "bold",
          borderColor: "transparent",
        },
        selectWordStyle: {
          padding: 5,
          border: "solid 2px",
          // borderColor: '#00aeef',
        },
        limitSelect: 1,
        listWords: [
          `A <br> B <br> C`, //1
        ],
        answers: ["0-4"],
        initialValue: [],
      },
      Layout: `  
      <div style='display:flex'>
      <div style='flex:1'>
      <img src='img/FriendsPlus/Page42/E1/1.jpg' style='width: 15cm'>
      </div>
      <div style ='flex:1;font-size:22px;line-height:2'>
      The best heading for the article is … . <br>
      <div style="display: flex;margin-left:40px">
           
        <div><input id='0' type='Circle'/></div>
        <div style='margin-left:20px'>
        Bad weather destroys Makoko school <br>
        New floating school in Makoko <br>
        Boat sent to help Makoko school
      </div>
      
      </div>
                
           
             
                       
      </div>



      `,
    },
  },
  2: {
    unit: "Unit 6",
    id: "FP8-WB-demo-2023-U6-P42-E2",
    audio: "",
    video: "",
    component: Circle_Write,
    exerciseKey: "img/FriendsPlus/Page42/Key/E2answerKey.png",
    titleQuestion: [
      {
        num: "2",
        star: 2,
        title: `Read the article again. Choose the correct words.  `,
        color: "#DA2D37",
      },
    ],
    question: {
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: 0,
          // border: "none",
          border: "2px solid #000000", // Sửa giá trị border thành "1px solid #000000"
          borderRadius: "50%",
          borderColor: "transparent",
          color: "#000000",
        },
        selectWordStyle: {
          padding: 5,
          borderColor: "#00aeef",
        },

        limitSelect: 1,
        listWords: [
          "wet / wealthy / windy", //0
          "use / create / avoid", //1
          "expensive / ecological / local", //2
          "Solar_panels / Plastic_barrels / Local_materials", //3
          "classrooms / plants / floors", //4
        ],
        answers: ["0-4", "1-8", "2-0", "3-0", "4-8"],
        initialValue: [],
      },
      Layout: `
       <div style='display:flex'>
       <div style='flex:1'>
       <img src='img/FriendsPlus/Page42/E1/1.jpg' style='max-width:100%'>
       </div>
       <div style ='flex:1;font-size:22px;line-height:2'>
       Adeyemi is<b>is visiting /<span style="display: inline-block; border: 2px solid black; border-radius: 50%; padding: 4px;">is from</span>/ hasn’t been to</b> Nigeria.  
       <div><b>1</b>&ensp;Makoko is not a very  <b><input id='0' type='Circle' /> </b> neighbourhood. </div>
       <div><b>2</b>&ensp;Adeyemi’s design helped to  <b><input id='1' type='Circle' /> </b> waste. </div>
       <div><b>3</b>&ensp;They didn’t use   <b><input id='2' type='Circle' /> </b>materials to build the school. </div>
       <div><b>4</b><b>&ensp;<input id='3' type='Circle' /></b>  provide power for the school.  </div>
       <div><b>5</b>&ensp;The school has got three  <b><input id='4' type='Circle' />.</b>  </div>
       </div>
       
       </div>


      `,
    },
  },
  3: {
    unit: "Unit 6",
    id: "FP8-WB-demo-2023-U6-P42-E3",
    audio: "",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page42/key/E3answerKey.png",
    component: T6v2,
    // maxLength: 6,
    inputSize: 850,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      textAlign: "left",
      fontSize: "22px",
    },
    titleQuestion: [
      {
        num: "3",
        star: 2,
        title: `
        Read the article again and write <i>true</i> or <i>false</i>. Correct the false sentences. 
				`,
        color: "#DA2D37",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <div style='line-height:50px; font-size: 22px'>
        <div>The new floating school isn’t safe.<br>
        <b style='border-bottom:1px solid gray; color: gray'>False. The new school is safe in extreme weather conditions.</b>
        </div>
        <div style ='display:flex'>
        <b>1</b>
        <div style='margin-left:10px'>
        Makoko is a city on a lake.  <br> <input/>
        </div>
        </div>
        <div style ='display:flex'>
        <b>2</b>
        <div style='margin-left:10px'>
        Weather and water levels aren’t a problem.   <br> <input/> 
        </div>
        </div>
        
    
        <div style ='display:flex;'>
        <b>3</b>
        <div style='margin-left:10px'>
        People from Makoko built the school.   <br> <input/>
        </div>
        </div>
        <div style ='display:flex'>
        
        <b>4</b>
        <div style='margin-left:10px'>
        The school has got outside space for playing. <br> <input/>
        </div>
        </div>
       
        </div>
			
				
				`,
        answer: [
          `False. Makoko is a neighbourhood by the ocean in Nigeria's largest city.`,
          `False. Water levels change a lot and waves destroy buildings.`,
          `True`,
          `False. The school has a play area on one of the floors.`,
        ],
      },
    ],
  },
  4: {
    unit: "Unit 6",
    id: "FP8-WB-demo-2023-U6-P42-E4",
    audio: "",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page42/key/E4answerKey.png",
    component: T6v2,
    // maxLength: 6,
    inputSize: 800,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      textAlign: "left",
      fontSize: "22px",
    },
    titleQuestion: [
      {
        num: "4",
        star: 3,
        title: `
        Answer the questions. Write complete sentences. 
				`,
        color: "#DA2D37",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <div style=' font-size: 22px'>
        <div>Why can’t the students go to lessons in the old primary school? <br>
        <b style='border-bottom:1px solid black'>They can’t use the old primary school because bad weather damaged it.</b>
        </div>
        <div style ='display:flex; line-height:45px;'>
        <b>1</b>
        <div style='margin-left:10px'>
        How does the building float on water?  <br> <input/>
        </div>
        </div>
        <div style ='display:flex; line-height:45px;'>
        <b>2</b>
        <div style='margin-left:10px'>
        How will the school stay up in the wind?    <br> <input/> 
        </div>
        </div>

        <div style ='display:flex; line-height:45px;'>
        <b>3</b>
        <div style='margin-left:10px'>
        How is the building ecological?   <br> <input/>
        </div>
        </div>

        <div style ='display:flex; line-height:45px;'>
        <b>4</b>
        <div style='margin-left:10px'>
        How will the school help the community?  <br> <input/>
        </div>
        </div>

        <div style ='display:flex; line-height:45px;'>
        <b>5</b>
        <div style='margin-left:10px'>
        What do you think life is like in Makoko?  <br> <input/>
        </div>
        </div>
       
        </div>
			
				
				`,
        answer: [
          `There are empty barrels under the building that make it float.`,
          `It has a big square base that prevents the wind from pushing it over.`,
          `It uses recycled barrels and local materials. It also has solar panels.`,
          `The children will be able to get qualifications and have a better future.`,
          `Life is difficult because it is a poor community with dangerous weather.`,
        ],
      },
    ],
  },
  5: {
    unit: "Unit 6",
    id: "FP8-WB-demo-2023-U6-P42-E5",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page42/Key/E5answerKey.png",
    component: T6v2,
    inputSize: 200,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      fontSize: "23px",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "5",
        star: 2,
        title: `
      VOCABULARY PLUS Complete the sentences with the words.

      `,
        color: "#DA2D37",
      },
    ],
    maxLength: 15,
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <div class="container">
			<div style=" width:800px;border: 1px solid red; display: flex; justify-content:center;padding: 10px 20px; border-radius: 40px">
			<div style="margin-right:20px"><span>challenging</span></div>
			<div style="margin-right:20px"><span>creative</span> </div>
			<div style="margin-right:20px"><span>historic</span></div>
      <div style="margin-right:20px"><s>ordinary</s></div>
			<div style="margin-right:20px"><span>unique</span></div>   
      </div>
<div style='line-height: 60px; margin-top:10px; font-size: 23px'>
This building is not <i><u style='color: gray'><b>&ensp;ordinary&ensp;</b></u></i> .It’s unusual.<br>
<b>1</b>&ensp;He loves art. He’s very <input/>   <br>
<b>2</b>&ensp;It isn’t like anything else. It’s <input/>   <br>
<b>3</b>&ensp;SThe book is  <input/>  . It’s hundreds of years old.  <br>
<b>4</b>&ensp;Is the work easy, or is it   <input/> ? <br>
</div>

        `,
        answer: ["creative", "unique", "historic", "challenging"],
      },
    ],
  },
};

export default json;
