import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
// import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/TypeIn/MatchDots";

const json = {
  1: {
    // Exercise num
    unit: "Unit Starter",
    id: "FP8-WB-demo-2023-US-P6-E1",
    // video: "",
    component: MatchDots,
    exerciseKey: "img/FriendsPlus/Page6/Key/E1answerKey.png",
    titleQuestion: [
      {
        num: "1",
        title: ` Find the irregular past simple forms of the verbs.`,
        color: "rgb(253,200,156)",
        star: 1,
      },
    ],
    // inputSize: 50,
    question: {
      DrawLines: {
        multipleLine: true,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "189px",
              left: "76px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //A
          {
            boxMatchStyle: {
              position: "absolute",
              top: "189px",
              left: "138px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //D
          {
            boxMatchStyle: {
              position: "absolute",
              top: "189px",
              left: "201px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //O
          {
            boxMatchStyle: {
              position: "absolute",
              top: "189px",
              left: "263px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //K
          {
            boxMatchStyle: {
              position: "absolute",
              top: "189px",
              left: "325px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //B
          {
            boxMatchStyle: {
              position: "absolute",
              top: "189px",
              left: "388px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //O
          {
            boxMatchStyle: {
              position: "absolute",
              top: "189px",
              left: "450px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //U
          {
            boxMatchStyle: {
              position: "absolute",
              top: "189px",
              left: "513px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //G
          {
            boxMatchStyle: {
              position: "absolute",
              top: "189px",
              left: "575px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //H
          {
            boxMatchStyle: {
              position: "absolute",
              top: "189px",
              left: "638px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //T

          //HÀNG 2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "245px",
              left: "76px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //E
          {
            boxMatchStyle: {
              position: "absolute",
              top: "245px",
              left: "138px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //I

          {
            boxMatchStyle: {
              position: "absolute",
              top: "245px",
              left: "388px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //G
          {
            boxMatchStyle: {
              position: "absolute",
              top: "245px",
              left: "450px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //C
          {
            boxMatchStyle: {
              position: "absolute",
              top: "245px",
              left: "513px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //P
          {
            boxMatchStyle: {
              position: "absolute",
              top: "245px",
              left: "575px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //E
          {
            boxMatchStyle: {
              position: "absolute",
              top: "245px",
              left: "638px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //G

          //HÀNG 3
          {
            boxMatchStyle: {
              position: "absolute",
              top: "301px",
              left: "76px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //P
          {
            boxMatchStyle: {
              position: "absolute",
              top: "301px",
              left: "138px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //D
          {
            boxMatchStyle: {
              position: "absolute",
              top: "301px",
              left: "201px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //O
          {
            boxMatchStyle: {
              position: "absolute",
              top: "301px",
              left: "263px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //K
          {
            boxMatchStyle: {
              position: "absolute",
              top: "301px",
              left: "325px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //B
          {
            boxMatchStyle: {
              position: "absolute",
              top: "301px",
              left: "388px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //O
          {
            boxMatchStyle: {
              position: "absolute",
              top: "301px",
              left: "450px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //U
          {
            boxMatchStyle: {
              position: "absolute",
              top: "301px",
              left: "513px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //G
          {
            boxMatchStyle: {
              position: "absolute",
              top: "301px",
              left: "575px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //H
          {
            boxMatchStyle: {
              position: "absolute",
              top: "301px",
              left: "638px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //T

          //HÀNG 4
          {
            boxMatchStyle: {
              position: "absolute",
              top: "356px",
              left: "76px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //P
          {
            boxMatchStyle: {
              position: "absolute",
              top: "356px",
              left: "138px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //D
          {
            boxMatchStyle: {
              position: "absolute",
              top: "356px",
              left: "201px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //O
          {
            boxMatchStyle: {
              position: "absolute",
              top: "356px",
              left: "263px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //K
          {
            boxMatchStyle: {
              position: "absolute",
              top: "356px",
              left: "325px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //B
          {
            boxMatchStyle: {
              position: "absolute",
              top: "356px",
              left: "388px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //O
          {
            boxMatchStyle: {
              position: "absolute",
              top: "356px",
              left: "450px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //U
          {
            boxMatchStyle: {
              position: "absolute",
              top: "356px",
              left: "513px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //G
          {
            boxMatchStyle: {
              position: "absolute",
              top: "356px",
              left: "575px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //H
          {
            boxMatchStyle: {
              position: "absolute",
              top: "356px",
              left: "638px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //T

          //HÀNG 5
          {
            boxMatchStyle: {
              position: "absolute",
              top: "412px",
              left: "76px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //P
          {
            boxMatchStyle: {
              position: "absolute",
              top: "412px",
              left: "138px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //D
          {
            boxMatchStyle: {
              position: "absolute",
              top: "412px",
              left: "201px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //O
          {
            boxMatchStyle: {
              position: "absolute",
              top: "412px",
              left: "263px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //K
          {
            boxMatchStyle: {
              position: "absolute",
              top: "412px",
              left: "325px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //B
          {
            boxMatchStyle: {
              position: "absolute",
              top: "412px",
              left: "388px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //O
          {
            boxMatchStyle: {
              position: "absolute",
              top: "412px",
              left: "450px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //U
          {
            boxMatchStyle: {
              position: "absolute",
              top: "412px",
              left: "513px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //G
          {
            boxMatchStyle: {
              position: "absolute",
              top: "412px",
              left: "575px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //H
          {
            boxMatchStyle: {
              position: "absolute",
              top: "412px",
              left: "638px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //T

          //HÀNG 6
          {
            boxMatchStyle: {
              position: "absolute",
              top: "468px",
              left: "76px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //P
          {
            boxMatchStyle: {
              position: "absolute",
              top: "468px",
              left: "138px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //D
          {
            boxMatchStyle: {
              position: "absolute",
              top: "468px",
              left: "201px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //O
          {
            boxMatchStyle: {
              position: "absolute",
              top: "468px",
              left: "263px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //K
          {
            boxMatchStyle: {
              position: "absolute",
              top: "468px",
              left: "325px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //B
          {
            boxMatchStyle: {
              position: "absolute",
              top: "468px",
              left: "388px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //O
          {
            boxMatchStyle: {
              position: "absolute",
              top: "468px",
              left: "450px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //U
          {
            boxMatchStyle: {
              position: "absolute",
              top: "468px",
              left: "513px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //G
          {
            boxMatchStyle: {
              position: "absolute",
              top: "468px",
              left: "575px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //H
          {
            boxMatchStyle: {
              position: "absolute",
              top: "468px",
              left: "638px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //T

          //HÀNG 7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "524px",
              left: "76px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //P
          {
            boxMatchStyle: {
              position: "absolute",
              top: "524px",
              left: "138px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //D
          {
            boxMatchStyle: {
              position: "absolute",
              top: "524px",
              left: "201px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //O
          {
            boxMatchStyle: {
              position: "absolute",
              top: "524px",
              left: "263px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //K
          {
            boxMatchStyle: {
              position: "absolute",
              top: "524px",
              left: "325px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //B
          {
            boxMatchStyle: {
              position: "absolute",
              top: "524px",
              left: "388px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //O
          {
            boxMatchStyle: {
              position: "absolute",
              top: "524px",
              left: "450px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //U
          {
            boxMatchStyle: {
              position: "absolute",
              top: "524px",
              left: "513px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //G
          {
            boxMatchStyle: {
              position: "absolute",
              top: "524px",
              left: "575px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //H
          {
            boxMatchStyle: {
              position: "absolute",
              top: "524px",
              left: "638px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //T

          //HÀNG 8
          {
            boxMatchStyle: {
              position: "absolute",
              top: "579px",
              left: "76px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //P
          {
            boxMatchStyle: {
              position: "absolute",
              top: "579px",
              left: "138px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //D
          {
            boxMatchStyle: {
              position: "absolute",
              top: "579px",
              left: "201px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //O
          {
            boxMatchStyle: {
              position: "absolute",
              top: "579px",
              left: "263px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //K
          {
            boxMatchStyle: {
              position: "absolute",
              top: "579px",
              left: "325px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //B
          {
            boxMatchStyle: {
              position: "absolute",
              top: "579px",
              left: "388px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //O
          {
            boxMatchStyle: {
              position: "absolute",
              top: "579px",
              left: "450px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //U
          {
            boxMatchStyle: {
              position: "absolute",
              top: "579px",
              left: "513px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //G
          {
            boxMatchStyle: {
              position: "absolute",
              top: "579px",
              left: "575px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //H
          {
            boxMatchStyle: {
              position: "absolute",
              top: "579px",
              left: "638px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //T

          //HÀNG 9
          {
            boxMatchStyle: {
              position: "absolute",
              top: "635px",
              left: "76px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //P
          {
            boxMatchStyle: {
              position: "absolute",
              top: "635px",
              left: "138px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //D
          {
            boxMatchStyle: {
              position: "absolute",
              top: "635px",
              left: "201px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //O
          {
            boxMatchStyle: {
              position: "absolute",
              top: "635px",
              left: "263px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //K
          {
            boxMatchStyle: {
              position: "absolute",
              top: "635px",
              left: "325px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //B
          {
            boxMatchStyle: {
              position: "absolute",
              top: "635px",
              left: "388px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //O
          {
            boxMatchStyle: {
              position: "absolute",
              top: "635px",
              left: "450px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //U
          {
            boxMatchStyle: {
              position: "absolute",
              top: "635px",
              left: "513px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //G
          {
            boxMatchStyle: {
              position: "absolute",
              top: "635px",
              left: "575px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //H
          {
            boxMatchStyle: {
              position: "absolute",
              top: "635px",
              left: "638px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //T

          //HÀNG 10
          {
            boxMatchStyle: {
              position: "absolute",
              top: "691px",
              left: "76px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //P
          {
            boxMatchStyle: {
              position: "absolute",
              top: "691px",
              left: "138px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //D
          {
            boxMatchStyle: {
              position: "absolute",
              top: "691px",
              left: "201px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //O
          {
            boxMatchStyle: {
              position: "absolute",
              top: "691px",
              left: "263px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //K
          {
            boxMatchStyle: {
              position: "absolute",
              top: "691px",
              left: "325px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //B
          {
            boxMatchStyle: {
              position: "absolute",
              top: "691px",
              left: "388px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //O
          {
            boxMatchStyle: {
              position: "absolute",
              top: "691px",
              left: "450px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //U
          {
            boxMatchStyle: {
              position: "absolute",
              top: "691px",
              left: "513px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //G
          {
            boxMatchStyle: {
              position: "absolute",
              top: "691px",
              left: "575px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //H
          {
            boxMatchStyle: {
              position: "absolute",
              top: "691px",
              left: "638px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //T

          //HÀNG 2 - W A S
          {
            boxMatchStyle: {
              position: "absolute",
              top: "245px",
              left: "201px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //O
          {
            boxMatchStyle: {
              position: "absolute",
              top: "245px",
              left: "263px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //K
          {
            boxMatchStyle: {
              position: "absolute",
              top: "245px",
              left: "325px",
              width: 54,
              height: 52,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //B
        ],
        answers: [
          "4-9",
          "18-68",
          "67-69",
          "59-89",
          "31-99",
          "22-62",
          "31-34",
          "26-56",
          "55-85",
          "81-85",
        ],
        initialValue: [],
      },
      Layout: `
      <img style='width:17cm; height: 3cm' src="img/FriendsPlus/Page6/E1/1.jpg" />
      <div><i style='font-size:21px; color: rgb(245,54,92)'>*Choose the first and last character of the word.</i></div>
			<div style='position: relative; text-align:left; margin-bottom: 100px; display: flex' >
       
				<img style='width:20cm; height: 16cm;' src="img/FriendsPlus/Page6/E1/2.jpg" />    
				
			</div>  
			  <input id='0' type= 'boxMatch' />
			  <input id='1' type= 'boxMatch' />
			  <input id='2' type= 'boxMatch' />
			  <input id='3' type= 'boxMatch' />
        <input id='4' type= 'boxMatch' />
			  <input id='5' type= 'boxMatch' />
        <input id='6' type= 'boxMatch' />
			  <input id='7' type= 'boxMatch' />
        <input id='8' type= 'boxMatch' />
        <input id='9' type= 'boxMatch' />
        
			  <input id='10' type= 'boxMatch' />
			  <input id='11' type= 'boxMatch' />
			  <input id='12' type= 'boxMatch' />
			  <input id='13' type= 'boxMatch' />
        <input id='14' type= 'boxMatch' />
			  <input id='15' type= 'boxMatch' />
        <input id='16' type= 'boxMatch' />
        <input id='97' type= 'boxMatch' />
			  <input id='98' type= 'boxMatch' />
        <input id='99' type= 'boxMatch' />
			  
        <input id='17' type= 'boxMatch' />
			  <input id='18' type= 'boxMatch' />
			  <input id='19' type= 'boxMatch' />
			  <input id='20' type= 'boxMatch' />
        <input id='21' type= 'boxMatch' />
			  <input id='22' type= 'boxMatch' />
        <input id='23' type= 'boxMatch' />
			  <input id='24' type= 'boxMatch' />
        <input id='25' type= 'boxMatch' />
        <input id='26' type= 'boxMatch' />

        <input id='27' type= 'boxMatch' />
			  <input id='28' type= 'boxMatch' />
			  <input id='29' type= 'boxMatch' />
			  <input id='30' type= 'boxMatch' />
        <input id='31' type= 'boxMatch' />
			  <input id='32' type= 'boxMatch' />
        <input id='33' type= 'boxMatch' />
			  <input id='34' type= 'boxMatch' />
        <input id='35' type= 'boxMatch' />
        <input id='36' type= 'boxMatch' />

        <input id='37' type= 'boxMatch' />
			  <input id='38' type= 'boxMatch' />
			  <input id='39' type= 'boxMatch' />
			  <input id='40' type= 'boxMatch' />
        <input id='41' type= 'boxMatch' />
			  <input id='42' type= 'boxMatch' />
        <input id='43' type= 'boxMatch' />
			  <input id='44' type= 'boxMatch' />
        <input id='45' type= 'boxMatch' />
        <input id='46' type= 'boxMatch' />

        <input id='47' type= 'boxMatch' />
			  <input id='48' type= 'boxMatch' />
			  <input id='49' type= 'boxMatch' />
			  <input id='50' type= 'boxMatch' />
        <input id='51' type= 'boxMatch' />
			  <input id='52' type= 'boxMatch' />
        <input id='53' type= 'boxMatch' />
			  <input id='54' type= 'boxMatch' />
        <input id='55' type= 'boxMatch' />
        <input id='56' type= 'boxMatch' />

        <input id='57' type= 'boxMatch' />
			  <input id='58' type= 'boxMatch' />
			  <input id='59' type= 'boxMatch' />
			  <input id='60' type= 'boxMatch' />
        <input id='61' type= 'boxMatch' />
			  <input id='62' type= 'boxMatch' />
        <input id='63' type= 'boxMatch' />
			  <input id='64' type= 'boxMatch' />
        <input id='65' type= 'boxMatch' />
        <input id='66' type= 'boxMatch' />

        <input id='67' type= 'boxMatch' />
			  <input id='68' type= 'boxMatch' />
			  <input id='69' type= 'boxMatch' />
			  <input id='70' type= 'boxMatch' />
        <input id='71' type= 'boxMatch' />
			  <input id='72' type= 'boxMatch' />
        <input id='73' type= 'boxMatch' />
			  <input id='74' type= 'boxMatch' />
        <input id='75' type= 'boxMatch' />
        <input id='76' type= 'boxMatch' />

        <input id='77' type= 'boxMatch' />
			  <input id='78' type= 'boxMatch' />
			  <input id='79' type= 'boxMatch' />
			  <input id='80' type= 'boxMatch' />
        <input id='81' type= 'boxMatch' />
			  <input id='82' type= 'boxMatch' />
        <input id='83' type= 'boxMatch' />
			  <input id='84' type= 'boxMatch' />
        <input id='85' type= 'boxMatch' />
        <input id='86' type= 'boxMatch' />

        <input id='87' type= 'boxMatch' />
			  <input id='88' type= 'boxMatch' />
			  <input id='89' type= 'boxMatch' />
			  <input id='90' type= 'boxMatch' />
        <input id='91' type= 'boxMatch' />
			  <input id='92' type= 'boxMatch' />
        <input id='93' type= 'boxMatch' />
			  <input id='94' type= 'boxMatch' />
        <input id='95' type= 'boxMatch' />
        <input id='96' type= 'boxMatch' />
		  `,
    },
  },
  2: {
    unit: "Unit Starter",
    id: "FP8-WB-demo-2023-US-P6-E2",
    audio: "/Audios/1-02 Friends Plus 8_WB.mp3",
    video: "",
    exerciseKey: "/img/FriendsPlus/Page6/Key/E2answerKey.jpg",
    component: Circle_Write,
    stylesTextInput: {
      background: "transparent",
      border: "none",
      fontSize: "17px",
    },
    titleQuestion: [
      {
        num: "2",
        title: `
					<headphone name='02' src='/Audios/1-02 Friends Plus 8_WB.mp3'></headphone>&ensp; 
					Choose the word with a different vowel sound. Then listen and check.
				`,
        color: "rgb(249,162,95)",
        star: 2,
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    question: {
      Write: {
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          margin: "5 10",
          border: "none",
          borderColor: "transparent",
          borderRadius: "60%",
        },
        selectWordStyle: {
          padding: 5,
          border: "solid 3px",
          borderColor: "#00a8ec",
          margin: "-2px",
        },
        limitSelect: 1,
        listWords: [
          "saw|&emsp;|were|&emsp;|taught",
          "do|&emsp;|go|&emsp;|spoke",
          "had|&emsp;|made|&emsp;|ate",
          "left|&emsp;|were|&emsp;|went",
          "rode|&emsp;|bought|&emsp;|taught",
          "heard|&emsp;|were|&emsp;|hear",
        ],
        answers: ["0-4", "1-0", "2-0", "3-4", "4-0", "5-8"],
        initialValue: [],
      },
      Layout: /*html */ `
      <div style="width:800px">
      <div>
        made &emsp; gave &emsp; <span style=" border: 3px solid black;border-radius:70px;padding:5px ">have</span>
      </div>
      <div>
      <strong style="margin-right:15px">1</strong> <input id="0" type="Circle" />
      </div>
      <div>
      <strong style="margin-right:15px">2</strong> <input id="1" type="Circle" />
      </div>
      <div>
      <strong style="margin-right:15px">3</strong> <input id="2" type="Circle" />
      </div>
      <div>
      <strong style="margin-right:15px">4</strong> <input id="3" type="Circle" />
      </div>
      <div>
      <strong style="margin-right:15px">5</strong> <input id="4" type="Circle" />
      </div>
      <div>
      <strong style="margin-right:15px">6</strong> <input id="5" type="Circle" />
      </div>
      </div>
      
      `,
    },
  },
  3: {
    unit: "Unit Starter",
    id: "FP8-WB-demo-2023-US-P6-E3",
    audio: "",
    video: "",
    exerciseKey: "/img/FriendsPlus/Page6/Key/E3answerKey.jpg",
    component: T6v2,
    checkDuplicated: true,
    inputSize: 200,
    stylesTextInput: {
      fontSize: "24px",
      textAlign: "center	",
    },
    titleQuestion: [
      {
        num: "3",
        title: `Complete the answers with the correct 
        irregular past simple verbs.`,
        color: "rgb(249,162,95)",
        star: 2,
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
					<div>
            <div>Why did you do it?</div>
            <div>I <span style="font-weight:bold">did</span> it because she asked me to.</div>
          </div>
          <div>
            <div><strong style="margin-right:10px">1</strong>When did they last eat pizza?</div>
            <div style="margin-left:25px">They # pizza last weekend.</div>
          </div>
          <div>
            <div><strong style="margin-right:10px">2</strong>At what age did he first ride a horse?</div>
            <div style="margin-left:25px">He first # a horse at age nine.</div>
          </div>
          <div>
            <div><strong style="margin-right:10px">3</strong>When did you first hear that song?</div>
            <div style="margin-left:25px">We first # that song two years ago.</div>
          </div>
          <div>
            <div><strong style="margin-right:10px">4</strong>What did she make on Sunday?</div>
            <div style="margin-left:25px">She # a chocolate cake.</div>
          </div>
          <div>
            <div><strong style="margin-right:10px">5</strong>What did you buy at the shops?</div>
            <div style="margin-left:25px">I # some new clothes.</div>
          </div>
        `,
        answer: ["ate", "rode", "heard", "made", "bought"],
      },
    ],
  },
  4: {
    unit: "Unit Starter",
    id: "FP8-WB-demo-2023-US-P6-E4",
    audio: "",
    video: "",
    exerciseKey: "/img/FriendsPlus/Page6/Key/E4answerKey.png",
    component: T6v2,

    inputSize: 118,

    stylesTextInput: {
      fontSize: "24px",
      background: "transparent",
      borderBottom: "none",
      textAlign: "left",
    },
    titleQuestion: [
      {
        num: "4",
        title: `
				Complete the online message using the 
correct irregular past simple verbs.
				`,
        color: "rgb(249,162,95)",
        star: 2,
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
      <div style='position: relative; margin-left: 100px'>
        <div style='display:flex'>
            <img src="/img/FriendsPlus/Page6/E4/1.jpg" style='height:26cm;'  >
        </div>
        <div style=" position: absolute; top: 97px; left: 203px; "><input id='0' style="width:0px"></input></div>
        <div style=" position: absolute; top: 160px; left: 55px; "><input id='1' style="width:0px"></input></div>
        <div style=" position: absolute; top: 190px; left: 112px; "><input id='2' style="width:0px"></input></div>
        <div style=" position: absolute; top: 220px; left: 368px; "><input id='3' style="width:0px"></input></div>
        <div style=" position: absolute; top: 280px; left: 54px; "><input id='4' style="width:0px"></input></div>
        <div style=" position: absolute; top: 307px; left: 159px; "><input id='5' style="width:0px"></input></div>
        <div style=" position: absolute; top: 339px; left: 226px; "><input id='6' style="width:0px"></input></div>
        <div style=" position: absolute; top: 399px; left: 280px; "><input id='7' style="width:0px"></input></div>
        <div style=" position: absolute; top: 430px; left: 164px; "><input id='8' style="width:0px"></input></div>
        
        
    
      </div>
				`,
        answer: [
          "as",
          "ot",
          "ought",
          "aw",
          "aught",
          "ere",
          "poke",
          "eft",
          "ent",
        ],
      },
    ],
  },
  5: {
    unit: "Unit Starter",
    id: "FP8-WB-demo-2023-US-P6-E5",
    audio: "",
    video: "",
    component: T6v2,
    textareaStyle: { width: 1120 },
    inputSize: 800,
    stylesTextInput: {
      fontSize: "24px",
    },
    titleQuestion: [
      {
        num: "5",
        title: `
				When was the last time that you had 
a surprise? Write five to ten complete sentences 
that are true for you. Include the answers to these 
questions:
				`,
        color: "rgb(249,162,95)",
        star: 3,
      },
    ],
    questionImage: [],
    hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
          <div>
          <ul>
          <li>When was it?</li>
          <li>Who was there?</li>
          <li>What happened?</li>
          <li>How did you feel?</li>
          </ul>
          <strong>The last time I had a surprise was on my birthday. My
          parents planned a surprise party at my house. They
          made a cake and all my friends were there. We had fun 
          and I was really happy.</strong>
          <textarea id="0" rows="5"></textarea>
          </div>
        `,
        answer: [],
      },
    ],
  },
};

export default json;
