import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  4: {
    unit: "Unit 7",
    id: "FP8-SB-demo-2023-U7-P71-E4",
    audio: "Audios/2.21.mp3",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page71/key/E4answerKey.png",
    component: T6v2,
    // maxLength: 6,
    inputSize: 300,
    stylesTextInput: {
      //   background: 'transparent',
      //   border: 'none',
      //   borderBottom: '1px solid black',
      textAlign: "center",
      fontSize: "25px",
      fontWeight: "bold",
    },
    titleQuestion: [
      {
        num: "4",

        title: `
			<headphone name="2.21"></headphone >		
			Listen to four people Yana, Zara, Will and Mitchell talking about petitions they
      have just signed. Which verbs in exercise 1 do Yana, Zara and Mitchell use?
													`,
        color: "rgb(7,114,186)",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
		
    <h1 style="margin-top: 30px">Answers</h1>
    <div style="display: flex;flex-wrap: wrap; gap:30px">

      <div>
        <span style="font-size: 25px">Yana:#</span>
      </div>

      <div>
        <span style="font-size: 25px">Zara:#</span>
      </div>

      <div>
        <span style="font-size: 25px">Mitchell:#</span>
      </div>

    </div>
   

		
													`,
        answer: ["support, campaign", "end", "propose"],
      },
    ],
  },
  5: {
    unit: "Unit 7",
    id: "FP8-SB-demo-2023-U7-P71-E5",
    audio: "Audios/2.21.mp3",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page71/key/E5answerKey.png",
    component: T6v2,
    // maxLength: 6,
    inputSize: 150,
    stylesTextInput: {
      //   background: 'transparent',
      //   border: 'none',
      //   borderBottom: '1px solid black',
      textAlign: "center",
      fontSize: "25px",
      fontWeight: "bold",
    },
    titleQuestion: [
      {
        num: "5",

        title: `
			<headphone name="2.21"></headphone >		
			Watch or listen again and fill in each blank with ONE word.
													`,
        color: "rgb(7,114,186)",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <table style="border: 1px solid rgb(71,140,202);color:white;margin-top:30px">
          <thead style="background-color: rgb(125,167,216);text-align:center">
            <th style="border: 1px solid rgb(71,140,202);;padding:10px">Names</th>
            <th style="padding:10px">Petitions</th>
          </thead>
          <tbody>

            <tr style="color:black">
              <td style="border: 1px solid rgb(71,140,202);padding:10px">Yana</td>
              <td style="border: 1px solid rgb(71,140,202);padding:10px">An anti-<sup>1</sup># petition</td>
            </tr>

            <tr style="color:black">
              <td style="border: 1px solid rgb(71,140,202);padding:10px">Zara</td>
              <td style="border: 1px solid rgb(71,140,202);padding:10px">To end <sup>2</sup># fishing</td>
            </tr>

            <tr style="color:black">
              <td style="border: 1px solid rgb(71,140,202);padding:10px">Will</td>
              <td style="border: 1px solid rgb(71,140,202);padding:10px">To persuade local <sup>3</sup># to let <sup>4</sup># people live in empty houses</td>
            </tr>

            <tr style="color:black">
              <td style="border: 1px solid rgb(71,140,202);padding:10px">Mitchell</td>
              <td style="border: 1px solid rgb(71,140,202);padding:10px">To propose a new law to let people
              <sup>5</sup># from <sup>6</sup># on</td>
            </tr>



          </tbody>
        </table>
    
   

		
													`,
        answer: ["bullying", "shark", "council", "homeless", "vote", "sixteen"],
      },
    ],
  },
  6: {
    unit: "Unit 7",
    id: "FP8-SB-demo-2023-U7-P71-E6",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "",
    component: T6v2,
    // maxLength: 1,
    inputSize: 500,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      fontSize: "24px",
    },
    titleQuestion: [
      {
        num: "6",
        title: `
        Work in pairs. Use the words in exercise 1 and the key phrases to make
        suggestions for the problems below. Decide which two changes you would make.

        `,
        color: "rgb(7,114,186)",
        prefix: { icons: ["far fa-comment"], text: "USE IT!" },
      },
    ],
    questionImage: [],
    hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
        
        <div style="border: 2px solid rgb(7,114,186); display: flex; flex-wrap: wrap;justify-content:center; align-items:center; padding: 20px 10px 10px 40px; margin-top: 40px; border-radius: 40px;">
					  <p style="font-size: 25px; margin-right: 40px; font-weight:bold">an aim</p>
					  <p style="font-size: 25px; margin-right: 40px; font-weight:bold">a ban</p>
					  <p style="font-size: 25px; margin-right: 40px; font-weight:bold">a belief</p>
					  <p style="font-size: 25px; margin-right: 40px; font-weight:bold">a boycott</p>

					  <p style="font-size: 25px; margin-right: 40px; font-weight:bold">a campaign</p>
					  <p style="font-size: 25px; margin-right: 40px; font-weight:bold">a donation</p>
					  <p style="font-size: 25px; margin-right: 40px; font-weight:bold">an end</p>
					  <p style="font-size: 25px; margin-right: 40px; font-weight:bold">a proposal</p>
					  <p style="font-size: 25px; margin-right: 40px; font-weight:bold">a protest</p>

					  <p style="font-size: 25px; margin-right: 40px; font-weight:bold">a signature</p>
					  <p style="font-size: 25px; margin-right: 40px; font-weight:bold">a supporter</p>
					  <p style="font-size: 25px; margin-right: 40px; font-weight:bold">a volunteer</p>
		    </div>
        <div style="margin-left: 250px; margin-top:30px"><img src="img/FriendsPlus/Page71/10.jpg"></div>
        <div style="margin-left: 250px; margin-top:30px"><img src="img/FriendsPlus/Page71/11.jpg"></div>

        `,
        answer: [],
      },
    ],
  },
};

export default json;
