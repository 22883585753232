import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";

const json = {
  1: {
    unit: "Unit 7",
    id: "FP8-WB-demo-2023-U7-P46-E1",
    audio: "",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page46/key/E1answerKey.png",
    component: T6v2,
    maxLength: 16,
    inputSize: 180,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      textAlign: "center",
      fontSize: "22px",
    },
    titleQuestion: [
      {
        num: "1",
        star: 1,

        title: `
        Read and correct the <b>bold</b> adjective in each sentence.`,
        color: "#F37F27",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <div style='line-height:50px; font-size: 22px'>
		My neighbour is a very <b>sympathetic</b> person.
		<br>  He accepts annoying things without becoming angry. <span style='border-bottom:1px solid black'><b><i style='color: gray'>patient</i></b></span>
        <div style ='display:flex'>
        <b>1</b>
        <div style='margin-left:10px'>
        Philip is so <b>thoughtful</b>. He always plans things carefully.<input/>
        </div>
        </div>
        <div style ='display:flex'>
        <b>2</b>
        <div style='margin-left:10px'>
		The woman jumped into the water to save the child. I think that was <b>honest</b>.<input/>
        </div>
        </div>
        
    
        <div style ='display:flex;'>
        <b>3</b>
        <div style='margin-left:10px'>
		They always buy expensive presents. They’re very <b>heroic</b>.<input/>
        </div>
        </div>

        <div style ='display:flex'>
        <b>4</b>
        <div style='margin-left:10px'>
        He invited the new student to his birthday party.</b> He’s very <b>organised</b>.<input/>
        </div>
        </div>

        <div style ='display:flex;'>
        <b>5</b>
        <div style='margin-left:10px'>
        She’s very <b> patient</b>. She doesn’t need people to tell her how good she is.<input/>
        </div>
        </div>

        <div style ='display:flex;'>
        <b>6</b>
        <div style='margin-left:10px'>
		You’re a <b> generous </b>boy. You always tell the truth.<input/>
        </div>
        </div>

		<div style ='display:flex;'>
        <b>7</b>
        <div style='margin-left:10px'>
        People like to tell her their problems because she is so <b>confident</b>. <input/>
        </div>
        </div>
       
        </div>
			
						`,
        answer: [
          `organised`,
          `heroic`,
          `generous`,
          `thoughtful`,
          `confident`,
          `honest`,
          `sympathetic`,
        ],
      },
    ],
  },
  2: {
    unit: "Unit 7",
    id: "FP8-WB-demo-2023-U7-P46-E2",
    audio: "Audios/1-16 Friends Plus 8_WB.mp3",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page46/key/E2answerKey.png",
    component: T6v2,
    // maxLength: 6,
    inputSize: 150,
    maxLength: 14,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      textAlign: "left",
      fontSize: "23px",
      fontWeight: "bold",
    },
    titleQuestion: [
      {
        num: "2",
        star: 2,
        title: `&ensp;<headphone name="16"></headphone>&ensp;
		Read Freya’s conversation with her grandmother, Sue. Complete the adjectives. Then listen and check.`,
        color: "#F37F27",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
				
		<div style='line-height:45px; font-size: 23px'>
        <div style ='display:flex'>
        <b>Freya </b>
        <div style='margin-left:10px'>
		So, tell me about Grandad. How did you first meet? 
        </div>
        </div>
        <div style ='display:flex'>
        <b>Sue&ensp;&ensp;</b>
        <div style='margin-left:10px'>
		Well, one morning on my way to work, I dropped my purse. He saw it and returned it to me. So I immediately knew he was an honest man. 
        </div>
        </div>
        
    
        <div style ='display:flex;'>
        <b>Freya</b>
        <div style='margin-left:10px'>
		And he became a policeman after you got married, didn’t he? You need to be <sup>1</sup> <b>b</b><input/> to do a job like that.        </div>
        </div>

        <div style ='display:flex'>
        <b>Sue&ensp;&ensp;</b>
        <div style='margin-left:10px'>
		Yes. He often caught criminals. He was very <sup>2</sup> <b>h</b><input/> . He was also  <sup>3</sup> <b>f</b><input/> because he treated everyone equally.          </div>
        </div>

        <div style ='display:flex;'>
        <b>Freya</b>
        <div style='margin-left:10px'>
        And what was he like at home? 
        </div>
        </div>

        <div style ='display:flex;'>
        <b>Sue&ensp;&ensp;</b>
        <div style='margin-left:10px'>
		He was always giving me presents. He was a very <sup>4</sup> <b>g</b><input/> and  <sup>5</sup> <b>t</b><input/> man. He wasn’t very  <sup>6</sup> <b>p</b><input/> , though – he hated waiting for things!

        </div>
        </div>

		
       
        </div>
							`,
        answer: ["rave", "eroic", "air", "enerous", "houghtful", "atient"],
      },
    ],
  },
  3: {
    unit: "Unit 7",
    id: "FP8-WB-demo-2023-U7-P46-E3",
    audio: "Audios/1-17 Friends Plus 8_WB.mp3",
    video: "",
    component: Circle_Write,
    exerciseKey: "img/FriendsPlus/Page46/Key/E3answerKey.png",
    titleQuestion: [
      {
        num: "3",
        star: 2,
        title: `&ensp;<headphone name="17"></headphone>&ensp;
		Listen to the dialogue and choose the correct answers.`,
        color: "#F37F27",
      },
    ],
    question: {
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: 0,
          // border: "none",
          border: "2px solid #000000", // Sửa giá trị border thành "1px solid #000000"
          borderRadius: "50%",
          borderColor: "transparent",
          color: "#000000",
        },
        selectWordStyle: {
          padding: 5,
          borderColor: "#00aeef",
        },

        limitSelect: 1,
        listWords: [
          "historian / hero", //0
          "are / aren’t", //1
          "famous / ordinary", //2
          "website / book", //3
          "Thousands / Hundreds", //4
          "knows_about / has_met", //4
        ],
        answers: ["0-0", "1-4", "2-4", "3-0", "4-4", "5-0"],
        initialValue: [],
      },
      Layout: `
      <div style='font-size:22px;line-height:2.5'>
    The presenter says it isn’t easy to find positive <b>young people /<span style="display: inline-block; border: 2px solid black; border-radius: 50%; padding: 2px;">news stories</span></b>.
    <div><b>1</b>&ensp;Dr Hook is a <b><input id='0' type='Circle' /></b>.</div>
    <div><b>2</b>&ensp;She feels that in general we <b><input id='1' type='Circle' /></b> happy about what’s happening in the world.</div>
    <div><b>3</b>&ensp;Dr Hook would like to hear more stories about <b><input id='2' type='Circle' /></b> people.</div>
    <div><b>4</b>&ensp;Dr Hook talks about a <b><input id='3' type='Circle' /></b> with stories about positive role models.</div>
    <div><b>5</b>&ensp;<b><input id='4' type='Circle' /></b>of people have shared stories about their heroes.</div>
    <div><b>6</b>&ensp;The presenter says he <b><input id='5' type='Circle' /></b> Malala Yousafzai.</div>
</div>

       


      `,
    },
  },
  4: {
    unit: "Unit 7",
    id: "FP8-WB-demo-2023-U7-P46-E4",
    audio: "Audios/1-17 Friends Plus 8_WB.mp3",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page46/key/E4answerKey.png",
    component: T6v2,
    maxLength: 15,
    inputSize: 40,
    stylesTextInput: {
      background: "transparent",
      // border: 'none',
      textAlign: "center",
      fontSize: "24px",
      fontWeight: "bold",
    },
    titleQuestion: [
      {
        num: "4",
        star: 2,
        title: `&ensp;<headphone name="17"></headphone>&ensp;
		Listen again and complete the poster.`,
        color: "#F37F27",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
				
		<div>
      
					
		<div style=" display:flex; position: relative;">
		
		<div style=" position: absolute; top:167px; left: 27px; "><input id='0' font-size='20px' width='120px' border-bottom='0px'></input></div>
		<div style=" position: absolute; top: 285px; left: 186px; "><input id='1' font-size='20px' width='120px' border-bottom='0px'></input></div>
		<div style=" position: absolute; top: 326px; left: 92px; "><input id='2' font-size='20px' width='120px' border-bottom='0px'></input></div>
		<div style=" position: absolute; top: 366px; left: 194px; "><input id='3' font-size='20px' width='120px' border-bottom='0px'></input></div>
		<div style=" position: absolute; top: 406px; left: 275px; "><input id='4' font-size='20px' width='120px' border-bottom='0px'></input></div>
		<div style=" position: absolute; top: 435px; left: 108px; "><input id='5' font-size='20px' width='120px' border-bottom='0px'></input></div>
		<div> <img style='max-width:70%' src='img/FriendsPlus/Page46/E4/1.jpg' /> </div>

	</div>
			
							`,
        answer: ["twenty", "film", "drawing", "essay", "festival", "awards"],
      },
    ],
  },
  5: {
    unit: "Unit 7",
    id: "FP8-WB-demo-2023-U7-P46-E5",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "",
    component: T6v2,
    // inputSize: 1000,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      fontSize: "24px",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "5",
        star: 3,
        title: `Write three or four sentences about a person who has shown two or more of the qualities below. What did they do and what was the result? `,
        color: "#F37F27",
      },
    ],
    textareaStyle: { width: 1200 },
    // maxLength:10,
    questionImage: [],
    hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <div class="container" style="display: flex; justify-content: center; align-items: center;">
			<div style="display:flex;justify-content: center; align-items: center;width:600px;border: 1px solid rgb(209 210 212); background-color:rgb(209 210 212); display: flex; justify-content:center;padding: 10px 20px; border-radius: 40px">
			<div style="margin-right:20px"><span>heroic</span></div>
			<div style="margin-right:20px"><span>brave</span> </div>
			<div style="margin-right:20px"><span>kind</span></div>
      <div style="margin-right:20px"><span>IT patient
	  </span></div>
	  </div>
	  </div>


<div style='margin-top:10px;width:1200px;'>
 <b style='color: gray'>My dance teacher has been both patient and kind. She gave me extra classes and now I feel more confident ...
 
 </b>
 <textarea id='0' rows='8'></textarea>

</div>

        `,
        answer: [""],
      },
    ],
  },
};

export default json;
