import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";

const json = {
  1: {
    unit: "Unit 6",
    id: "FP8-WB-demo-2023-U6-P39-E1",
    audio: "",
    video: "",
    recorder: true,
    exerciseKey: "img/FriendsPlus/Page39/key/E1answerKey.png",
    component: T6v2,
    stylesTextInput: {
      background: "none",
      border: "none",
      textAlign: "center",
      fontSize: "23px",
      height: 28,
    },
    hintBox: [
      {
        src: [
          `able`,
          `can`,
          `can't`,
          `could`,
          `couldn't `,
          `to`,
          `was`,
          `weren't`,
          `will`,
          `won't`,
        ],
        borderColor: "rgb(28,190,215)",
        width: 800,
      },
    ],

    isHiddenCheck: true,

    maxLength: 8,
    titleQuestion: [
      {
        num: "1",
        title: `
        Complete the table with the words. There are three extra words.
				`,
        color: "rgb(28,190,215)",
        star: 1,
      },
    ],
    questions: [
      {
        title: ` <div>
        <div style='margin-top: 10px; display: flex; gap: 20px;'>
							<div>
							<hintBox id='0'></hintBox></div>
        </div>
					
              <div style=" display:flex; position: relative; padding-top:20px ">
              
              <div style=" position: absolute; top:142px; left: 447px; "><input id='0' width='120px'></input></div>
              <div style=" position: absolute; top: 190px; left: 349px; "><input id='1' width='120px'></input></div>
              <div style=" position: absolute; top: 220px; left: 222px; "><input id='2' width='120px'></input></div>
              <div style=" position: absolute; top: 333px; left: 302px; "><input id='3' width='120px'></input></div>
              <div style=" position: absolute; top: 368px; left: 202px; "><input id='4' width='120px'></input></div>
              <div style=" position: absolute; top: 405px; left: 469px; "><input id='5' width='120px'></input></div>
              <div> <img style='width:60%' src='img/FriendsPlus/Page39/E1/1.jpg' /> </div>

          </div>

        `,
        answer: [`can't`, `couldn't`, `Could`, `able`, `Will`, `won't`],
      },
    ],
  },
  2: {
    unit: "Unit 6",
    id: "FP8-WB-demo-2023-U6-P39-E2",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page39/key/E2answerKey.png",
    component: T6v2,
    maxLength: 90,
    inputSize: 850,
    stylesTextInput: {
      // background: "rgb(225,226,228)",
      fontSize: "22px",
      // borderButoom: '1px solid',
      textAlign: "left",
    },
    titleQuestion: [
      {
        num: "2",
        title: `
        Write sentences and questions using the correct form of <i>can, could</i> or <i>be able to</i>. 
        `,
        color: "rgb(28,190,215)",
        star: 2,
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <div style='line-height:45px; font-size: 22px'>
        <div>they / leave school / next year <br>
        <u style='color: gray'><b>They’ll be able to leave school next year. </b></u>
        </div>
        <div style ='display:flex'>
        <b>1</b>
        <div style='margin-left:10px'>
        Maria / not go to / her dance class yesterday <br> <input/>
        </div>
        </div>
        <div style ='display:flex'>
        <b>2</b>
        <div style='margin-left:10px'>
        you / come / to the concert tomorrow? Yes / I  <br> <input/> 
        </div>
        </div>
        
    
        <div style ='display:flex;'>
        <b>3</b>
        <div style='margin-left:10px'>
        my friends / not take / the drama class last year  <br> <input/>
        </div>
        </div>
        <div style ='display:flex'>
        
        <b>4</b>
        <div style='margin-left:10px'>
        Michael / not get into / college next September  <br> <input/>
        </div>
        </div>
        <div style ='display:flex'>
        <b>5</b>
        <div style='margin-left:10px'>
        you / do / this maths homework? No / I   <br> <input/> 
        </div>
        </div>
    
        <div style ='display:flex'>
        <b>6</b>
        <div style='margin-left:10px'>
        Tia / not find / the car keys   <br> <input/>
        </div>
        </div>
        </div>
        
        `,
        answer: [
          "Maria couldn't go to her dance class yesterday.",
          "Will you be able to come to the concert tomorrow? Yes, I will.",
          "My friends couldn't take the drama class last year.",
          "Michael won't be able to get into college next September.",
          "Can you do this maths homework? No, I can't.",
          "Tia can't find the car keys.",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 6",
    id: "FP8-WB-demo-2023-U6-P39-E3",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page39/Key/E3answerKey.png",
    component: Circle_Write,
    titleQuestion: [
      {
        num: "3",
        title: `
        Choose the correct words to complete what Josh’s grandma says about school.
			  `,
        color: "rgb(28,190,215)",
      },
    ],
    question: {
      Write: {
        isHiddenCheck: true,
        maxLength: 11,
        inputStyle: { width: 100, fontSize: 19, textAlign: "center" },
        answers: [""],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: 0,
          borderRadius: "50%",
          fontWeight: "bold",
          borderColor: "transparent",
        },
        selectWordStyle: {
          padding: 5,
          border: "solid 2px",
          // borderColor: '#00aeef',
        },
        limitSelect: 1,
        listWords: [
          `a <br> b <br> c`, //1
          `a <br> b <br> c`, //1
          `a <br> b <br> c`, //1
          `a <br> b <br> c`, //1
          `a <br> b <br> c`, //1
          `a <br> b <br> c`, //1
          `a <br> b <br> c`, //1
        ],
        answers: ["0-0", "1-8", "2-8", "3-4", "4-8", "5-0", "6-4"],
        initialValue: [],
      },
      Layout: `
      <div style='display:flex'>
    <div style='flex:1; flex-wrap: wrap'>
    
        <div style="display: flex;margin-left:40px">
        <div style ='margin-right:20px'>
        <b>1</b>&ensp;

        </div>
            <div><input id='0' type='Circle'/></div>
            <div style='margin-left:20px'>
                couldn’t <br>
                weren’t <br>
                will be able 
            </div>
        </div>
        <div style="display: flex;margin-left:40px">
        <div style ='margin-right:20px'>
        <b>2</b>&ensp;

        </div>
            <div><input id='1' type='Circle'/></div>
            <div style='margin-left:20px'>
                can <br>be able to <br>could
            </div>
        </div>

        <div style="display: flex;margin-left:40px">
        <div style ='margin-right:20px'>
        <b>3</b>&ensp;

        </div>
            <div><input id='2' type='Circle'/></div>
            <div style='margin-left:20px'>
                were able <br>can’t <br>could
            </div>
        </div>

        <div style="display: flex;margin-left:40px">
        <div style ='margin-right:20px'>
        <b>4</b>&ensp;

        </div>
            <div><input id='3' type='Circle'/></div>
            <div style='margin-left:20px'>
                can’t <br>won’t be able <br>will be
            </div>
        </div>
    </div>

    <div style='flex:1; flex-wrap: wrap'>
        

        <div style="display: flex;margin-left:40px">
        <div style ='margin-right:20px'>
        <b>5</b>&ensp;

        </div>
            <div><input id='4' type='Circle'/></div>
            <div style='margin-left:20px'>
                ’s able <br>able <br>can 
            </div>
        </div>

        <div style="display: flex;margin-left:40px">
        <div style ='margin-right:20px'>
        <b>6</b>&ensp;

        </div>
            <div><input id='5' type='Circle'/></div>
            <div style='margin-left:20px'>
                ’ll be able <br>could <br>can 		          
            </div>
        </div>

        <div style="display: flex;margin-left:40px">
        <div style ='margin-right:20px'>
        <b>7</b>&ensp;

        </div>
            <div><input id='6' type='Circle'/></div>
            <div style='margin-left:20px'>
                won’t be able <br>couldn’t <br>can’t
            </div>
        </div>
    </div>

    <div style='flex:2'>
        <img src='img/FriendsPlus/Page39/E3/1.jpg' style='max-width:100%'>
    </div>
</div>


      



        
      `,
    },
  },
  4: {
    unit: "Unit 6",
    id: "FP8-WB-demo-2023-U6-P39-E4",
    audio: "",
    video: "",
    // recorder: true,
    // exerciseKey: "img/FriendsPlus/Page38/key/E2answerKey.png",
    component: T6v2,
    // maxLength: 6,
    inputSize: 850,
    stylesTextInput: {
      // background: "rgb(225,226,228)",
      fontSize: "22px",
      // borderButoom: '1px solid',
      textAlign: "left",
    },
    titleQuestion: [
      {
        num: "4",
        title: `
        Write one or two sentences about these things. 
        `,
        color: "rgb(28,190,215)",
        star: 3,
      },
    ],
    questionImage: [],
    hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
    <div style='line-height:50px'>
    <div>things you can do now that you couldn’t do ten years ago<br>
     <b style='border-bottom:1px solid; color: gray'> Ten years ago, I couldn’t swim very well or ride a bike. Now I can do both. </b></div>
    <div style ='display:flex'>
    <b>1</b>
    <div style='margin-left:10px'>
    things you can do that your grandparents couldn’t when they were young 
 <br> <input/>
    </div>
    </div>
    <div style ='display:flex'>
    <b>2</b>
    <div style='margin-left:10px'>
    things you could do when you were younger that you can’t any more   <br> <input/>
    </div>
    </div>
    <div style ='display:flex'>
    <b>3</b>
    <div style='margin-left:10px'>
    things your brothers, sisters or friends can do that you can’t 
   <br> <input/>
    </div>
    </div>
    <div style ='display:flex'>
    <b>4</b>
    <div style='margin-left:10px'>
    things you’ll be able to do when you’re twenty-five 
    <br> <input/>
    </div>
    </div>
    </div>

   
        
        `,
        answer: [],
      },
    ],
  },
};

export default json;
