import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";

const json = {
  1: {
    // Exercise num
    unit: "Unit 7",
    id: "FP8-WB-demo-2023-U7-P67-E1",
    audio: "",
    video: "",
    component: T6v2,
    // totalInput: 5,
    textareaStyle: {
      width: 977,
      fontSize: 21,
      height: 80,
      marginTop: "-42px",
    },
    // inputSize: 900,
    stylesTextInput: {
      //   background: 'transparent',
      //   border: 'none',
      //   borderBottom: '1px solid black',
      // textAlign: "center",
      width: 700,
      fontSize: "22px",
      // fontWeight: "bold",
    },
    isHiddenCheck: true,
    exerciseKey: "img/FriendsPlus/Page67/key/E1answerKey.png",
    titleQuestion: [
      {
        num: "1",
        title: "Complete the sentences to report what people said.",
        color: "#4c3b98",
      },
    ],
    questions: [
      {
        title: `
        <div style='font-size: 21px; line-height: 37px; width: fit-content'>
          <div><b>1.</b>&ensp;‘It will be safer to buy a car than a motorbike.’
        
          <div style='margin-left: 30px'><span>She said to her brother that</span><input id='0' width='662px'/></div>
          
          </div>

          <div><b>2.</b>&ensp;‘The driver is parking across the entrance.’
          <div style='margin-left: 30px'><span>I told my father that</span> <input id='1' width='736px'/></div>
          </div>

          <div><b>3.</b>&ensp;‘Further action may be taken.’
          <div style='margin-left: 30px'><span>The policeman said that</span> <input id='2' width='702px'/></div>
          </div>

          <div><b>4.</b>&ensp;‘The election result is a victory for democracy.’
          <div style='margin-left: 30px'><span>The spokesman told the press that </span><input id='3' width='599px'/></div>
          </div>

          <div><b>5.</b>&ensp;‘There may be delays on the railways.’
          <div style='margin-left: 30px'><span>The man said that </span><input id='4' width='764px'/></div>
          </div>

          <div><b>6.</b>&ensp;‘His explanation is unconvincing.’
          <div style='margin-left: 30px'><span>The judge said that </span><input id='5' width='756px'/></div>
          </div>

          <div><b>7.</b>&ensp;‘When the snow falls, I can't see where to go.’
          <div style='margin-left: 30px'><span>My friend told me that </span><input id='6' width='728px'/></div>
          </div>

          <div><b>8.</b>&ensp;As it is getting darker, I begin to realise my life is in danger.’
          <div style='margin-left: 30px'><span>Tom said that</span><input id='7' width='821px'/></div>
          </div>

          <div><b>9.</b>&ensp;‘She takes my hands and shows me how to hold the golf club properly.’
          <div style='margin-left: 30px'><span>John told me that </span><input id='8' width='784px'/></div>
          </div>

          <div><b>10.</b>&ensp;‘As we are walking over the hills, the guide warns us where the path is dangerous.’<br>
          <div>The tourists said that</div><br>
          <span style=''> <textarea id='9' rows='2'></textarea></span>
          </div>
          
        </div>
												`,
        answer: [
          `it would be safer to buy a car than a motorbike.`,
          `the driver was parking across the entrance.`,
          `further action might be taken.`,
          `the election result was a victory for democracy.`,
          `there might be delays on the railways.`,
          `his explanation was unconvincing.`,
          `when the snow fell, he couldn't see where to go.`,
          `as it was getting darker, he began to realise his life was in danger.`,
          `she took his hands and showed him how to hold the golf club properly.`,
          `as they were walking over the hills, the guide warned them where the path was dangerous.`,
        ],
      },
    ],
  },
  2: {
    unit: "Unit 7",
    id: "FP8-WB-demo-2023-U7-P67-E2",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page67/key/E2answerKey.png",
    component: T6,
    textareaStyle: { width: 700 },
    inputSize: 850,
    stylesTextInput: {
      //   background: 'transparent',
      //   border: 'none',
      //   borderBottom: '1px solid black',
      // textAlign: "center",
      fontSize: "22px",
      // fontWeight: "bold",
    },
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: "2",
        title: `
        Write two true sentences for each second conditional sentence.

					`,
        color: "#4c3b98",
      },
    ],
    questions: [
      {
        title: `
      <div style='font-size: 22px; line-height: 45px'>
      If I wasn’t so tired, I’d walk there.<br>
      <u><b style='color: gray'>&ensp;I’m very tired.&ensp;</b></u><br>
      <u><b style='color: gray'>&ensp;I won’t walk there.&ensp;</b></u>
      
      <div><b>1.</b>&ensp; We would invite Ollie to the picnic if we had his mobile number.
      #
      #
      </div>

      <div style='margin-top:20px'><b>2.</b>&ensp; If my sister came home on time, Mum wouldn’t get angry with her.
      #
      #
      </div>

      <div style='margin-top:20px'><b>3.</b>&ensp; Evan would buy that tablet if he had enough money.
      #
      #
      </div>

      <div style='margin-top:20px'><b>4.</b>&ensp; If you didn’t work hard, you’d find maths very difficult.
      #
      </div>

      <div style='margin-top:20px'><b>5.</b>&ensp; If Deena was more generous, we’d be friends.
      #
      </div>

      <div style='margin-top:20px'><b>6.</b>&ensp; We’d all go out tonight if we weren’t busy.
      #
      </div>


      </div>
												`,
        answer: [
          // `We won't invite Ollie to the picnic. / We haven't invited Ollie to the picnic.`,
          // `We don't have got his mobile number. / We haven't got his mobile number.`,
          // `My sister comes home late. Mum gets angry with her. / My sister doesn't come home on time. Mum gets angry with her.`,
          // `My sister doesn't come home on time. Mum gets angry with her. / My sister comes home late. Mum gets angry with her.`,
          // `Evan won't buy that tablet. He doesn't have enough money. / Evan hasn't bought that tablet. He hasn't got enough money.`,
          // `Evan won't buy that tablet. He doesn't have enough money. / Evan hasn't bought that tablet. He hasn't got enough money.`,

          `We won't invite Ollie to the picnic. / We haven't invited Ollie to the picnic.`,
          `We don't have got his mobile number. / We haven't got his mobile number.`,
          `My sister comes home late. / My sister doesn't come home on time.`,
          `Mum gets angry with her.`,
          `Evan won't buy that tablet. / Evan hasn't bought that tablet.`,
          `He doesn't have enough money. / He hasn't got enough money.`,
          `You work hard. You don't find maths very difficult.`,
          `Deena isn't very generous. We're not friends.`,
          `We won't all go out tonight. We're busy tonight.`,
        ],
      },
    ],
  },
  3: {
    unit: "Unit 7",
    id: "FP8-WB-demo-2023-U7-P67-E3",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page67/key/E3answerKey.png",
    component: T6v2,
    maxLength: 20,
    inputSize: 250,
    stylesTextInput: {
      background: "transparent",
      border: "none",
      //   borderBottom: '1px solid black',
      textAlign: "center",
      fontSize: "22px",
      // fontWeight: "bold",
      borderBottom: "2px dotted",
    },
    titleQuestion: [
      {
        num: "3",

        title: `
					
        Complete the second conditional sentences using the correct form of the verbs in brackets.
											`,
        color: "#4c3b98",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
		<div style='line-height:45px; font-size: 22px'>
      <div>
      We ’d <b><u style='color: gray'>&ensp;finish&ensp;</u></b> (finish) this homework a lot faster if we <b><u style='color: gray'>&ensp;stopped&ensp;</u></b>(stop) watching TV!
      </div>
      
      <div style='display:flex'><b>1.</b>&ensp; <span>Emma # (have) more friends if she # (be) more sympathetic.</span></div>

      <div><b>2.</b>&ensp; <span>If I # (not have) so much work to do, I # (relax) a lot more.</span></div>

      <div><b>3.</b>&ensp; <span>What # (you / do) if I # (tell) you a secret?</span></div>

      <div style='display:flex'><b>4.</b>&ensp; <span>If we # (not have to) wear a school uniform, I # (spend) more money on clothes.</span></div>

      <div style='display:flex'><b>5.</b>&ensp; <span>I # (not know) what to say if I # (see) my favourite celebrity.</span></div>

      <div><b>6.</b>&ensp; <span>If you # (win) the prize, how # (you / celebrate)?</span></div>
        
    </div>
											`,
        answer: [
          `would have`,
          `was`,
          `didn't have`,
          `'d relax`,
          `would you do`,
          `told`,
          `didn't have to`,
          `'d spend`,
          `wouldn't know`,
          `saw`,
          `won`,
          `would you celebrate`,
        ],
      },
    ],
  },
  4: {
    unit: "Unit 7",
    id: "FP8-WB-demo-2023-U7-P67-E4",
    audio: "",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page67/key/E4answerKey.png",
    component: T6v2,
    maxLength: 15,
    inputSize: 150,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      borderBottom: "2px dotted",
      textAlign: "center",
      fontSize: "22px",
      // fontWeight: "bold",
    },
    titleQuestion: [
      {
        num: "4",

        title: `
					
        Correct the mistakes in bold.
											`,
        color: "#4c3b98",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
					<div style='line-height: 50px; font-size: 22px'>
            <div style='color: gray'>We saw the pickpockets but <b>them</b> ran away. <b><u style='color: gray'>&ensp;they&ensp;</u></b></div>
            
            <div><b>1.</b>&ensp; The offender tried to leave the country but the detectives caught <b>he</b>. #</div>

            <div><b>2.</b>&ensp; The police looked at the pictures and said ‘<b>Them</b> paintings here are stolen.’ #</div>

            <div><b>3.</b>&ensp; Teachers want to ban mobiles at school and we think that <b>these</b> plan is good. #</div>

            <div><b>4.</b>&ensp; Carla phoned earlier and told <b>I</b> about a seriousrobbery in the centre of town. #</div>



          </div>
						
											`,
        answer: ["him", "Those", "this", "me"],
      },
    ],
  },
};

export default json;
