import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";

const json = {
  1: {
    unit: "Unit 6",
    id: "FP8-WB-demo-2023-U6-P43-E1",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page43/Key/E1answerKey.png",
    component: T6v2,
    inputSize: 250,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      fontSize: "24px",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "1",
        star: 1,
        title: `
      Write the words in the order that we use them in essays. <br> Two words have got the same meaning.


      `,
        color: "#01AA50",
      },
    ],
    maxLength: 20,
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <div class="container">
			<div style=" width:800px;border: 1px solid green; display: flex; justify-content:center;padding: 10px 20px; border-radius: 40px">
			<div style="margin-right:20px"><span>finally</span></div>
			<div style="margin-right:20px"><span>firstly</span> </div>
			<div style="margin-right:20px"><span>lastly</span></div>
      <div style="margin-right:20px"><span>secondly
      </span></div>
      </div>
<div style='line-height:2.5;margin-top:10px'>
<b>1</b>&ensp; <input/>   <br>
<b>2</b>&ensp; <input/>   <br>
<b>3</b>&ensp;  <input/>  <br>
</div>
</div>


        `,
        answer: ["first", "secondly", "finally / lastly"],
      },
    ],
  },
  2: {
    unit: "Unit 6",
    id: "FP8-WB-demo-2023-U6-P43-E2",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page43/Key/E2answerKey.png",
    component: T6v2,
    inputSize: 180,
    checkUppercase: true,
    stylesTextInput: {
      background: "transparent",
      // border: 'none',
      fontSize: "22px",
      textAlign: "center",
      fontWeight: 600,
    },
    titleQuestion: [
      {
        num: "2",
        star: 2,
        title: `
      Complete the paragraph with three of the words in exercise 1.
      `,
        color: "#01AA50",
      },
    ],
    maxLength: 18,
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <img style="width:17cm; height:2.5cm; margin-bottom: 20px" src='img/FriendsPlus/Page43/E2/1.jpg' />
      <div id="example2" style="background-color:rgb(209,232,212);padding: 5px 10px; border: 0px solid; box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;; display: inline-block; text-align: left;">
        <div style="font-size: 22px; line-height: 2;">
          In my opinion, school uniforms are a good <br>
          thing for many reasons. <sup>1</sup> <input style=""/> , I believe everyone <br>
          should look smart at school. <sup>2</sup> <input style=""/> , if students have <br>
          to wear a uniform, everyone’s clothes are the same. <br>
          This stops students from looking richer or poorer<br>
          than others. <sup>3</sup> <input style=""/> , I think it encourages students<br>
          to obey the rules and feel part of the school community.
        </div>
      </div>
      
      
        `,
        answer: ["Firstly", "Secondly", "Finally | Lastly"],
      },
    ],
  },
  3: {
    unit: "Unit 6",
    id: "FP8-WB-demo-2023-U6-P43-E3",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page43/Key/E3answerKey.png",
    component: T6v2,
    inputSize: 200,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      fontSize: "23px",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "3",
        star: 2,
        title: `
      Replace the bold words with the phrases.
      `,
        color: "#01AA50",
      },
    ],
    maxLength: 16,
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <div class="container" style="display: flex; justify-content: center; align-items: center; ">
        <div style="display:flex; width: max-content; border: 1px solid green; padding: 10px 20px; border-radius: 40px;">
          <div style="margin-right: 20px;"><span>all in all</span></div>
          <div style="margin-right: 20px;"><span>as a result</span></div>
          <div style="margin-right: 20px;"><span>I feel</span></div>
          <div style="margin-right: 20px;"><span><s>in my opinion</s></span></div>
          <div style="margin-right: 20px;"><span>to conclude</span></div>
          <div style="margin-right: 20px;"><span>so</span></div>
        </div>
      </div>

      <div style='display:flex;margin-top:10px'>
      <div style='flex:1'>
      <div style='width:600px'>
      In the UK, students have to wear a uniform to most schools. But are school uniforms really a good idea? <br>
     <b>For me</b>, school uniforms are a bad idea. <sup>1</sup> <b>I think</b> most children aren’t comfortable when they have to wear
      formal clothes. <sup>2</sup> <b>Because</b> of this, they might enjoy school less, and <sup>3</sup> <b>then</b> they have more problems with their
      studies. Secondly, uniforms are usually more expensive than ordinary clothes. Some parents can’t afford them. <br>
      <sup>4</sup> <b>Considering these things</b>, uniform is unpopular and can cost too much. <sup>5</sup> <b>In the end</b>, I would prefer not to have uniform in schools. 

      
      </div>
      </div>
      <div style='flex:1'>
      <div style='line-height:3;margin-top:10px'>
&ensp;<b style='border-bottom:1px solid gray; color: gray'>In my opinion</b> <br>
<b>1</b>&ensp;<input/>   <br>
<b>2</b>&ensp;<input/>   <br>
<b>3</b>&ensp;<input/>  <br>
<b>4</b>&ensp;<input/>  <br>
<b>5</b>&ensp;<input/>  <br>

</div>
      </div>
      
      </div>
      
      




        `,
        answer: ["I feel", "As a result", "So", "All in all", "To conclude"],
      },
    ],
  },
  4: {
    unit: "Unit 6",
    id: "FP8-WB-demo-2023-U6-P43-E4",
    audio: "",

    video: "",
    // recorder: true,
    exerciseKey: "",
    component: T6v2,
    // maxLength: 6,
    inputSize: 200,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      textAlign: "center",
      fontSize: "24px",
    },
    titleQuestion: [
      {
        num: "4",
        star: 3,
        title: `
      Write an opinion essay with this title: <i>Should all secondary school students do work experience in the real world?</i> 
      `,
        color: "#01AA50",
      },
    ],
    textareaStyle: { width: 1000 },
    questionImage: [],
    hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
        Think about the following things:
		<ul>
    <li>Do any students in your school do work experience now?</li>
    <li>What types of work experience could you do? </li>
    <li>What are the advantages of work experience? </li>
    <li>Are there any problems with work experience? Use the essay in exercise 3 to help you. Include ordering expressions and the phrases on this page. </li>

    </ul>
    <textarea id='0' rows='7'></textarea>
				`,
        answer: [],
      },
    ],
  },
};

export default json;
