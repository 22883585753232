import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 8",
    id: "FP8-WB-demo-2023-U8-P56-E1",
    // audio: "Audios/2.08.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page56/key/E1answerKey.png",
    textareaStyle: {
      // background: 'none',
      width: 700,
      fontSize: 22,
      height: 130,
    },
    component: T6,
    recorder: true,
    hideBtnFooter: true,
    titleQuestion: [
      {
        num: "1",
        title: `
        Look at the photo below. What are they doing? Are you good at this?
						`,
        color: "#6A5EA1",
        prefix: { text: "Speaking" },
      },
    ],
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page56/E1/1.jpg" style='margin-left:150px' />
					<div style='margin-left: 150px'><textarea id='1' rows='3'></div>
											`,
        answer: [``],
      },
    ],
  },
  2: {
    unit: "Unit 8",
    id: "FP8-WB-demo-2023-U8-P56-E2",
    // audio: "Audios/2.08.mp3",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page56/key/E1answerKey.png",
    component: T6v2,
    // maxLength: 1,
    inputSize: 280,
    textareaStyle: { width: "500px" },
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      textAlign: "center",
      fontSize: "20px",
      fontWeight: "bold",
    },
    titleQuestion: [
      {
        num: "2",
        title: `
        Read a competition entry. What did Matt learn during his course?
											`,
        color: "#6A5EA1",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
					<div style='display:flex'>
          <img src="img/FriendsPlus/Page56/E1/1.jpg" style='width:45%' />
          <textarea id='0' row='6'></textarea>
          </div>
											`,
        answer: ["He learned to prepare desserts, cakes and ice cream."],
      },
    ],
  },
  3: {
    unit: "Unit 8",
    id: "FP8-WB-demo-2023-U8-P56-E3",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page56/key/E3answerKey.png",
    component: T6v2,
    // maxLength: 1,
    inputSize: 150,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      fontSize: "23px",
      textAlign: "center",
      // fontWeight: "bold",
      // border:'dotted',
    },
    titleQuestion: [
      {
        num: "3",
        title: `
        Read the competition entry again. Complete the sentences.
        `,
        color: "#6A5EA1",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
      <div style='line-height:2'>
        <div><b>1.</b>&ensp; You can win a trip to Rome when you write about an # course you did.</div>
        <div><b>2.</b>&ensp; During # , the teachers talked about what desserts to make that day.</div> 
        <div><b>3.</b>&ensp; Matt learned how to use different kitchen # like the ice cream machine.</div> 
        <div style='display:flex'><b>4.</b>&ensp; In the kitchen, some chefs wear # shirts and # trousers.</div> 
        <div><b>5.</b>&ensp; Matt tried to find out what makes a dessert #.</div>    


      </div>
        `,
        answer: [
          `amazing`,
          `breakfast`,
          `gadgets`,
          `baggy`,
          `patterned`,
          `delicious`,
        ],
      },
    ],
  },
  4: {
    unit: "Unit 8",
    id: "FP8-WB-demo-2023-U8-P56-E4",
    audio: "Audios/1-21 Friends Plus 8_WB.mp3",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page56/key/E4answerKey.png",
    component: Circle_Write,
    // maxLength: 1,
    inputSize: 500,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      fontSize: "24px",
    },
    titleQuestion: [
      {
        num: "4",
        title: `
        <headphone name="21"></headphone >&ensp; Listen to a radio programme with a chef, Daniel Nold. Choose the correct answer.
        `,
        color: "#6A5EA1",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    question: {
      Write: {
        isHiddenCheck: true,
        maxLength: 11,
        inputStyle: { width: 100, fontSize: 19, textAlign: "center" },
        answers: [""],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: 0,
          borderRadius: "50%",
          fontWeight: "bold",
          borderColor: "transparent",
        },
        selectWordStyle: {
          padding: 5,
          border: "solid 2px",
          // borderColor: '#00aeef',
        },
        limitSelect: 1,
        listWords: [
          `a <br> b <br> c`, //1
        ],
        answers: ["0-8"],
        initialValue: [],
      },
      Layout: `
      
        
      
        <div>
        What <u>doesn’t</u> Daniel Nold do at the moment?
          <div style='display:flex ; margin-left:20px;margin-top:20px'>
          <div>
          <input id='0' type='Circle'/>&ensp;
          </div>
          <span style='margin-left:10px'>
          teach cooking <br>
          work in a restaurant <br>
          sell books about desserts
          </span>
          </div>
          

        </div>
      `,
    },
  },
  5: {
    unit: "Unit 8",
    id: "FP8-WB-demo-2023-U8-P56-E5",
    audio: "Audios/1-21 Friends Plus 8_WB.mp3",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page56/key/E5answerKey.png",
    component: Circle_Write,
    // maxLength: 1,
    inputSize: 500,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      fontSize: "24px",
    },
    titleQuestion: [
      {
        num: "5",
        title: `
        <headphone name="21"></headphone >&ensp; Listen again and choose the correct words.
        `,
        color: "#6A5EA1",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    question: {
      Write: {
        isHiddenCheck: true,
        maxLength: 11,
        inputStyle: { width: 100, fontSize: 19, textAlign: "center" },
        answers: [""],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: 0,
          borderRadius: "50%",
          fontWeight: "bold",
          borderColor: "transparent",
        },
        selectWordStyle: {
          padding: 5,
          border: "solid 2px",
          // borderColor: '#00aeef',
        },
        limitSelect: 1,
        listWords: [
          `teenagers_and_adults / restaurant_cooks`, //1
          `smell / taste`, //1
          `delicious / disgusting`, //1
          `cooks / students`, //1
          `flavour / texture`, //1
          `don’t_pay / pay`, //1
        ],
        answers: ["0-0", `1-4`, `2-4`, `3-0`, `4-0`, `5-0`],
        initialValue: [],
      },
      Layout: `
      
        
      
        <div style='line-height:2'>
          
          <div><b>1</b>&ensp; Daniel Nold organises cookery courses for <input id='0' type='Circle'/>.</div>

          <div><b>2</b>&ensp; Daniel’s book will be about <input id='1' type='Circle'/>.</div>
          
          <div><b>3</b>&ensp; People sometimes say their food is great or <input id='2' type='Circle'/>.</div>
          
          <div><b>4</b>&ensp; Daniel and his <input id='3' type='Circle'/> organise taste sessions in his restaurant.</div>

          <div><b>5</b>&ensp; Learning about <input id='4' type='Circle'/>&ensp; helps people to cook better.</div>

          <div><b>6</b>&ensp; You <input id='5' type='Circle'/>&ensp; for Daniel’s sessions.</div>
    
        </div>
      `,
    },
  },
  6: {
    unit: "Unit 8",
    id: "FP8-WB-demo-2023-U8-P56-E6",
    audio: "",
    video: "",
    // recorder: true,
    // exerciseKey: "img/FriendsPlus/Page56/key/E3answerKey.png",
    component: T6v2,
    // maxLength: 1,
    inputSize: 150,
    textareaStyle: { width: "500px" },
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      fontSize: "20px",
      textAlign: "center",
      fontWeight: "bold",
      // border:'dotted',
    },
    titleQuestion: [
      {
        num: "6",
        title: `
        A TASK Write a competition entry about a course you have done.
        `,
        color: "#6A5EA1",
      },
    ],
    questionImage: [],
    hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
      <div style='line-height:2'>
        <div>
        <b>B THINK AND PLAN</b>
        <div><b>1</b>&ensp; What kind of course was it? Where was it? Who was it for?</div>
        <div><b>2</b>&ensp; What did you learn there? What were the classes like?</div>
        <div><b>3</b>&ensp; What made this course special?</div>
        </div>

        <div>
        <b>C WRITE Use the text in exercise 2 and the writing guide.</b>
        <div style='display:flex  '>
        <img src="img/FriendsPlus/Page56/E6/1.jpg" style='width:45%' />
          <div style='margin-left:15px'>
          <i>ANSWER:</i> 
          <textarea id='0' rows='5'></textarea></div>
        </div>
        
        </div>
      
  
  


      </div>
        `,
        answer: [
          `amazing`,
          `breakfast`,
          `breakfast`,
          `baggy`,
          `patterned`,
          `delicious`,
        ],
      },
    ],
  },
};

export default json;
